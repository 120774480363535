import classNames from "classnames/bind";
import React, { useCallback, useState } from "react";
import { Card } from "react-bootstrap";
import _debounce from "lodash/debounce";

import styles from "../../css/CreateForm.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormData,
  editSectionOnEnter,
} from "../../redux/slice/previewSlice";
import NextSectionByCondition from './NextSectionByCondition'

const  ConditionalRedirect = ({ section, sectionIndex }) => {
  const formData = useSelector((state) => state.preview.formData);
  const dispatch = useDispatch();

  const handleEditQuestion = useCallback(
    (sectionId, e) => {
      const updatedSections = formData.sections.find((section) => {
        return section?._id === sectionId && section.condition;
      });
      const data = {
        value: updatedSections?.condition,
        update: "condition",
        sectionId,
      };

      dispatch(editSectionOnEnter(data));
    },
    [dispatch, formData],
  );

  const handleUpdateQuestion = useCallback(
    (sectionId, questionValue) => {
      const updatedSections = {
        ...formData,
        sections: formData.sections.map((section) => {
          if (section?._id === sectionId) {
            if (section.condition) {
              return {
                ...section,
                condition: {
                  ...section.condition,
                  question: questionValue,
                },
              };
            }
            return {
              ...section,
              condition: {
                question: questionValue,
              },
            };
          }
          return section;
        }),
      };
      const updatedFormData = { ...updatedSections };
      console.log("updatedFormData", updatedFormData);
      dispatch(setFormData(updatedFormData));
    },
    [dispatch, formData],
  );

  const handleUpdateOption = useCallback(
    (optionId, optionValue, sectionId, update) => {

      console.log("update------",optionId,update,optionValue,sectionId)
      const updatedSections = {
        ...formData,
        sections: formData.sections.map((section) => {
          if (section?._id === sectionId) {
            return {
              ...section,
              condition: {
                ...section.condition,
                options: section.condition.options.map((data) => {
                  return data._id === optionId
                    ? { ...data, [update]: optionValue }
                    : data;
                }),
              },
            };
          }
          return section;
        }),
      };

      const updatedFormData = {
        ...updatedSections,
      };

      dispatch(setFormData(updatedFormData));
    },
    [formData, dispatch],
  );

  const handleAddOption = useCallback(
    _debounce((sectionId) => {
      const newOption = {
        option: "",
        redirectIndex: "",
      };
      const updatedSections = {
        ...formData,
        sections: formData.sections.map((section) => {
          if (section?._id === sectionId) {
            if (section?.condition?.options.length > 0) {
              // If `condition` already exists, add the `question` property
              return {
                ...section,
                condition: {
                  ...section.condition,
                  options: [...section.condition.options, newOption],
                },
              };
            }
            // If `condition` doesn't exist, create it with the `question` property
            return {
              ...section,
              condition: {
                ...section.condition,
                options: [newOption],
              },
            };
          }
          return section;
        }),
      };

      const updatedFormData = {
        ...updatedSections,
      };

      dispatch(setFormData(updatedFormData));
    }, 500),
    [dispatch, formData],
  );

  const [nextIndex, setNextIndex] = useState("next");

  return (
    <div className={styles.questionpage_container}>
      <Card className={classNames(styles.questionpage_card, "shadow")}>
        <div className={styles.inputandSelect_container}>
          <div className={styles.inputandImg_container}>
            <input
              type="text"
              placeholder={"Enter Conditional Question"}
              className={styles.question_input}
              value={section.condition?.question}
              onChange={(e) =>
                handleUpdateQuestion(section._id, e.target.value)
              }
              onKeyDown={(e) => {
                e.keyCode===13 && 
                handleEditQuestion(section._id, e)
              }}
            />
          </div>
        </div>

        {/*------------------------------------------- MultipleChoice ----------------------------*/}

        <div className={styles.field_options}>
          {section.condition?.options.map((option, index) => (
            <div key={option} className={styles.showconditional_options}>
              <div>
                <input
                  id={`multiple_optionInput_${index}`}
                  type="text"
                  className={styles.condtional_input}
                  value={option.option}
                  placeholder={`Option ${index + 1}`}
                  onChange={(e) =>
                    handleUpdateOption(
                      option?._id,
                      e.target.value,
                      section?._id,
                      "option",
                    )
                  }
                  onBlur={(e) => {
                    handleEditQuestion(section._id, e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 13) {
                      handleEditQuestion(section._id, e);
                    }
                  }}
                />
              </div>
              <div>
                <NextSectionByCondition
                  optionIndex={index}
                  optionId={option?._id}
                  currentSectionIndex={sectionIndex}
                  formData={formData}
                  handleChange={handleUpdateOption}
                  nextIndex={nextIndex}
                  setNextIndex={setNextIndex}
                  sectionId={section?._id}
                  handleEditQuestion={handleEditQuestion}
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            // className="button button-secondary"
            className={`${styles.button} ${styles.button_secondry}`}
            onClick={() => handleAddOption(section._id)}
          >
            Add Option
          </button>
        </div>
      </Card>

      <div className={styles.button_container} />
    </div>
  );
}

export default ConditionalRedirect;


