import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styles from '../../css/Preview.module.css'

const DropdownQuestion = ({ _id, questionText, qImgUrl, required, isSelfPreviewPage, options, handleInputChange, formErrors, ind, value }) => {
  const dispatch = useDispatch();

  return (
    <Card className={styles.question_card}>
      <Form.Group key={_id} className={styles.dropDown}>
        <Form.Label className="mb-3 w-100">
          <div className="d-flex">
            <p className="d-flex me-1">
              {required && <span className={styles.required}>*</span>} {questionText?.length !== 0 && `${ind + 1}. `}
            </p>
            <div>{questionText}</div>
          </div>
          {qImgUrl && qImgUrl?.length !== 0 && (
            <>
              <img
                src={qImgUrl}
                alt=""
                className={styles.preview_Question_image}
              />
            </>
          )}
        </Form.Label>
        <Form.Select
          disabled={isSelfPreviewPage}
          className={`${styles.dropdown} ms-3  w-50`}
          required={required}
          value={value}
          onChange={(e) =>
            dispatch(
              handleInputChange({
                questionId: _id,
                value: e.target.value,
              }),
            )
          }
          isInvalid={formErrors.includes(
            `Please fill the required field: ${questionText}`,
          )}
        >
          <option value="">Choose</option>
          {options.map((option, index) => (
            <option key={option}>{option.option}</option>
          ))}
        </Form.Select>

        <Form.Control.Feedback type="invalid">
          Please fill the required field: {questionText}
        </Form.Control.Feedback>
      </Form.Group>
    </Card>
  );
};

export default DropdownQuestion;
