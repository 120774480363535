import React from "react";
import RecentAllForms from "./RecentAllForms.jsx";
import styles from "./Recent.module.css";

const Recent = () => {
  return (
    <>
      <div className={styles.userboard}>
        <div className="headingandbutton_div">
          <h5 className="workspace_heading">Recent Forms</h5>
        </div>
        <RecentAllForms />
      </div>
    </>
  );
};

export default Recent;
