import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import {
  editSectionOnEnter,
  setSectionRedirect,
} from "../../redux/slice/previewSlice";
import { useDispatch } from "react-redux";

export function NextSectionSelect({ formData, currentSectionIndex }) {
  const [nextIndex, setNextIndex] = useState("next");
  const dispatch = useDispatch();
  useEffect(() => {
    if (formData?.sections?.[currentSectionIndex]?.redirectNext) {
      setNextIndex(formData?.sections?.[currentSectionIndex]?.redirectNext);
    }
  }, [formData, currentSectionIndex]);

  const handleSectionRedirectNext = useCallback(
    (e, sectionIndex) => {
      dispatch(
        setSectionRedirect({ sectionIndex, redirectNext: e.target.value }),
      );
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (e) => {
      setNextIndex(e.target.value);
      handleSectionRedirectNext(e, currentSectionIndex);
    },
    [handleSectionRedirectNext, currentSectionIndex],
  );

  const handleSaveRedirect = (value, sectionId, currentSectionIndex) => {
    console.log(sectionId, "next", value, "current", currentSectionIndex);

    // current Section
    let updateRedirectIndex = {
      value,
      update: "redirectNext",
      sectionId,
    };
    dispatch(editSectionOnEnter(updateRedirectIndex));

    //nextSection

    // if (value === "next") {
    //   let updateRedirectSection = {
    //     value: currentSectionIndex - 1 > 0 ? currentSectionIndex - 1 : 0,
    //     update: "redirectPrevious",
    //     sectionId: formData?.sections?.[currentSectionIndex + 1]?._id,
    //   };
    //   dispatch(editSectionOnEnter(updateRedirectSection));
    // } else if (value !== "submit") {
      if (value !== "submit" && value !== "next") {
      let updateRedirectSection = {
        value: currentSectionIndex,
        update: "redirectPrevious",
        sectionId: formData?.sections?.[value]?._id,
      };
      dispatch(editSectionOnEnter(updateRedirectSection));
    }
  };

  return (
    <FormControl
      sx={{
        "& legend": { display: "none" },
        "& fieldset": { top: 0 },
        m: 2,
        minWidth: 350,
      }}
    >
      <Select
        sx={{
          height: "30px",
          "& .MuiSelect-select": {
            padding: "0px 14px",
          },
        }}
        value={nextIndex}
        onChange={(e) => {
          handleChange(e);
          handleSaveRedirect(
            e.target.value,
            formData?.sections[currentSectionIndex]?._id,
            currentSectionIndex,
          );
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSaveRedirect(
              nextIndex,
              formData?.sections[currentSectionIndex]?._id,
              currentSectionIndex,
            );
          }
        }}
      >
        <MenuItem
          key={`conditional_section_next_${currentSectionIndex + 1}`}
          selected={nextIndex === "next"}
          value={"next"}
        >
          Continue to next section
        </MenuItem>
        {formData?.sections?.map((section, index) =>
          index !== currentSectionIndex ? (
            <MenuItem
              key={`conditional_section_${index}`}
              selected={nextIndex === index}
              value={index}
            >
              Continue to section {index + 1} ({section?.title})
            </MenuItem>
          ) : (
            ""
          ),
        )}
        <MenuItem
          key={`conditional_section_next_${currentSectionIndex + 1}_submit`}
          selected={nextIndex === "submit"}
          value={"submit"}
        >
          Submit Form
        </MenuItem>
      </Select>
    </FormControl>
  );
}
