import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Rating from "@mui/material/Rating";
import Select from "@mui/material/Select";
import axios from "axios";
import classNames from "classnames/bind";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Button, Card, Container, Overlay, Popover } from "react-bootstrap";
import { AiOutlineDownCircle } from "react-icons/ai";
import { BiTime } from "react-icons/bi";
import { BsImage, BsStar, BsTelephoneFill, BsTextCenter } from "react-icons/bs";
import { CgCalendarDates } from "react-icons/cg";
import { CiTimer } from "react-icons/ci";
import { FaRegCircleDot } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { IoIosCheckbox } from "react-icons/io";
import { MdShortText } from "react-icons/md";
import { TfiText } from "react-icons/tfi";
import { TiDelete } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { Form, useLocation, useNavigate, useParams } from "react-router-dom";
import Switch from "react-switch";
import Header from "../components/Headers/Header";
import HoverOverlay from "../components/HoverOverlay";
import styles from "../css/CreateForm.module.css";
import { createForm, editForm } from "../redux/slice/formSlice";
import { API_URL, cookieToken } from "../service/url";
import AddCardPage from "./AddCardPage";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const CreateForms = () => {
  const { workspaceId } = useParams();
  const [showTimer, setShowTimer] = useState(false);
  const [targetTimer, setTargetTimer] = useState(null);
  const timeRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [formDate, setFormDate] = useState(null);
  const dateRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [targetDatePicker, setTargetDatePicker] = useState(null);

  const [newOptionIndex, setNewOptionIndex] = useState(null);
  const [themeImage, setThemeImage] = useState(null);

  useEffect(() => {
    if (newOptionIndex !== null) {
      let toFind;

      switch (newOptionIndex.type) {
        case "multiple_choice":
          toFind = `multiple_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
          break;

        case "checkbox":
          toFind = `check_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
          break;

        case "dropdown":
          toFind = `dropdown_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
          break;

        default:
          toFind = null;
          break;
      }

      const inputElement = document.getElementById(toFind);
      if (inputElement) {
        inputElement.focus();
      }

      // Reset the new option index
      setNewOptionIndex(null);
    }
  }, [newOptionIndex]);

  const handleClickTimer = (event) => {
    setShowTimer(!showTimer);
    setTargetTimer(event.target);
  };

  const handleClickDatePicker = (event) => {
    setShowDatePicker(!showDatePicker);
    setTargetDatePicker(event.target);
  };

  const alert = useAlert();
  const formID = useSelector((state) => state.forms.formId);
  const formResponse = useSelector((state) => state.forms.responseStatus);
  const isEditing = false;
  const location = useLocation();
  const navigate = useNavigate();
  const [isQuiz, setIsQuiz] = useState(false);
  const [selectImageUrl, setSelectedImageUrl] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const buttonText = useState("Add Form");

  const [childData, setChildData] = useState(null);
  const dispatch = useDispatch();
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [isPreview, setIsPreview] = useState(true);
  const [formData, setFormData] = useState({
    sections: [
      {
        title: "",
        description: "",
        submitButtonText: "",

        questions: [
          {
            id: new Date().getTime(),
            question: "",
            required: false,
            type: "text",
            options: [],
            img: null,
            qindex: 0,
            qImgUrl: "",
            answer: "",
            points: 1,
          },
        ],
      },
    ],
    submitButtonText: "",
    videoData: null,
    imageTheme: null,
    formType: "normal",
    date: formDate,
    workspaceId,
    isQuiz: false,
  });

  const formTypeToggle = useCallback(() => {
    setIsQuiz((prev) => !prev);
  }, [dispatch]);

  const handleAddNewSection = () => {
    let updatedForm = { ...formData };

    updatedForm.sections.push({
      title: "",
      description: "",
      submitButtonText: "",
      redirectPrevious:"0",
      questions: [
        {
          id: new Date().getTime(),
          question: "",
          required: false,
          type: "text",
          options: [],
          img: null,
          qindex: 0,
          qImgUrl: "",
        },
      ],
    });

    setFormData(updatedForm);
  };
  const handleImageTheme = (url) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      imageTheme: url,
    }));
  };

  useEffect(() => {
    const hasTitle = formData?.title?.trim() !== "";
    const hasQuestions =
      (formData?.sections[0].questions?.length > 0 &&
        formData?.sections[0].questions[0]?.question?.trim() !== "") ||
      formData?.sections[0].questions[0]?.qImgUrl?.trim();

    setIsPreview(hasTitle && hasQuestions);
  }, [formData]);
  useEffect(() => {
    if (location.state) {
      setFormData(location.state.formData);
      // console.log("location formdata",formData)
    }
  }, [location.state]);
  // const handleSwitchChange = useCallback(
  //   (checked) => {
  //     setIsChecked(checked);
  //   },
  //   [setIsChecked]
  // );
  // console.log(location.state, "location.state")

  const handleFormTitleChange = useCallback(
    (e, sectionIndex) => {
      const newValue = e.target.value;
      // If the first character is a capital letter, keep it unchanged
      const updatedTitle = newValue.charAt(0).toUpperCase() + newValue.slice(1);
      let updatedFormData = { ...formData };
      updatedFormData.sections[sectionIndex].title = updatedTitle;

      setFormData(updatedFormData);
    },
    [setFormData, formData],
  );
  const handleFormTitleChangeUndo = useCallback(
    (title) => {
      setUndoStack((prevUndoStack) => [
        ...prevUndoStack,
        { ...formData, title: title },
      ]);
    },
    [formData, setUndoStack],
  );

  const handleFormDescriptionChange = useCallback(
    (e, sectionIndex) => {
      const newValue = e.target.value;
      // If the first character is a capital letter, keep it unchanged
      const updatedDescripition =
        newValue.charAt(0).toUpperCase() + newValue.slice(1);
      let updatedFormData = { ...formData };
      updatedFormData.sections[sectionIndex].description = updatedDescripition;
      setFormData(updatedFormData);
    },
    [setFormData, formData],
  );
  const handleFormDescriptionChangeUndo = useCallback(
    (title) => {
      setUndoStack((prevUndoStack) => [
        ...prevUndoStack,
        { ...formData, description: title },
      ]);
    },
    [formData, setUndoStack],
  );
  const handleSubmitButtonTextChange = useCallback(
    (e, sectionIndex) => {
      const newValue = e.target.value;
      // If the first character is a capital letter, keep it unchanged
      const updatedSubmit =
        newValue.charAt(0).toUpperCase() + newValue.slice(1);
      setSubmit(updatedSubmit);
      let updatedFormData = { ...formData };
      updatedFormData.submitButtonText = updatedSubmit;

      setFormData(updatedFormData);
    },
    [setFormData, formData],
  );
  const handleSubmitButtonTextChangeUndo = useCallback(
    (title) => {
      setUndoStack((prevUndoStack) => [
        ...prevUndoStack,
        { ...formData, submitButtonText: title },
      ]);
    },
    [formData, setUndoStack],
  );

  const handleAddField = useCallback(
    (type = null, sectionIndex) => {
      const previousQuestionType =
        formData?.sections[sectionIndex]?.questions.length > 0
          ? formData?.sections[sectionIndex]?.questions.slice(-1)[0].type
          : "text"; // Default to "text" if no previous question

      const newField = {
        id: new Date().getTime(),
        question: "",
        required: false,
        type: type || previousQuestionType,
        options: [],
        img: null,
        qindex: formData?.sections[sectionIndex]?.questions?.length,
      };

      let updatedFormData = { ...formData };
      updatedFormData.sections[sectionIndex].questions = [
        ...updatedFormData.sections[sectionIndex].questions,
        newField,
      ];
      setFormData(updatedFormData);

      // setUndoStack([
      //   ...undoStack,
      //   { ...formData, questions: [...formData.questions, newField] },
      // ]);
    },
    [formData, setFormData, undoStack, setUndoStack],
  );
  const handleCopyField = (fieldId, sectionIndex) => {
    const fieldToCopy = formData.sections[sectionIndex].questions.find(
      (field) => field.id === fieldId,
    );

    if (fieldToCopy) {
      const newField = { ...fieldToCopy, id: Date.now().toString() };

      let updatedFormData = { ...formData };
      updatedFormData.sections[sectionIndex].questions = [
        ...updatedFormData.sections[sectionIndex].questions,
        newField,
      ];
      setFormData(updatedFormData);
    }
  };
  const handleUpdateQuestion = (fieldId, question, required, sectionIndex) => {
    const updatedFormData = { ...formData };
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) =>
        field.id === fieldId ? { ...field, question, required } : field,
    );

    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };
  const handleUpdatePoints = (fieldId, points, required, sectionIndex) => {
    const updatedFormData = { ...formData };
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) =>
        field.id === fieldId ? { ...field, points, required } : field,
    );

    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };
  const handleUpdateQuestionImgUrl = (fieldId, qImgUrl, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => (field.id === fieldId ? { ...field, qImgUrl } : field),
    );
    let updatedFormData = { ...formData };

    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  const handleUpdateQuestionRequired = useCallback(
    (fieldId, question, required, sectionIndex) => {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };
        updatedFormData.sections = [...prevFormData.sections]; // Shallow copy of the sections array

        const updatedFields = prevFormData.sections[sectionIndex].questions.map(
          (field) =>
            field.id === fieldId
              ? { ...field, question, required }
              : { ...field }, // Include all other fields
        );

        updatedFormData.sections[sectionIndex].questions = updatedFields;

        return updatedFormData;
      });
    },
    [setFormData],
  );

  const handleUpdateQuestionUndo = useCallback(
    (fieldId, question, required) => {
      const updatedFields = formData.sections[0].questions.map((field) =>
        field.id === fieldId ? { ...field, question, required } : field,
      );

      setUndoStack([...undoStack, { ...formData, questions: updatedFields }]);
    },
    [formData, setUndoStack, undoStack],
  );

  const handleUpdateFieldType = useCallback((fieldId, type, sectionIndex) => {
    let required = false;
    let updatedFormData = { ...formData };
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => (field.id === fieldId ? { ...field, type, required } : field),
    );

    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  });

  // -------------------------------Add option to multiple choice or checkbox field---------------------------------------------------

  const handleAddOption = useCallback(
    (fieldId, sectionIndex) => {
      const updatedFields = formData?.sections[sectionIndex].questions.map(
        (field) => {
          if (
            field.id === fieldId &&
            (field.type === "multiple_choice" || field.type === "checkbox")
          ) {
            const newOption = {
              // option: `Option ${field?.options?.length + 1}`,
              option: ``,
            };
            setNewOptionIndex({
              index: field.options.length,
              type: field.type,
              fieldid: field.id,
            });
            return { ...field, options: [...field.options, newOption] };
          }
          return field;
        },
      );
      let updatedFormData = { ...formData };
      updatedFormData.sections[sectionIndex].questions = updatedFields;

      setFormData(updatedFormData);

      // setUndoStack([...undoStack, { ...formData, questions: updatedFields }]);
    },
    [formData, setFormData, undoStack, setUndoStack],
  );
  const handleAddOptionForOther = useCallback(
    (fieldId, sectionIndex) => {
      const updatedFields = formData.sections[sectionIndex].questions.map(
        (field) => {
          if (
            field.id === fieldId &&
            (field.type === "multiple_choice" || field.type === "checkbox")
          ) {
            const newOption = { option: ``, otherOption: true };
            return { ...field, options: [...field.options, newOption] };
          }
          return field;
        },
      );
      let updatedFormData = { ...formData };
      updatedFormData.sections[sectionIndex].questions = updatedFields;

      setFormData(updatedFormData);
      setUndoStack([...undoStack, { ...formData, questions: updatedFields }]);
    },
    [formData, setFormData, undoStack, setUndoStack],
  );

  // --------------------------------------------------------------------mcg

  const handleAddOptionmcg = useCallback(
    (fieldId, sectionIndex) => {
      const updatedFields = formData.sections[sectionIndex].questions.map(
        (field) => {
          if (
            field.id === fieldId &&
            (field.type === "multiple_choice_option" ||
              field.type === "checkbox_grid")
          ) {
            const newOption = { option: "" };
            return { ...field, options: [...field.options, newOption] };
          }
          return field;
        },
      );

      let updatedFormData = { ...formData };
      updatedFormData.sections[sectionIndex].questions = updatedFields;

      setFormData(updatedFormData);
    },
    [formData, setFormData],
  );

  // ------------------------------------------------Remove field-----------------------------------------------------

  const handleRemoveField = (fieldId, sectionIndex) => {
    alert.success("Question Removed");
    const updatedFields = formData.sections[sectionIndex].questions.filter(
      (field) => field.id !== fieldId,
    );
    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  const handleUndos = (fieldId) => {
    const updatedFields = formData.questions.filter(
      (field) => field.id === fieldId,
    );

    const updatedFormQuestions = formData.questions.map((field) =>
      field.id === fieldId ? updatedFields[0] : field,
    );

    setFormData({ ...formData, questions: updatedFormQuestions });
  };
  //----------------------------------------- Update option in multiple choice option or checkbox grid field---------------------------------------------------

  const handleUpdateOptionmcg = (
    fieldId,
    optionIndex,
    option,
    sectionIndex,
  ) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => {
        if (
          field.id === fieldId &&
          (field.type === "multiple_choice_option" ||
            field.type === "checkbox_grid")
        ) {
          const options = [...field.options];
          options[optionIndex] = { option };
          return { ...field, options: options };
        }
        return field;
      },
    );
    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  // --------------------------------------------------------------------mcg

  const handleUpdateOption = (fieldId, optionIndex, option, sectionIndex) => {
    const updatedFields = formData?.sections[sectionIndex].questions.map(
      (field) => {
        if (field.id === fieldId) {
          const options = [...field.options];
          options[optionIndex] = { ...options[optionIndex], option: option };
          return { ...field, options: options };
        }
        return field;
      },
    );

    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };
  // const handleUpdateOptionR = (fieldId, optionIndex) => {

  //   const updatedFields = formData.questions.map((field) => {
  //     if (field.id === fieldId) {
  //       const options = [...field.options];
  //       options[optionIndex] = "";
  //       return { ...field, options: options };
  //     }
  //     return field;
  //   });

  //   setFormData({ ...formData, questions: updatedFields });
  // };
  // --------------------------------------------------------------------mcg

  const handleRemoveOption = (fieldId, optionIndex, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => {
        if (
          field.id === fieldId &&
          (field.type === "multiple_choice" || field.type === "checkbox")
        ) {
          const options = [...field.options];
          options.splice(optionIndex, 1);
          return { ...field, options: options };
        }
        return field;
      },
    );
    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  const handleEmptyOption = (fieldId, optionIndex, sectionIndex) => {
    formData?.sections[sectionIndex]?.questions?.forEach((field) => {
      if (field.id === fieldId && field.options) {
        field?.options?.forEach((option) => {
          if (option?.option?.trim() === "") {
            handleUpdateOption(
              fieldId,
              optionIndex,
              `Option ${optionIndex + 1}`,
              sectionIndex,
            );
          }
        });
      }
    });
  };
  const dhandleEmptyOption = (fieldId, optionIndex, sectionIndex) => {
    formData?.sections[sectionIndex]?.questions?.forEach((field) => {
      if (field.id === fieldId && field.options) {
        field?.options?.forEach((option) => {
          if (option?.option?.trim() === "") {
            dhandleUpdateOption(
              fieldId,
              optionIndex,
              `Option ${optionIndex + 1}`,
              sectionIndex,
            );
          }
        });
      }
    });
  };

  // const handleEmptyQuestion = (fieldId) => {
  //   formData.questions.map((field) => {
  //     if (field.id === fieldId) {
  //     }
  //   });
  // };

  // --------------------------------------------------------------------mcg

  const handleRemoveOptionmcg = (fieldId, optionIndex, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => {
        if (
          field.id === fieldId &&
          (field.type === "multiple_choice_option" ||
            field.type === "checkbox_grid")
        ) {
          const options = [...field.options];
          options.splice(optionIndex, 1);
          return { ...field, options: options };
        }
        return field;
      },
    );

    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  //------------------------------------------This is for dropdown handle --------------------------------------------------------------------//

  // -----------------------------Add option to dropdown field---------------------------
  const dhandleAddOption = (fieldId, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => {
        if (field.id === fieldId && field.type === "dropdown") {
          const newOption = {
            // option: `Option ${field?.options?.length + 1}`
            option: ``,
          };
          setNewOptionIndex({
            index: field.options.length,
            type: field.type,
            fieldid: field.id,
          });
          return { ...field, options: [...field.options, newOption] };
        }
        return field;
      },
    );
    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  // -----------------------Update option in dropdown field-----------------------------------------------

  const dhandleUpdateOption = (fieldId, optionIndex, option, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => {
        if (field.id === fieldId && field.type === "dropdown") {
          const options = [...field.options];
          options[optionIndex] = { option };
          return { ...field, options: options };
        }
        return field;
      },
    );
    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  // --------------------------------------Remove option from dropdown field--------------------------------

  const dhandleRemoveOption = (fieldId, optionIndex, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => {
        if (field.id === fieldId && field.type === "dropdown") {
          const options = [...field.options];
          options.splice(optionIndex, 1);
          return { ...field, options: options };
        }
        return field;
      },
    );

    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  //------------------------------------------------- end This is for dropdown handle --------------------------------------------------------------------//

  //--------------------Handle date----------------------

  const handleUpdatedate = (fieldId, date, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => (field.id === fieldId ? { ...field, dateValue: date } : field),
    );

    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  //--------------------Handle time----------------------

  const handleUpdatetime = (fieldId, time, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => (field.id === fieldId ? { ...field, timeValue: time } : field),
    );

    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  //--------------------Handle image----------------------

  const handleUpdateimage = (fieldId, image, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => (field.id === fieldId ? { ...field, image: image } : field),
    );

    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };

  // --------------------------------------------Handle APi -----------------------------------------------------------------------

  const handleAddForm = async () => {
    if (!isEditing) {
      try {
        if (
          formData?.sections.length === 0 ||
          formData.sections.some((section) => {
            return (
              section.title?.trim() === "" ||
              section.questions?.length === 0 ||
              section.questions.some((q) => {
                if (q?.qImgUrl) {
                  return false;
                } else {
                  return q?.question?.trim() === "";
                }
              })
            );
          })
        ) {
          alert.error("Please fill in all the section titles and questions.");
          return;
        }
        setIsButtonDisabled(true);
        try {
          // Dispatch the createForm action and store the result in a variable
          setFormData({
            ...formData,
            timer: timer,
            formTitle: formData.sections[0].title,
          });

          dispatch(createForm({ ...formData, isQuiz })).then(
            (createFormResponse) => {
              if (createFormResponse.payload.success) {
                alert.success(createFormResponse.payload.message);
                navigate(`/workspace/${workspaceId}`);
              }
            },
          );

          // Access the formID from the createForm response
          // const formID = createFormResponse.payload.formId;

          // Check if formID is available
          // if (formID && themeImage) {
          //   const Data = new FormData();
          //   Data.append("form-attach", themeImage);
          //   Data.append("formId", formID);
          //   // Dispatch the sendFormImage action with the correct formID
          //   dispatch(sendFormImage({ formId: formID, data: Data }))
          //     .then(() => {
          //       alert.success("Form Created Successfully");
          //       navigate("/");
          //     })
          //     .catch((error) => {
          //       console.error("Error adding form:", error);
          //       throw error;
          //     });

          // } else {
          //   alert.success("Form Created Successfully");
          //   navigate("/");
          // }
        } catch (error) {
          // Handle errors if the form creation fails.
          console.error("Error creating form:", error);
        }
      } catch (error) {
        // setIsSubmitting(false);
        console.error("Error adding form:", error);
        throw error;
      }
    }
  };

  // useEffect(() => { }, [formData]);
  const handleUpdateVideoData = () => {
    // setFormData({ ...formData, videoData: childData });
  };

  // const handleDoubleClick = () => {
  //   setIsEditing(true);
  // };

  // const handleBlur = () => {
  //   setIsEditing(false);
  // };
  const handleUndo = useCallback(() => {
    if (undoStack.length > 0) {
      const prevFormData = undoStack.pop();
      setFormData(prevFormData);
      setUndoStack([...undoStack]);
      setRedoStack([...redoStack, formData]);
    }
  }, [undoStack, formData, setFormData, setUndoStack, setRedoStack]);

  const handleRedo = useCallback(() => {
    if (redoStack.length > 0) {
      const nextFormData = redoStack.pop();
      setFormData(nextFormData);
      setRedoStack([...redoStack]);
      setUndoStack([...undoStack, formData]);
    }
  }, [redoStack, formData, undoStack, setFormData, setRedoStack, setUndoStack]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedImgId, setSelectedImgId] = useState(null);
  // const [QuesImageUrl, setQuesImageUrl] = useState("");

  const handleImageChange = async (event, fieldId, index, sectionIndex) => {
    const file = event.target.files[0];
    if (file) {
      const QImgFormData = new FormData();
      QImgFormData.append("questionImage", file);

      try {
        const response = await axios.post(
          `${API_URL}/form/question/questionImage`,
          QImgFormData,
          {
            headers: {
              "x-access-token": cookieToken,
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (response?.data?.success) {
          // setQuesImageUrl(response.data.url);

          handleUpdateQuestionImgUrl(
            fieldId,
            response?.data?.url,
            sectionIndex,
          );
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const fileInputRef = useRef(null);
  const handleIconClick = useCallback((id) => {
    setSelectedImgId(id);

    // fileInputRef.current;
  }, []);
  const [submit, setSubmit] = useState();
  // select mui
  // const [option, setOption] = useState("text");

  const handleRemoveImage = () => {
    setSelectedImageUrl(null);
  };

  const handleRemoveQuestionImage = (fieldId, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => (field.id === fieldId ? { ...field, qImgUrl: "" } : field),
    );

    let updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    setFormData(updatedFormData);
  };
  const handleRemoveSection = (sectionIndex) => {
    const formDataCopy = { ...formData };
    formDataCopy.sections.splice(sectionIndex, 1);
    setFormData(formDataCopy);
  };
  const [showDelteModal, setShowDeleteModal] = useState(false);
  const [sectionIndexToDelete, setSectionIndexToDelete] = useState(null);
  const handleCloseDeletePopup = () => {
    setShowDeleteModal(false);
    setSectionIndexToDelete(null);
  };
  const handleAutoTypeUpdate = (value, fieldId, sectionIndex) => {
    let splitedValueArr = value.split(" ");

    if (
      splitedValueArr.some((word) =>
        [
          "phone",
          "Phone",
          "number",
          "Number",
          "telephone",
          "Telephone",
          "contact",
          "Contact",
        ].includes(word),
      )
    ) {
      handleUpdateFieldType(fieldId, "phone", sectionIndex);
    } else if (splitedValueArr.includes("time")) {
      handleUpdateFieldType(fieldId, "time", sectionIndex);
    } else if (splitedValueArr.includes("date")) {
      handleUpdateFieldType(fieldId, "date", sectionIndex);
    } else if (splitedValueArr.includes("address")) {
      handleUpdateFieldType(fieldId, "address", sectionIndex);
    } else if (splitedValueArr.includes("rating")) {
      handleUpdateFieldType(fieldId, "rating", sectionIndex);
    } else if (
      splitedValueArr.some((word) =>
        [
          "paragraph",
          "Paragraph",
          "description",
          "Description",
          "summary",
          "Summary",
          "brief",
          "Brief",
        ].includes(word),
      )
    ) {
      handleUpdateFieldType(fieldId, "paragraph", sectionIndex);
    } else if (
      splitedValueArr.some((word) =>
        [
          "multiple",
          "options",
          "option",
          "Multiple",
          "Options",
          "Option",
          "select",
          "Select",
        ].includes(word),
      )
    ) {
      handleUpdateFieldType(fieldId, "multiple_choice", sectionIndex);
    } else if (
      splitedValueArr.some((word) =>
        [
          "upload",
          "Upload",
          "document",
          "Document",
          "file",
          "File",
          "resume",
          "Resume",
        ].includes(word),
      )
    ) {
      handleUpdateFieldType(fieldId, "file_upload", sectionIndex);
    }
  };

  const [addingAnswer, setAddingAnswer] = useState(null);
  const [answerFieldId, setAnswerFieldId] = useState(null);
  const handleAddAnswerClick = useCallback((fieldId) => {
    setAddingAnswer(fieldId);
    setAnswerFieldId(fieldId);
  }, []);
  const handleAddAnswer = useCallback(
    (fieldId, sectionIndex, answerIndex) => {
      const updatedFields = formData.sections[sectionIndex].questions.map(
        (field) => {
          if (field.id === fieldId) {
            if (field.type === "multiple_choice" || field.type === "checkbox") {
              return { ...field, answer: answerIndex };
            }
          }
          return field;
        },
      );

      let updatedFormData = { ...formData };
      updatedFormData.sections[sectionIndex].questions = updatedFields;

      setFormData(updatedFormData);
      setUndoStack([...undoStack, { ...formData, questions: updatedFields }]);
      setAnswerFieldId(null);
      setAddingAnswer(null);
    },
    [formData, setFormData, undoStack, setUndoStack],
  );

  return (
    <>
      <Header
        title={formData.sections[0].title}
        handleFormTitleChange={handleFormTitleChange}
        formData={formData}
        formId={formData.formId}
        // handleUndo={handleUndo}
        // handleRedo={handleRedo}
        // setFormData={setFormData}
        handleImageTheme={handleImageTheme}
        setThemeImage={setThemeImage}
        setSelectedImageUrl={setSelectedImageUrl}
        showPreviews={isPreview}
        showTheme={true}
        editing={false}
      />

      {/* dialog box */}
      <Dialog
        open={showDelteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="responsive-dialog-title"
        fullWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete Section and its Questions?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDeletePopup}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRemoveSection(sectionIndexToDelete);
              setShowDeleteModal(false);
              setSectionIndexToDelete(null);
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialog box */}
      {formData?.sections?.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <div className={styles.root_wrapper}>
            <div className={styles.root_col}>
              {/* <div className="root-col root-col-1"> */}

              {formData.imageTheme && (
                <div>
                  <div className={styles.crossIconParent}>
                    <ImCross
                      className={styles.crossIcon}
                      onClick={handleRemoveImage}
                    />
                  </div>
                  <img
                    className={styles.customize_theme}
                    src={formData.imageTheme}
                    alt=""
                  />
                </div>
              )}
              <div className={styles.titlepage_container}>
                <div className={styles.section_header_bar}>
                  {formData.sections.length > 1 && (
                    <span className={styles.section_indicator}>{`section ${
                      sectionIndex + 1
                    }/${formData.sections.length}`}</span>
                  )}

                  {sectionIndex === 0 && timer && timer !== "00:00" && (
                    <div className={styles.timer_div}>
                      <CiTimer fontSize={32} />
                      {timer}
                    </div>
                  )}
                  {formData.sections.length > 1 && (
                    <span
                      className={styles.section_delete_button}
                      onClick={() => {
                        setSectionIndexToDelete(sectionIndex);
                        setShowDeleteModal(true);
                      }}
                    >
                      <TiDelete />
                    </span>
                  )}
                </div>
                <Card className={classNames(styles.titlepage_card, "shadow")}>
                  <span className={styles.titlepagecard_span}></span>

                  <input
                    type="text"
                    className={styles.title_input}
                    value={section.title}
                    onChange={(e) => handleFormTitleChange(e, sectionIndex)}
                    onBlur={() => handleFormTitleChangeUndo(section.title)}
                    placeholder="Untitled Documents"
                  />
                  <input
                    type="text"
                    className={styles.description_input}
                    value={section.description}
                    onChange={(e) =>
                      handleFormDescriptionChange(e, sectionIndex)
                    }
                    onBlur={() =>
                      handleFormDescriptionChangeUndo(section.description)
                    }
                    placeholder="Form Description"
                  />

                  {sectionIndex === 0 && (
                    <input
                      type="text"
                      className={styles.description_input}
                      value={formData.submitButtonText}
                      onChange={(e) =>
                        handleSubmitButtonTextChange(e, sectionIndex)
                      }
                      onBlur={() =>
                        handleSubmitButtonTextChangeUndo(
                          section.submitButtonText,
                        )
                      }
                      placeholder="Button Text default is `Submit`"
                    />
                  )}
                </Card>
              </div>

              {/* ------------------------------------------------Render  field type ------------------------------------------------*/}

              <div className={styles.questionpage_container}>
                {section.questions.map((field, index) => (
                  <Card
                    key={index}
                    // key={field.id}
                    className={classNames(styles.questionpage_card, "shadow")}
                  >
                    <div className={styles.inputandSelect_container}>
                      <div className={styles.inputandImg_container}>
                        <input
                          type="text"
                          placeholder={`Enter Question ${index + 1}`}
                          className={styles.question_input}
                          value={field.question}
                          onChange={(e) =>
                            handleUpdateQuestion(
                              field.id,
                              e.target.value,
                              field.required,
                              sectionIndex,
                            )
                          }
                          onBlur={(e) => {
                            handleAutoTypeUpdate(
                              e.target.value,
                              field.id,
                              sectionIndex,
                            );
                          }}
                          onMouseLeave={() =>
                            handleUpdateQuestionUndo(
                              field.id,
                              field.question,
                              field.required,
                            )
                          }
                        />
                      </div>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        {field.qImgUrl ? (
                          <div
                            key={field.id}
                            className={styles.removeQuestionImage}
                            onClick={() =>
                              handleRemoveQuestionImage(field.id, sectionIndex)
                            }
                          >
                            <ImCross />
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="select_image">
                          <label htmlFor={`fileInput-${index}`}>
                            <BsImage />
                          </label>

                          <input
                            type="file"
                            id={`fileInput-${index}`}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                field.id,
                                index,
                                sectionIndex,
                              )
                            }
                            accept="image/*"
                          />
                        </div>

                        <FormControl
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            m: 2,
                            minWidth: 150,
                          }}
                        >
                          {/* <InputLabel id="demo-controlled-open-select-label">
                      Type
                    </InputLabel> */}
                          {/* <InputLabel></InputLabel> */}
                          <InputLabel disableTypography></InputLabel>
                          <Select
                            sx={{
                              height: "30px",
                              "& .MuiSelect-select": {
                                padding: "0px 14px",
                              },
                            }}
                            value={field.type}
                            label="Age"
                            // onChange={handleChange}
                            onChange={(e) => {
                              handleUpdateFieldType(
                                field.id,
                                e.target.value,
                                sectionIndex,
                              );
                            }}
                          >
                            <MenuItem selected value="text">
                              <TfiText className="mx-2" />
                              Text
                            </MenuItem>

                            <MenuItem value="multiple_choice">
                              <FaRegCircleDot className="mx-2" />
                              Multiple choice
                            </MenuItem>
                            <MenuItem value="checkbox">
                              <IoIosCheckbox className="mx-2" />
                              checkbox
                            </MenuItem>
                            <MenuItem value="short_answer">
                              <MdShortText className="mx-2" />
                              Short Answer
                            </MenuItem>
                            <MenuItem value="paragraph">
                              <BsTextCenter className="mx-2" />
                              Paragraph
                            </MenuItem>
                            <MenuItem value="dropdown">
                              <AiOutlineDownCircle className="mx-2" />
                              Dropdown
                            </MenuItem>
                            <MenuItem value="phone">
                              <BsTelephoneFill className="mx-2" />
                              Phone Number
                            </MenuItem>
                            <MenuItem value="address">
                              <BsTextCenter className="mx-2" />
                              Address
                            </MenuItem>
                            <MenuItem value="time">
                              <BiTime className="mx-2" />
                              Time
                            </MenuItem>
                            <MenuItem value="date">
                              <CgCalendarDates className="mx-2" />
                              Date
                            </MenuItem>
                            <MenuItem value="rating">
                              <BsStar className="mx-2" />
                              Rating
                            </MenuItem>
                            <MenuItem value="file_upload">
                              <BsStar className="mx-2" />
                              Document upload
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {field?.qImgUrl && (
                      <div key={field.id} className={styles.qImgUrl_div}>
                        <img
                          src={field.qImgUrl}
                          alt="Question Image"
                          className={styles.qImgUrl}
                        />
                      </div>
                    )}
                    {field.img && (
                      <div className={styles.image_question}>
                        <img
                          className="img-fluid"
                          src={field.img}
                          alt="Selected"
                        />
                      </div>
                    )}

                    {field.type === "linear_scale" && (
                      <>
                        <input
                          className={styles.options_input}
                          placeholder="label(optional)"
                        ></input>

                        <input
                          className={styles.options_input}
                          placeholder="label(optional)"
                        ></input>
                      </>
                    )}

                    {/* --------------------------------------------text--------------------------------------- */}

                    {field.type === "text" && (
                      <input
                        type="text"
                        className={styles.paragraph_input}
                        placeholder="Enter text"
                        disabled
                      />
                    )}
                    {/* -------------------------e-------------------ShortAnswer--------------------------------------- */}

                    {field.type === "short_answer" && (
                      <textarea
                        // className="paragraph_input text_area"
                        className={`${styles.paragraph_input} ${styles.text_area}`}
                        rows="2"
                        placeholder="short_answer"
                        disabled
                      />
                    )}
                    {/* --------------------------------------------paragraph-------------------------------------- */}

                    {field.type === "paragraph" && (
                      <textarea
                        className={`${styles.paragraph_input} ${styles.text_area}`}
                        rows="3"
                        placeholder="paragraph"
                        disabled
                      />
                    )}
                    {/* --------------------------------------------choose file-------------------------------------- */}
                    {field.type === "file_upload" && (
                      <input
                        type="file"
                        disabled
                        className={styles.paragraph_input}
                        accept=".jpg, .jpeg, .png, .pdf" // Limiting accepted file types
                        required // Making the field mandatory
                      />
                    )}
                    {/* -------------------------------------------- Number -------------------------------------- */}
                    {field.type === "number" && (
                      <input
                        type="number"
                        className={styles.paragraph_input}
                        placeholder="Number"
                        min={0} // Setting a minimum value
                        max={100} // Setting a maximum value
                        step={1} // Setting the step value (increments)
                        required // Making the field mandatory
                        disabled
                      />
                    )}
                    {/* -------------------------------------------- Email -------------------------------------- */}
                    {field.type === "email" && (
                      <input
                        type="email"
                        className={styles.paragraph_input}
                        placeholder="Email"
                        required // Making the field mandatory
                        disabled
                      />
                    )}
                    {/* -------------------------------------------- Phone-------------------------------------- */}
                    {field.type === "phone" && (
                      <input
                        type="tel" // Using 'tel' input type for phone numbers
                        className={styles.paragraph_input}
                        placeholder="Phone number"
                        pattern="[0-9]{10}" // Adding a pattern for valid phone numbers (10 digits)
                        required // Making the field mandatory
                        disabled
                      />
                    )}
                    {/* --------------------------------------------Link-------------------------------------- */}
                    {field.type === "link" && (
                      <input
                        type="url"
                        className={styles.paragraph_input}
                        placeholder="Link"
                        required // Making the field mandatory
                      />
                    )}
                    {/* -------------------------------------------- Rating -------------------------------------- */}
                    {field.type === "rating" && (
                      <Rating
                        className="mx-3"
                        name="half-rating-read"
                        defaultValue={0}
                        precision={0.5}
                        readOnly
                      />
                    )}
                    {/* -------------------------------------------- Address -------------------------------------- */}
                    {field.type === "address" && (
                      <input
                        type="text"
                        className={styles.paragraph_input}
                        placeholder="Address"
                        required // Making the field mandatory
                        disabled
                      />
                    )}
                    {/* -------------------------------------------- Signature -------------------------------------- */}
                    {field.type === "signature" && (
                      <input
                        type="text"
                        className={styles.paragraph_input}
                        placeholder="Signature"
                        required // Making the field mandatory
                      />
                    )}
                    {/* -------------------------------------------- Opinion Scale -------------------------------------- */}
                    {field.type === "opinion_scale" && (
                      <input
                        type="range" // Using 'range' input type for opinion scale
                        className={styles.paragraph_input}
                        min={1} // Setting the minimum scale value
                        max={5} // Setting the maximum scale value
                        step={1} // Setting the step value (increments)
                        required // Making the field mandatory
                      />
                    )}
                    {/* -------------------------------------------- statement -------------------------------------- */}
                    {field.type === "statement" && (
                      <input
                        type="text"
                        className={styles.paragraph_input}
                        placeholder="Statement"
                        required // Making the field mandatory
                      />
                    )}
                    {/* -------------------------------------------- Ranking -------------------------------------- */}
                    {field.type === "ranking" && (
                      <input
                        type="number"
                        className={styles.paragraph_input}
                        placeholder="Ranking"
                        min={1} // Setting a minimum ranking value
                        max={10} // Setting a maximum ranking value
                        step={1} // Setting the step value (increments)
                        required // Making the field mandatory
                      />
                    )}
                    {/* -------------------------------------------- Website Link -------------------------------------- */}
                    {field.type === "website_link" && (
                      <input
                        type="text"
                        className={styles.paragraph_input}
                        placeholder="Website link"
                      />
                    )}
                    {/* --------------------------------------------dropdown--------------------------------------- */}

                    {/* {field.type === "dropdown" && (
                  <input type="text" className="options_input"
                  />
                )} */}

                    {/* --------------------------------------------date--------------------------------------- */}

                    {field.type === "date" && (
                      <div className="date_input_container">
                        <label
                          htmlFor={`date_${field.id}`}
                          className="date_label"
                        ></label>
                        <input
                          type="date"
                          id={`date_${field.id}`}
                          className={styles.date_input}
                          value={field.dateValue}
                          onChange={(e) =>
                            handleUpdatedate(
                              field.id,
                              e.target.value,
                              sectionIndex,
                            )
                          }
                          disabled
                        />
                      </div>
                    )}

                    {/* --------------------------------------------time-------------------------------------- */}

                    {field.type === "time" && (
                      <input
                        type="time"
                        className={styles.time_input}
                        value={field.timeValue}
                        onChange={(e) =>
                          handleUpdatetime(
                            field.id,
                            e.target.value,
                            sectionIndex,
                          )
                        }
                        disabled
                      />
                    )}
                    {/* --------------------------------------------image--------------------------------------- */}

                    {field.type === "image" && (
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          className={styles.image_input}
                          onChange={(e) =>
                            handleUpdateimage(field.id, e.target.files[0])
                          }
                        />
                        {field?.image && (
                          <img
                            src={URL.createObjectURL(field.image)}
                            alt="uploaded"
                            className={styles.image_preview}
                          />
                        )}
                      </div>
                    )}

                    {/*------------------------------------ This is for dropdown  ---------------------------------------------------*/}

                    {field.type === "dropdown" && (
                      <div className={styles.field_options}>
                        {field.options.map((option, index) => (
                          <div key={index}>
                            <input
                              id={`dropdown_optionInput_${index}_${field.id}`}
                              type="text"
                              className={styles.options_input}
                              value={option.option}
                              placeholder={`Option ${index + 1}`}
                              onChange={(e) =>
                                dhandleUpdateOption(
                                  field.id,
                                  index,
                                  e.target.value,
                                  sectionIndex,
                                )
                              }
                              // onClick={() =>
                              //   dhandleUpdateOption(
                              //     field.id,
                              //     index,
                              //     "",
                              //     sectionIndex,
                              //   )
                              // }
                              onFocus={() =>
                                dhandleUpdateOption(
                                  field.id,
                                  index,
                                  "",
                                  sectionIndex,
                                )
                              }
                              onBlur={() => {
                                if (option.option.trim() === "") {
                                  dhandleEmptyOption(
                                    field.id,
                                    index,
                                    sectionIndex,
                                  );
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  dhandleAddOption(field.id, sectionIndex);
                                }
                              }}
                            />
                            <i
                              className="fa-solid fa-lg fa-multiply text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                dhandleRemoveOption(
                                  field.id,
                                  index,
                                  sectionIndex,
                                )
                              }
                            ></i>
                          </div>
                        ))}
                        <button
                          // className="button button-secondary"
                          className={`${styles.button} ${styles.button_secondry}`}
                          onClick={() =>
                            dhandleAddOption(field.id, sectionIndex)
                          }
                        >
                          Add Option
                        </button>
                      </div>
                    )}

                    {/* ---------------------multiple choice option ---------------------------------- */}

                    {(field.type === "multiple_choice_option" ||
                      field.type === "checkbox_grid ") && (
                      <>
                        <div className={styles.parent}>
                          <div className={styles.row}>
                            {field.options.map((option, index) => (
                              <div key={index}>
                                {field.type === "multiple_choice_option" ? (
                                  <i className="fa-regular fa-square text-muted"></i>
                                ) : (
                                  <i className="fa-regular fa-circle text-muted"></i>
                                )}

                                <input
                                  type="text"
                                  className={styles.options_input}
                                  value={option.option}
                                  onChange={(e) =>
                                    handleUpdateOptionmcg(
                                      field.id,
                                      index,
                                      e.target.value,
                                      sectionIndex,
                                    )
                                  }
                                  placeholder="Row"
                                />

                                <i
                                  className="fa-solid fa-lg fa-multiply text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleRemoveOptionmcg(field.id, index)
                                  }
                                ></i>
                              </div>
                            ))}

                            <button
                              className="button button-secondary"
                              onClick={() =>
                                handleAddOptionmcg(field.id, sectionIndex)
                              }
                            >
                              Add Row
                            </button>
                          </div>

                          <div className={styles.column}>
                            {field.options.map((option, index) => (
                              <div key={index}>
                                {field.type === "multiple_choice_option" ? (
                                  <i className="fa-regular fa-square text-muted"></i>
                                ) : (
                                  <i className="fa-regular fa-circle text-muted"></i>
                                )}

                                <input
                                  type="text"
                                  className={styles.options_input}
                                  value={option.option}
                                  onChange={(e) =>
                                    handleUpdateOptionmcg(
                                      field.id,
                                      index,
                                      e.target.value,
                                      sectionIndex,
                                    )
                                  }
                                  placeholder="Column"
                                />

                                <i
                                  className="fa-solid fa-lg fa-multiply text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleRemoveOptionmcg(field.id, index)
                                  }
                                ></i>
                              </div>
                            ))}

                            <button
                              // className="button button-secondary"
                              className={`${styles.button} ${styles.button_secondry}`}
                              onClick={() =>
                                handleAddOptionmcg(field.id, sectionIndex)
                              }
                            >
                              Add Column
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {/*------------------------------------------- MultipleChoice ----------------------------*/}

                    {field.type === "multiple_choice" && (
                      <div className={styles.field_options}>
                        {field.options.map((option, index) => (
                          <>
                            {!option?.otherOption && (
                              <div className="position-relative" key={index}>
                                {field.answer === index ? (
                                  <i className="fa-solid fa-lg fa-check text-success"></i>
                                ) : (
                                  <i className="fa-regular fa-circle text-muted"></i>
                                )}
                                <input
                                  id={`multiple_optionInput_${index}_${field.id}`}
                                  type="text"
                                  placeholder={`Option ${index + 1}`}
                                  className={styles.options_input}
                                  value={option.option}
                                  onChange={(e) =>
                                    handleUpdateOption(
                                      field.id,
                                      index,
                                      e.target.value,
                                      sectionIndex,
                                    )
                                  }
                                  onFocus={() => {
                                    handleUpdateOption(
                                      field.id,
                                      index,
                                      "",
                                      sectionIndex,
                                    );
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleAddOption(field.id, sectionIndex);
                                    }
                                  }}
                                />

                                {/* {field.options.length > 1 && ( */}

                                {addingAnswer !== field.id ? (
                                  <i
                                    className="fa-solid fa-lg fa-multiply text-danger"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleRemoveOption(
                                        field.id,
                                        index,
                                        sectionIndex,
                                      )
                                    }
                                  ></i>
                                ) : (
                                  <i
                                    className="fa-solid fa-lg fa-check text-success"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleAddAnswer(
                                        field.id,
                                        sectionIndex,
                                        index,
                                      )
                                    }
                                  ></i>
                                )}
                                {/* )} */}
                              </div>
                            )}
                            {option?.otherOption && (
                              <>
                                <div className="position-relative" key={index}>
                                  {field.answer === index ? (
                                    <i className="fa-solid fa-lg fa-check text-success"></i>
                                  ) : (
                                    <i className="fa-regular fa-circle text-muted"></i>
                                  )}
                                  <input
                                    type="text"
                                    className={styles.options_input}
                                    // value={option.option}
                                    placeholder="Other"
                                    readOnly
                                  />
                                  {/* {field.options.length > 1 && ( */}

                                  {addingAnswer !== field.id ? (
                                    <i
                                      className="fa-solid fa-lg fa-multiply text-danger"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleRemoveOption(
                                          field.id,
                                          index,
                                          sectionIndex,
                                        )
                                      }
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa-solid fa-lg fa-check text-success"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleAddAnswer(
                                          field.id,
                                          sectionIndex,
                                          index,
                                        )
                                      }
                                    ></i>
                                  )}
                                  {/* )} */}
                                </div>
                              </>
                            )}
                          </>
                        ))}
                        <button
                          // className="button button-secondary"
                          className={`${styles.button} ${styles.button_secondry}`}
                          onClick={() => {
                            handleAddOption(field.id, sectionIndex);
                            setAnswerFieldId(field.id);
                          }}
                        >
                          Add Option
                        </button>
                        <span> or </span>
                        <span
                          onClick={() => {
                            handleAddOptionForOther(field.id, sectionIndex);
                            setAnswerFieldId(field.id);
                          }}
                        >
                          add other
                        </span>
                      </div>
                    )}

                    {/*------------------------------------------- Checkbox ----------------------------*/}

                    {field.type === "checkbox" && (
                      <div className={styles.field_options}>
                        {field.options.map((option, index) => (
                          <div key={index}>
                            <i className="fa-regular fa-square text-muted"></i>
                            <input
                              id={`check_optionInput_${index}_${field.id}`}
                              type="text"
                              className={styles.options_input}
                              value={option.option}
                              placeholder={`Option ${index + 1}`}
                              onChange={(e) =>
                                handleUpdateOption(
                                  field.id,
                                  index,
                                  e.target.value,
                                  sectionIndex,
                                )
                              }
                              // onClick={() =>
                              //   handleUpdateOption(
                              //     field.id,
                              //     index,
                              //     "",
                              //     sectionIndex,
                              //   )
                              // }
                              onFocus={() =>
                                handleUpdateOption(
                                  field.id,
                                  index,
                                  "",
                                  sectionIndex,
                                )
                              }
                              // onBlur={() => {
                              //   if (option.option.trim() === "") {
                              //     handleEmptyOption(
                              //       field.id,
                              //       index,
                              //       sectionIndex,
                              //     );
                              //   }
                              // }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleAddOption(field.id, sectionIndex);
                                }
                              }}
                            />

                            {/* {field.options.length > 1 && ( */}
                            <i
                              className="fa-solid fa-lg fa-multiply text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleRemoveOption(
                                  field.id,
                                  index,
                                  sectionIndex,
                                )
                              }
                            ></i>
                            {/* )} */}
                          </div>
                        ))}
                        <button
                          // className="button button-secondary"
                          className={`${styles.button} ${styles.button_secondry}`}
                          onClick={() =>
                            handleAddOption(field.id, sectionIndex)
                          }
                        >
                          Add Option
                        </button>
                      </div>
                    )}

                    <div className={styles.questioncard_options}>
                      {addingAnswer !== field.id &&
                      isQuiz &&
                      field.type === "multiple_choice" ? (
                        <div className="text-muted d-flex align-items-center gap-1 w-100">
                          <span
                            className="text-muted d-flex align-items-center gap-1 w-100"
                            onClick={() =>
                              handleAddAnswerClick(field.id, sectionIndex)
                            }
                          >
                            <i className="fa-solid fa-list-check"></i>
                            Add Answer
                          </span>
                          <span
                            className="text-muted d-flex align-items-center gap-1 w-100"
                            // onClick={() =>
                            //   handleAddAnswerClick(field.id, sectionIndex)
                            // }
                          >
                            <input
                              type="number"
                              placeholder={`Enter points ${index + 1}`}
                              className={styles.points_input}
                              value={field.points}
                              onChange={(e) =>
                                handleUpdatePoints(
                                  field.id,
                                  e.target.value,
                                  field.required,
                                  sectionIndex,
                                )
                              }
                              // onBlur={(e) => {
                              //   handleAutoTypeUpdate(
                              //     e.target.value,
                              //     field.id,
                              //     sectionIndex,
                              //   );
                              // }}
                              // onMouseLeave={() =>
                              //   handleUpdateQuestionUndo(
                              //     field.id,
                              //     field.question,
                              //     field.required,
                              //   )
                              // }
                            />
                          </span>
                        </div>
                      ) : null}
                      <i
                        className="fa-solid fa-copy mx-2 mt-1 text-muted"
                        // className={styles.fa_copy}
                        onClick={() => handleCopyField(field.id, sectionIndex)}
                      ></i>
                      <i
                        className="fa-solid fa-trash mx-2 mt-1 text-muted text-danger"
                        // style={{color:"#db4437"}}
                        // className={classNames(styles.fa_tras,"fa-soid fa-trash ")}
                        onClick={() =>
                          handleRemoveField(field.id, sectionIndex)
                        }
                      ></i>
                      <span className="ml-10 required_span">Required</span>
                      <Switch
                        className={styles.smallSwitch}
                        checked={field.required}
                        onChange={(checked) =>
                          handleUpdateQuestionRequired(
                            field.id,
                            field.question,
                            checked,
                            sectionIndex,
                          )
                        }
                      />
                    </div>
                  </Card>
                ))}
                {childData && (
                  <Card
                    className={classNames(styles.questionpage_card, "shadow")}
                  >
                    <div className={styles.inputandSelect_container}>
                      {/* <input
                    type="text"
                    placeholder="Enter Video Title"
                    className="question_input"
                    value={fullVideoData.title}
                  onChange={(e) => handleVideoTitleChange(e.target.value)}
                  onBlur={() =>
                    handleVideoTitleChangeUndo(formData.videoData.title)
                  }
                  /> */}
                    </div>
                    <div className={styles.iframe}>
                      {/* <iframe
                    title="YouTube Video"
                    width="50%"
                    height="250"
                    src={`https://www.youtube.com/embed/${fullVideoData.id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe> */}
                    </div>

                    <div className={styles.questioncard_options}>
                      <i
                        className="fa-solid fa-trash mx-2 mt-1 text-muted"
                        // onClick={() => handleRemoveVideoField()}
                      ></i>
                    </div>
                  </Card>
                )}

                {/* <div>
                  <button
                    className={styles.add_button}
                    onClick={handleAddForm}
                    disabled={isButtonDisabled}
                  >
                    Add Form
                  </button>
                </div> */}

                <div className={styles.button_container}></div>
              </div>
              <div></div>
            </div>

            <div className={`${styles.root_col} ${styles.root_col_2}`}>
              {sectionIndex === 0 && (
                <>
                  <HoverOverlay
                    placement="left"
                    hoverText="Auto Submit timer"
                    mainInput={
                      <Card
                        className={`${styles.timer_btn} `}
                        onClick={handleClickTimer}
                        ref={timeRef}
                      >
                        <i
                          className={`fa-solid fa-stopwatch-20 ${styles.timer_icon}`}
                        ></i>
                      </Card>
                    }
                  ></HoverOverlay>
                  <Overlay
                    show={showTimer}
                    target={targetTimer}
                    placement="top"
                    container={timeRef}
                    containerPadding={100}
                  >
                    <Popover id="popover-contained">
                      <Popover.Header as="h3">Time</Popover.Header>
                      <Popover.Body>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="time"
                            value={timer}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                timer: e.target.value,
                              });
                              setTimer(e.target.value);
                            }}
                            style={{ margin: "0.25rem" }}
                          />
                          <Button size="sm" onClick={() => setShowTimer(false)}>
                            done
                          </Button>
                        </div>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </>
              )}
              {sectionIndex === 0 && (
                <>
                  <HoverOverlay
                    placement="left"
                    hoverText="Form Date"
                    mainInput={
                      <Card
                        className={`${styles.timer_btn} `}
                        onClick={handleClickDatePicker}
                        ref={dateRef}
                      >
                        <i
                          className={`fa-solid fa-calendar-days ${styles.timer_icon}`}
                        ></i>
                      </Card>
                    }
                  ></HoverOverlay>
                  <Overlay
                    show={showDatePicker}
                    target={targetDatePicker}
                    placement="top"
                    container={dateRef}
                    containerPadding={100}
                  >
                    <Popover id="popover-contained">
                      <Popover.Header as="h3">Calendar</Popover.Header>
                      <Popover.Body>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="date"
                            value={formDate}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                date: e.target.value,
                              });
                              setFormDate(e.target.value);
                            }}
                            style={{ margin: "0.25rem" }}
                          />
                          <Button
                            size="sm"
                            onClick={() => setShowDatePicker(false)}
                          >
                            done
                          </Button>
                        </div>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </>
              )}
              <AddCardPage
                handleAddField={handleAddField}
                handleAddNewSection={handleAddNewSection}
                setChildData={setChildData}
                updateVideoData={handleUpdateVideoData}
                sectionIndex={sectionIndex}
                formTypeToggle={formTypeToggle}
                formType={formData.formType}
                isQuiz={isQuiz}
              />
            </div>
          </div>
        </div>
      ))}

      <div className={styles.add_button_wrapper}>
        <button
          className={styles.add_button}
          onClick={handleAddForm}
          disabled={isButtonDisabled}
        >
          Add Form
        </button>
      </div>
    </>
  );
};

export default CreateForms;
