import React from "react";
import styles from "../../css/Preview.module.css";
import { Button, Card, Container, Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

const TextQuestion = ({
  id,
  required,
  questionText,
  ind,
  qImgUrl,
  isSelfPreviewPage,
  type,
  handleInputChange,
  formErrors,
  value,
}) => {
  const dispatch = useDispatch();
  return (
    <Card className={styles.question_card}>
      <Form.Group key={id} className={styles.cardChange}>
        <Form.Label className="mb-3 w-100">
          <div className="d-flex">
            <p className="d-flex me-1">
              {required && <span className={styles.required}>*</span>}
              {questionText?.length !== 0 && `${ind + 1}. `}
            </p>
            <div>{questionText}</div>
          </div>
          {qImgUrl && qImgUrl?.length !== 0 && (
            <>
              <img
                src={qImgUrl}
                alt=""
                className={styles.preview_Question_image}
              />
            </>
          )}
        </Form.Label>
        <Form.Control
          disabled={isSelfPreviewPage}
          className={`${styles.text_input} ${styles.short_text_input}`}
          type={type}
          placeholder="Your answer"
          required={required}
          value={value}
          onChange={(e) =>
            dispatch(
              handleInputChange({
                questionId: id,
                value: e.target.value,
              }),
            )
          }
          isInvalid={formErrors.includes(
            `Please fill the required field: ${questionText}`,
          )}
        />
        <Form.Control.Feedback type="invalid">
          Please fill the required field: {questionText}
        </Form.Control.Feedback>
      </Form.Group>
    </Card>
  );
};


export default TextQuestion;
