import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { closeWorkspace } from "../../redux/action/workspace";
import { useAlert } from "react-alert";

const FormDeletePopup = ({
  leaveDeletePopup,
  handleLeaveDeletePopup,
  leaveDeleteData,
  category,
  handleDeletePerm,
  handleWorkspacePerm,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const userId = localStorage.getItem("user_id");
  const [buttonClicked, setButtonClicked] = useState(false);
  console.log(category, "category");
  console.log(leaveDeleteData, "leaveDeleteData");
  console.log(userId, leaveDeleteData?.userId?._id);

  const handleDeleteWorkspace = useCallback(
    (workspace) => {
      // logic
      handleDeletePerm(leaveDeleteData._id);
      handleLeaveDeletePopup();
    },
    [setButtonClicked, dispatch, handleLeaveDeletePopup],
  );

  return (
    <>
      <Modal show={leaveDeletePopup} onHide={handleLeaveDeletePopup} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="mx-1">
              {leaveDeleteData?.userId?._id === userId ? "Close" : "Leave"}
            </span>
            {category}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to
          <span className="ms-1">
            {leaveDeleteData?.userId?._id === userId ? "Close" : "leave"}
          </span>
          <span className="fw-bold mx-1">
            {category === "form"
              ? leaveDeleteData?.formTitle
              : leaveDeleteData?.workspaceName}
          </span>
          {category}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLeaveDeletePopup}>
            Cancel
          </Button>
          {category === "form" ? (
            <Button
              variant="danger"
              disabled={buttonClicked}
              onClick={() => handleDeleteWorkspace(leaveDeleteData)}
            >
              {leaveDeleteData?.userId?._id === userId
                ? "Delete Form"
                : "Leave Form"}
            </Button>
          ) : (
            <Button
              variant="danger"
              disabled={buttonClicked}
              onClick={() => {
                handleWorkspacePerm(leaveDeleteData?._id);
                handleLeaveDeletePopup();
              }}
            >
              {leaveDeleteData?.userId?._id === userId
                ? "Delete workspace"
                : "Leave workspace"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormDeletePopup;
