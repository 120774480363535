import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import _debounce from "lodash/debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RenderFields from "../components/Fields/RenderFields";
import Header from "../components/Headers/Header";
import Section from "../components/Section/Section";
import { ToolsRight } from "../components/ToolsRight/ToolsRight";
import styles from "../css/CreateForm.module.css";
import Loader from "../loader";
import { editForm } from "../redux/slice/formSlice";
import {
  addDescriptionToUndoStack,
  addQuestionOnEnter,
  addTitleToUndoStack,
  addToUndoStack,
  deleteQuestion,
  editFormOnEnter,
  editQuestionOnEnter,
  editSectionOnEnter,
  fetchFormQuestions,
  handleSectionRemove,
  setFormData,
  setFormDescription,
  setFormTitle,
  setSubmitButtonText,
} from "../redux/slice/previewSlice";
import { API_URL, cookieToken } from "../service/url";
import ConditionQuestion  from "../components/conditionRoutes/conditionQuestion";

const EditForm = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [targetTimer, setTargetTimer] = useState(null);
  const timeRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [formDate, setFormDate] = useState(null);
  const dateRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [targetDatePicker, setTargetDatePicker] = useState(null);
  const [newOptionIndex, setNewOptionIndex] = useState(null);

  useEffect(() => {
    if (newOptionIndex !== null) {
      let toFind;

      switch (newOptionIndex.type) {
        case "multiple_choice":
          toFind = `multiple_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
          break;

        case "checkbox":
          toFind = `check_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
          break;

        case "dropdown":
          toFind = `dropdown_optionInput_${newOptionIndex.index}_${newOptionIndex.fieldid}`;
          break;

        default:
          toFind = null;
          break;
      }

      const inputElement = document.getElementById(toFind);
      if (inputElement) {
        inputElement.focus();
      }

      // Reset the new option index
      setNewOptionIndex(null);
    }
  }, [newOptionIndex]);

  const handleClickTimer = (event) => {
    setShowTimer(!showTimer);
    setTargetTimer(event.target);
  };

  const { formId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFormQuestions(formId));
  }, []);

  const formData = useSelector((state) => state.preview.formData);

  const formTitle = useSelector((state) => state.preview.formTitle);

  const loading = useSelector((state) => state.preview.loading);

  const [childData, setChildData] = useState(null);

  const [addingAnswer, setAddingAnswer] = useState(null);
  const handleQuestionSaveOnEnter = (value, update, questionId) => {
    dispatch(editQuestionOnEnter({ value, update, questionId }));
  };
  const handleQuestionSaveAnswerOnEnter = (value, update, questionId) => {
    dispatch(editQuestionOnEnter({ value, update, questionId }));
  };
  const handleCopyField = useCallback(
    (fieldId, sectionIndex) => {
      const fieldToCopy = formData.sections[sectionIndex].questions.find(
        (field) => field.id === fieldId,
      );

      if (fieldToCopy) {
        const newField = {
          ...fieldToCopy,
          id: Date.now().toString(),
          qindex: formData.sections[sectionIndex].questions.length,
        };

        dispatch(addQuestionOnEnter(newField)).then((res) => {
          const updatedNewField = {
            ...newField,
            _id: res.payload._id,
          };

          const updatedSections = formData.sections.map((section, index) => {
            if (index === sectionIndex) {
              return {
                ...section,
                questions: [...section.questions, updatedNewField],
              };
            }
            return { ...section };
          });

          const updatedFormData = {
            ...formData,
            sections: updatedSections,
          };

          dispatch(setFormData(updatedFormData));
        });
      }
    },
    [dispatch, formData],
  );

  const handleUpdateQuestionRequired = useCallback(
    (fieldId, question, required, sectionIndex) => {
      const updatedFields = formData.sections[sectionIndex]?.questions.map(
        (field) => (field.id === fieldId ? { ...field, required } : field),
      );

      if (updatedFields) {
        const updatedSections = formData.sections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              questions: updatedFields,
            };
          } else {
            return { ...section };
          }
        });

        const updatedFormData = {
          ...formData,
          sections: updatedSections,
        };

        dispatch(setFormData(updatedFormData));
      }
    },
    [dispatch, formData],
  );

  const handleUpdatePoints = useCallback(
    (fieldId, points, sectionIndex, questionId) => {
      const updatedQuestions = formData.sections[sectionIndex]?.questions.map(
        (field) => (field.id === fieldId ? { ...field, points } : field),
      );

      if (updatedQuestions) {
        const updatedSections = formData.sections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              questions: updatedQuestions,
            };
          } else {
            return { ...section };
          }
        });

        const updatedFormData = {
          ...formData,
          sections: updatedSections,
        };

        dispatch(setFormData(updatedFormData));

        setTimeout(() => {
          // Dispatch an action to update the points for the specific question
          const data = {
            update: "points",
            value: points,
            questionId,
          };

          if (points !== "") {
            dispatch(editQuestionOnEnter({ ...data }));
          }
        }, 1000);
      }
    },
    [dispatch, formData],
  );

  const handleUpdateFieldType = useCallback(
    (fieldId, type, sectionIndex) => {
      const updatedFields = formData.sections[sectionIndex]?.questions.map(
        (field) => (field.id === fieldId ? { ...field, type } : field),
      );

      if (updatedFields) {
        const updatedSections = formData.sections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              questions: updatedFields,
            };
          } else {
            return { ...section };
          }
        });

        const updatedFormData = {
          ...formData,
          sections: updatedSections,
        };

        dispatch(setFormData(updatedFormData));
      }
    },
    [dispatch, formData],
  );

  const handleRemoveField = (fieldId, sectionIndex) => {
    if (sectionIndex >= 0 && sectionIndex < formData.sections.length) {
      // Find the question index in the section
      const questionIndex = formData.sections[sectionIndex].questions.findIndex(
        (question) => question.id === fieldId,
      );

      if (questionIndex !== -1) {
        // Remove the question from the section's questions array
        const updatedQuestions = [
          ...formData.sections[sectionIndex].questions,
        ].filter((question) => question.id !== fieldId);
        // updatedQuestions.splice(questionIndex, 1);

        // Update the form data with the modified questions array
        const updatedFormData = {
          ...formData,
          sections: [
            ...formData.sections.slice(0, sectionIndex),
            {
              ...formData.sections[sectionIndex],
              questions: updatedQuestions,
            },
            ...formData.sections.slice(sectionIndex + 1),
          ],
        };

        // Update the state with the new form data
        dispatch(setFormData(updatedFormData));

        // Call the API to update the form
        const removedQuestion =
          formData.sections[sectionIndex].questions[questionIndex];
        const currentSection = formData.sections[sectionIndex];

        dispatch(
          deleteQuestion({
            questionID: removedQuestion._id,
            sectionId: currentSection._id,
          }),
        );
      }
    }
  };
  const handleRemoveOption = useCallback(
    _debounce((fieldId, optionIndex, sectionIndex) => {
      const updatedFields = formData.sections[sectionIndex]?.questions.map(
        (field) => {
          if (
            field.id === fieldId &&
            (field.type === "multiple_choice" || field.type === "checkbox")
          ) {
            const options = [...field.options];
            options.splice(optionIndex, 1);

            // Dispatch an action to update the options using Redux
            const value = options;
            const update = "editoptions";
            const questionId = field._id;
            dispatch(editQuestionOnEnter({ value, update, questionId }));

            return { ...field, options };
          }
          return field;
        },
      );

      if (updatedFields) {
        const updatedSections = formData.sections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              questions: updatedFields,
            };
          } else {
            return { ...section };
          }
        });

        // Dispatch an action to update the entire formData in the store
        const updatedFormData = {
          ...formData,
          sections: updatedSections,
        };
        dispatch(setFormData(updatedFormData));
      }
    }, 500),
    [dispatch, formData],
  );
  const handleUpdateQuestion = useCallback(
    (fieldId, question, sectionIndex) => {
      const updatedQuestions = formData.sections[sectionIndex]?.questions.map(
        (field) => (field.id === fieldId ? { ...field, question } : field),
      );

      if (updatedQuestions) {
        const updatedSections = formData.sections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              questions: updatedQuestions,
            };
          }
          return section;
        });
        const updatedFormData = {
          ...formData,
          sections: updatedSections,
        };
        dispatch(setFormData(updatedFormData));
      }
    },
    [dispatch, formData],
  );
  const handleUpdateOption = (fieldId, optionIndex, option, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex]?.questions.map(
      (field) => {
        if (field.id === fieldId) {
          const options = [...field.options];
          options[optionIndex] = { option, _id: options[optionIndex]._id };

          return { ...field, options };
        }
        return field;
      },
    );

    if (updatedFields) {
      const updatedSections = formData.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            questions: updatedFields,
          };
        } else {
          return { ...section };
        }
      });

      // Dispatch an action to update the entire formData in the store
      const updatedFormData = {
        ...formData,
        sections: updatedSections,
      };
      dispatch(setFormData(updatedFormData));
    }
  };
  const handleUpdatedate = (fieldId, date) => {
    const updatedFields = formData.questions.map((field) =>
      field.id === fieldId ? { ...field, dateValue: date } : field,
    );

    dispatch(setFormData({ ...formData, questions: updatedFields }));
  };
  const handleAddAnswer = useCallback(
    (fieldId, sectionIndex, answerIndex, questionId) => {
      const updatedSections = formData?.sections.map((section, index) => {
        if (index === sectionIndex) {
          const updatedQuestions = section.questions.map((field) => {
            if (
              field.id === fieldId &&
              (field.type === "multiple_choice" || field.type === "checkbox")
            ) {
              return { ...field, answer: answerIndex };
            }
            return field;
          });

          return {
            ...section,
            questions: updatedQuestions,
          };
        }
        return { ...section };
      });

      const updatedFormData = {
        ...formData,
        sections: updatedSections,
      };
      dispatch(setFormData(updatedFormData));

      handleQuestionSaveAnswerOnEnter(answerIndex, "editanswer", questionId);
      setAnswerFieldId(null);
      setAddingAnswer(null);
    },
    [dispatch, formData, handleQuestionSaveAnswerOnEnter],
  );
  const handleAddAnswerClick = useCallback((fieldId) => {
    setAddingAnswer(fieldId);
    setAnswerFieldId(fieldId);
  }, []);
  const handleRemoveQuestionImage = (fieldId, indexSect) => {
    const updatedFields = formData?.sections[indexSect]?.questions.map(
      (field) => (field.id === fieldId ? { ...field, qImgUrl: "" } : field),
    );
    const questionId = formData?.sections[indexSect]?.questions.find(
      (field) => field.id === fieldId,
    )?._id;

    dispatch(
      editQuestionOnEnter({
        value: "",
        update: "qImgUrl",
        questionId,
      }),
    );

    // Dispatch an action to update the entire formData in the store
    const updatedSections = formData?.sections.map((section, index) => {
      if (index === indexSect) {
        return {
          ...section,
          questions: updatedFields,
        };
      }
      return { ...section };
    });
    const updatedFormData = {
      ...formData,
      sections: updatedSections,
    };
    dispatch(setFormData(updatedFormData));
  };
  const handleImageChange = async (event, fieldId, index, sectionIndex) => {
    const file = event.target.files[0];
    if (file) {
      const QImgFormData = new FormData();
      QImgFormData.append("questionImage", file);

      try {
        const response = await axios.post(
          `${API_URL}/form/question/questionImage`,
          QImgFormData,
          {
            headers: {
              "x-access-token": cookieToken,
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (response?.data?.success) {
          // setQuesImageUrl(response.data.url);

          handleUpdateQuestionImgUrl(
            fieldId,
            response?.data?.url,
            sectionIndex,
          );
        } else {
          console.log("file not uploaded");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUpdateQuestionImgUrl = (fieldId, qImgUrl, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex]?.questions.map(
      (field) => {
        if (field.id === fieldId) {
          const questionId = field._id;

          // Dispatch an action to update the question image URL using Redux
          dispatch(
            editQuestionOnEnter({
              value: qImgUrl,
              update: "qImgUrl",
              questionId,
            }),
          );

          return { ...field, qImgUrl };
        }
        return field;
      },
    );

    if (updatedFields) {
      const updatedSections = formData.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            questions: updatedFields,
          };
        }
        return { ...section };
      });

      // Dispatch an action to update the entire formData in the store
      const updatedFormData = {
        ...formData,
        sections: updatedSections,
      };
      dispatch(setFormData(updatedFormData));
    }
  };
  const handleUpdatetime = (fieldId, time) => {
    const updatedFields = formData.questions.map((field) =>
      field.id === fieldId ? { ...field, timeValue: time } : field,
    );

    dispatch(setFormData({ ...formData, questions: updatedFields }));
  };
  const handleAddOptionmcg = useCallback(
    (fieldId) => {
      const updatedFields = formData.questions.map((field) => {
        if (
          field.id === fieldId &&
          (field.type === "multiple_choice_option" ||
            field.type === "checkbox_grid")
        ) {
          const newOption = { option: "" };
          return { ...field, options: [...field.options, newOption] };
        }
        return field;
      });

      dispatch(setFormData({ ...formData, questions: updatedFields }));
    },
    [formData, dispatch],
  );
  const handleUpdateOptionmcg = (
    fieldId,
    optionIndex,
    option,
    sectionIndex,
  ) => {
    const updatedFields = formData.sections[sectionIndex].questions.map(
      (field) => {
        if (
          field.id === fieldId &&
          (field.type === "multiple_choice_option" ||
            field.type === "checkbox_grid")
        ) {
          const options = [...field.options];
          options[optionIndex] = { option };
          return { ...field, options: options };
        }
        return field;
      },
    );
    const updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].questions = updatedFields;

    dispatch(setFormData(updatedFormData));
  };
  const handleRemoveOptionmcg = (fieldId, optionIndex) => {
    const updatedFields = formData.questions.map((field) => {
      if (
        field.id === fieldId &&
        (field.type === "multiple_choice_option" ||
          field.type === "checkbox_grid")
      ) {
        const options = [...field.options];
        options.splice(optionIndex, 1);
        return { ...field, options: options };
      }
      return field;
    });

    dispatch(setFormData({ ...formData, questions: updatedFields }));
  };
  const dhandleUpdateOption = (fieldId, optionIndex, option, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex]?.questions.map(
      (field) => {
        if (field.id === fieldId && field.type === "dropdown") {
          const options = [...field.options];
          options[optionIndex] = { option, _id: options[optionIndex]?._id };

          return { ...field, options: options };
        }
        return field;
      },
    );

    if (updatedFields) {
      const updatedSections = formData.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            questions: updatedFields,
          };
        }
        return { ...section };
      });

      // Dispatch an action to update the entire formData in the store
      const updatedFormData = {
        ...formData,
        sections: updatedSections,
      };
      dispatch(setFormData(updatedFormData));
    }
  };

  const dhandleAddOption = (fieldId, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex]?.questions.map(
      (field) => {
        if (field.id === fieldId && field.type === "dropdown") {
          const newOption = {
            option: "",
          };

          setNewOptionIndex({
            index: field.options.length,
            type: field.type,
            fieldid: field.id,
          });

          return { ...field, options: [...field.options, newOption] };
        }
        return field;
      },
    );

    if (updatedFields) {
      const updatedSections = formData.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            questions: updatedFields,
          };
        } else {
          return { ...section };
        }
      });

      // Dispatch an action to update the entire formData in the store
      const updatedFormData = {
        ...formData,
        sections: updatedSections,
      };
      dispatch(setFormData(updatedFormData));
    }
  };
  const dhandleRemoveOption = (fieldId, optionIndex, sectionIndex) => {
    const updatedFields = formData.sections[sectionIndex]?.questions.map(
      (field) => {
        if (field.id === fieldId && field.type === "dropdown") {
          const options = [...field.options];
          options.splice(optionIndex, 1);

          // Dispatch an action to update the options using Redux
          const value = options;
          const update = "editoptions";
          const questionId = field?._id;
          dispatch(editQuestionOnEnter({ value, update, questionId }));

          return { ...field, options: options };
        }
        return field;
      },
    );

    if (updatedFields) {
      const updatedSections = formData.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            questions: updatedFields,
          };
        }
        return { ...section };
      });

      // Dispatch an action to update the entire formData in the store
      const updatedFormData = {
        ...formData,
        sections: updatedSections,
      };
      dispatch(setFormData(updatedFormData));
    }
  };
  const handleAddOption = useCallback(
    _debounce((fieldId, sectionIndex) => {
      const updatedFields = formData.sections[sectionIndex]?.questions.map(
        (field) => {
          if (
            field.id === fieldId &&
            (field.type === "multiple_choice" || field.type === "checkbox")
          ) {
            const newOption = {
              option: "",
            };

            // Dispatch an action to add the option using Redux
            const value = newOption;
            const questionId = field._id;
            const update = "Addoptions";
            dispatch(editQuestionOnEnter({ value, update, questionId })).then(
              () => {
                // Handle any additional logic after the option is added
                setNewOptionIndex({
                  index: field.options.length,
                  type: field.type,
                  fieldid: field.id,
                });
              },
            );

            return { ...field, options: [...field.options, newOption] };
          }
          return field;
        },
      );

      if (updatedFields) {
        const updatedSections = formData.sections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              questions: updatedFields,
            };
          }
          return { ...section };
        });

        // Dispatch an action to update the entire formData in the store
        const updatedFormData = {
          ...formData,
          sections: updatedSections,
        };
        dispatch(setFormData(updatedFormData));
      }
    }, 500),
    [dispatch, formData],
  );
  const handleFormTitleChange = useCallback(
    (e, sectionIndex) => {
      dispatch(setFormTitle({ sectionIndex, title: e.target.value }));
    },
    [dispatch],
  );
  const handleFormTitleChangeUndo = useCallback(
    (title) => {
      dispatch(addTitleToUndoStack({ title }));
    },
    [dispatch],
  );
  const handleFormDescriptionChange = useCallback(
    (e, sectionIndex) => {
      dispatch(
        setFormDescription({ sectionIndex, description: e.target.value }),
      );
    },
    [dispatch],
  );

 

  const handleFormDescriptionChangeUndo = useCallback(
    (description) => {
      dispatch(addDescriptionToUndoStack({ description }));
    },
    [dispatch],
  );
  const handleSubmitButtonTextChange = useCallback(
    (e) => {
      const newButtonText = e.target.value;
      dispatch(setSubmitButtonText(newButtonText));
    },
    [dispatch],
  );

  const handleSubmitButtonTextChangeUndo = useCallback(() => {
    const currentButtonText = formData.submitButtonText;
    dispatch(
      addToUndoStack({ key: "submitButtonText", value: currentButtonText }),
    );
  }, [dispatch, formData?.submitButtonText]);

  const handleSectionSaveOnEnter = (value, update, sectionId) => {
    dispatch(editSectionOnEnter({ value, update, sectionId }));
  };

 

  const handleEditFormOnEnter = useCallback(
    (value, update, formId) => {
      dispatch(editFormOnEnter({ value, update, formId }));
    },
    [dispatch],
  );

  const [showDelteModal, setShowDeleteModal] = useState(false);
  const [deleteSectionDetails, setDeleteSectonDetails] = useState(null);
  const handleCloseDeletePopup = useCallback(() => {
    setDeleteSectonDetails(null);
    setShowDeleteModal(false);
  }, []);
  const handleDeleteSection = useCallback(
    ({ sectionId, update, formId }) => {
      handleEditFormOnEnter(sectionId, update, formId);
      dispatch(handleSectionRemove(sectionId));
      setShowDeleteModal(false);
    },
    [dispatch, handleEditFormOnEnter],
  );
  const handleUpdateimage = (fieldId, image) => {
    const updatedFields = formData.questions.map((field) =>
      field.id === fieldId ? { ...field, image: image } : field,
    );

    dispatch(setFormData({ ...formData, questions: updatedFields }));
  };
  const handleUpdateVideoData = (videoData) => {
    dispatch(setFormData({ ...formData, videoData }));
  };
  const [themeImage, setThemeImage] = useState(null);
  // const [selectImageUrl, setSelectedImageUrl] = useState(null);
  const handleImageUrl = async (data) => {
    // Update the formData with the new themeImage
    // console.log()
    dispatch(
      setFormData({
        ...formData,
        imageTheme: data,
      }),
    );
  };
  const handleClickDatePicker = (event) => {
    setShowDatePicker(!showDatePicker);
    setTargetDatePicker(event.target);
  };
  const handleImageTheme = (url) => {
    dispatch(editFormOnEnter({ value: url, update: "image", formId }));
  };

  const [answerFieldId, setAnswerFieldId] = useState(null);

  const handleAddNewSection = (value, update, formId) => {
    dispatch(editFormOnEnter({ value, update, formId }));
  };

  const formTypeToggle = useCallback(() => {
    const data = { update: "isQuiz", value: !formData.isQuiz };
    dispatch(editForm({ formId: formId, formData: data }));

    // Update the isQuiz property in formData synchronously
    dispatch(setFormData({ ...formData, isQuiz: !formData.isQuiz }));
  }, [dispatch, formId, formData]);
  const handleAddField = useCallback(
    _debounce((type, sectionIndex) => {
      let newField = {
        id: new Date().getTime(),
        question: "",
        required: false,
        type: type,
        options: [],
        img: null,
        qindex: formData?.sections[sectionIndex]?.questions?.length,
        sectionId: formData?.sections[sectionIndex]._id,
        formId: formData?.formId,
      };

      if (type === "multiple_choice") {
        newField.points = 1;
      }

      dispatch(addQuestionOnEnter(newField))
        .then((res) => {
          const updatedNewField = {
            ...newField,
            _id: res.payload._id,
          };

          const updatedSections = formData.sections.map((section, index) => {
            if (index === sectionIndex) {
              const updatedQuestions = Array.isArray(section?.questions)
                ? [...section.questions, updatedNewField]
                : [updatedNewField];

              return {
                ...section,
                questions: updatedQuestions,
              };
            }
            return { ...section };
          });

          const updatedFormData = {
            ...formData,
            sections: updatedSections,
          };

          dispatch(setFormData(updatedFormData));
        })
        .catch((err) => alert.error(err.message));
    }, 500),
    [dispatch, formData, alert],
  );

  return (
    <>
      {/* {console.log(formData)} */}
      <Header
        title={formTitle ? formTitle : formData?.sections?.[0]?.title}
        handleFormTitleChange={handleFormTitleChange}
        formData={formData}
        formId={formId}
        // handleUndo={handleUndo}
        // handleRedo={handleRedo}
        // setFormData={setFormData}
        handleImageTheme={handleImageTheme}
        setThemeImage={setThemeImage}
        // setSelectedImageUrl={setSelectedImageUrl}
        setSelectedImageUrl={handleImageUrl}
        showTheme={true}
        showPreviews={true}
        editing={true}
        handleEditFormOnEnter={handleEditFormOnEnter}
      />
      {/* dialog box */}
      <Dialog
        open={showDelteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="responsive-dialog-title"
        fullWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete Section and its Questions?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDeletePopup}>
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteSection({ ...deleteSectionDetails })}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialog box */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {formData?.sections?.map((section, sectionIndex) => (
            <div key={section._id}>
              <div className={styles.root_wrapper}>
                <div className={styles.root_col}>
                  {formData?.imageTheme && sectionIndex === 0 && (
                    <div className="Nothing">
                      <img
                        className={styles.customize_theme}
                        src={formData.imageTheme}
                        alt=""
                      />
                    </div>
                  )}
                  <Section
                    formData={formData}
                    sectionIndex={sectionIndex}
                    timer={timer}
                    setShowDeleteModal={setShowDeleteModal}
                    setDeleteSectonDetails={setDeleteSectonDetails}
                    section={section}
                    formId={formId}
                    handleFormTitleChange={handleFormTitleChange}
                    handleFormTitleChangeUndo={handleFormTitleChangeUndo}
                    handleSectionSaveOnEnter={handleSectionSaveOnEnter}
                    handleFormDescriptionChange={handleFormDescriptionChange}
                    handleFormDescriptionChangeUndo={
                      handleFormDescriptionChangeUndo
                    }
                    handleSubmitButtonTextChange={handleSubmitButtonTextChange}
                    handleSubmitButtonTextChangeUndo={
                      handleSubmitButtonTextChangeUndo
                    }
                    handleEditFormOnEnter={handleEditFormOnEnter}
                  />

                  {/* ------------------------------------------------Render  field type ------------------------------------------------*/}
                 
                  <RenderFields
                    section={section}
                    sectionIndex={sectionIndex}
                    handleUpdateQuestion={handleUpdateQuestion}
                    handleQuestionSaveOnEnter={handleQuestionSaveOnEnter}
                    handleRemoveQuestionImage={handleRemoveQuestionImage}
                    handleImageChange={handleImageChange}
                    handleUpdateFieldType={handleUpdateFieldType}
                    isQuiz={formData?.isQuiz}
                    handleUpdatedate={handleUpdatedate}
                    handleUpdatetime={handleUpdatetime}
                    handleUpdateimage={handleUpdateimage}
                    dhandleUpdateOption={dhandleUpdateOption}
                    dhandleAddOption={dhandleAddOption}
                    dhandleRemoveOption={dhandleRemoveOption}
                    handleUpdateOptionmcg={handleUpdateOptionmcg}
                    handleRemoveOptionmcg={handleRemoveOptionmcg}
                    handleAddOptionmcg={handleAddOptionmcg}
                    handleUpdateOption={handleUpdateOption}
                    handleAddOption={handleAddOption}
                    addingAnswer={addingAnswer}
                    handleRemoveOption={handleRemoveOption}
                    handleAddAnswer={handleAddAnswer}
                    handleAddAnswerClick={handleAddAnswerClick}
                    handleUpdatePoints={handleUpdatePoints}
                    handleCopyField={handleCopyField}
                    handleRemoveField={handleRemoveField}
                    handleUpdateQuestionRequired={handleUpdateQuestionRequired}
                    childData={childData}
                  />
                   {section?.isConditional && (
                    <ConditionQuestion
                      sectionIndex={sectionIndex}
                      section={section}
                    />
                  )}

                  <div />
                </div>
                <ToolsRight
                  sectionId={section?._id}
                  isConditional={section?.isConditional}
                  sectionIndex={sectionIndex}
                  handleClickTimer={handleClickTimer}
                  timeRef={timeRef}
                  showTimer={showTimer}
                  targetTimer={targetTimer}
                  timer={timer}
                  setFormData={setFormData}
                  formData={formData}
                  setTimer={setTimer}
                  handleEditFormOnEnter={handleEditFormOnEnter}
                  formId={formId}
                  setShowTimer={setShowTimer}
                  handleClickDatePicker={handleClickDatePicker}
                  dateRef={dateRef}
                  showDatePicker={showDatePicker}
                  targetDatePicker={targetDatePicker}
                  formDate={formDate}
                  setFormDate={setFormDate}
                  setShowDatePicker={setShowDatePicker}
                  handleAddField={handleAddField}
                  handleAddNewSection={handleAddNewSection}
                  setChildData={setChildData}
                  handleUpdateVideoData={handleUpdateVideoData}
                  formTypeToggle={formTypeToggle}
                />
              </div>
            </div>
          ))}
          {/* <div className={styles.add_button_wrapper}>
            <button
              className={styles.add_button}
              onClick={handleAddForm}
              disabled={isButtonDisabled}
            >
              Edit Form
            </button>
          </div> */}
        </>
      )}
    </>
  );
};

export default EditForm;
