import React, { useCallback, useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DriveLogo from "../../assets/Drive.png";
// import "../../css/Header.css";
import LogoutIcon from "@mui/icons-material/Logout";
import { Menu } from "@mui/material";
import axios from "axios";
import classNames from "classnames/bind";
import Cookies from "js-cookie";
import styles from "../../css/Header.module.css";
import { cx, drive, idea, risk, task, whiteboards } from "../../service/route";
import { API_URL, cookieToken } from "../../service/url";

// import { useCookies } from 'react-cookie';
import { BsGrid3X3GapFill } from "react-icons/bs";
import FormLogo from "../../assets/form logo.png";

import { useCookies } from "react-cookie";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData } from "../../redux/slice/profileSlice";
import HoverOverlay from "../HoverOverlay";

// import { useDispatch } from "react-redux";
const HeaderAll = ({ setSearch, search, showBreak }) => {
  // const [anchorElOne, setAnchorElOne] = useState(null);
  // const handleClickOne = (event) => {
  //   setAnchorElOne(event.currentTarget);
  // };
  // const handleCloseOne = () => {
  //   setAnchorElOne(null);
  // };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentURL = window.location.href;
  const SplitURL = currentURL.split("/");
  const [currenturl, setCurrentUrl] = useState(SplitURL);
  const [showSearch, setShowSearch] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clearCookieFromAllDomains = useCallback(() => {
    const domain = window.location.hostname;
    const path = "/"; // Adjust the path based on your cookie configuration

    // Clear the cookie for the current domain
    Cookies.remove("account_token", { domain, path });

    // Clear the cookie for each subdomain
    const subdomains = getSubdomains(domain);
    subdomains.forEach((subdomain) => {
      Cookies.remove("account_token", { domain: subdomain, path });
    });
    window.location.reload();
  }, []);

  // Helper function to get subdomains of a domain
  const getSubdomains = useCallback((domain) => {
    const domainParts = domain.split(".");
    const subdomains = [];

    for (let i = domainParts.length - 2; i >= 0; i--) {
      subdomains.push(domainParts.slice(i).join("."));
    }

    return subdomains;
  }, []);
  // const [userName, setUserName] = useState();
  const [newUser, setNewUser] = useState();
  let token = cookieToken;

  var decoded = jwt_decode(token);

  useEffect(() => {
    setCurrentUrl(SplitURL);
    setShowSearch(
      SplitURL.includes("responses") || SplitURL.includes("profile")
        ? false
        : true,
    );
    // setUserName(decoded._doc.userId.username)
    setNewUser(decoded._doc.userId.username.toUpperCase().slice(0, 2));
  }, []);

  // for grid icon
  // const [selectedDomain, setSelectedDomain] = useState("");
  const [cookies, setCookie] = useCookies(["selectedDomain"]);

  useEffect(() => {
    // Retrieve the stored domain from the cookie
    const storedDomain = cookies.selectedDomain;
    if (storedDomain) {
      // setSelectedDomain(storedDomain);
    }
  }, [cookies]);

  const handleDomainChange = useCallback(
    (newDomain) => {
      // Set the selected domain in the cookie
      setCookie("selectedDomain", newDomain, { path: "/" });

      // Update the selected domain state
      // setSelectedDomain(newDomain);

      try {
        // Open a new tab with a blank page
        const newTab = window.open("about:blank", "_blank");

        // Set the location of the new tab to the selected domain
        newTab.location.href = `https://${newDomain}`;
      } catch (error) {
        console.error("Error navigating to the selected domain:", error);
      }
    },
    [setCookie],
  );

  // for logout
  const [img, setImg] = useState("");

  const profileData = useSelector((store) => store.profile);
  useEffect(() => {
    if (!profileData.userId) dispatch(fetchProfileData());

    setImg(profileData?.img);
  }, [profileData, dispatch]);
  const handleLogout = useCallback(() => {
    localStorage.clear();
    clearCookieFromAllDomains();
    window.location.reload();
  }, []);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top" className={styles.navbar_nav}>
      <Container fluid>
        <Navbar.Brand>
          <NavLink className="nav-link" to="/">
            <img src={FormLogo} className={styles.forms_logo} alt="logo-icon" />
          </NavLink>
        </Navbar.Brand>

        {showSearch && (
          <Form.Control
            // className="text-center w-25 fm"
            className={classNames(styles.fm, "text-center w-25 fm ")}
            type="email"
            placeholder="Search..."
            value={search}
            onChange={handleInputChange}
          />
        )}

        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto align-items-center ">
            {/* <HoverOverlay
                placement="bottom"
                hoverText="Create Form"
                mainInput={
                  <Nav.Item
                    className={styles.nav_icons}
                    onClick={() => navigate("/createForm")}
                  >
                    <FiPlusCircle />
                  </Nav.Item>
                }
              /> */}
            {currenturl.length !== 4 ? (
              <HoverOverlay
                placement="bottom"
                hoverText="All Forms"
                mainInput={
                  <Nav.Item
                    className={styles.nav_icons}
                    onClick={() => navigate("/")}
                  >
                    <MdOutlineLibraryBooks />
                  </Nav.Item>
                }
              />
            ) : null}
            <Nav.Item className={styles.nav_icons}>
              <div className="dropdown">
                <button
                  className="d-flex hidden-arrow dropdown-toggle"
                  // className={classNames(`${styles.hidden_arrow} ${styles.dropdown_toggle} "d-flex"`)}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsGrid3X3GapFill />
                </button>
                <ul
                  className="dropdown-menu switch-to"
                  // className={`${styles.dropdown_menu} ${styles.switch_to}`}
                >
                  <h6 className="">Switch to</h6>
                  <div className=" ">
                    <a
                      className={classNames(styles.dropdown_link, "link_css")}
                      href={`https://${drive}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="dropdown-item">
                        <span className="text-muted text-sm">
                          <img
                            className={styles.switch_dots}
                            src={DriveLogo}
                            alt="icon"
                          />
                        </span>
                        Drive
                      </div>
                    </a>
                  </div>
                  <div className="dropdown-divider"></div>

                  <div className=" ">
                    <Link
                      className={classNames(styles.dropdown_link, "link_css")}
                      onClick={handleDomainChange.bind(this, task)}
                    >
                      <div className="dropdown-item">
                        <span className="  text-muted text-sm ">
                          <img
                            className={styles.switch_dots}
                            src={
                              // process.env.PUBLIC_URL +
                              "/Images/Navbarimg/Task.ico"
                            }
                            alt="icon"
                          />
                        </span>
                        Taskdeployer
                      </div>
                    </Link>
                  </div>
                  <div className="dropdown-divider"></div>

                  <div className=" ">
                    <Link
                      className={classNames(styles.dropdown_link, "link_css")}
                      onClick={handleDomainChange.bind(this, idea)}
                    >
                      <div className="dropdown-item  ">
                        <span className="  text-muted text-sm ">
                          <img
                            className={styles.switch_dots}
                            src={
                              // process.env.PUBLIC_URL +
                              "/Images/Navbarimg/Idea-deployer-favicon.ico"
                            }
                            alt="icon"
                          />
                        </span>
                        Ideadeployer
                      </div>
                    </Link>
                  </div>
                  <div className="dropdown-divider"></div>
                  <div className=" ">
                    <Link
                      className={classNames(styles.dropdown_link, "link_css")}
                      onClick={handleDomainChange.bind(this, cx)}
                    >
                      <div className="dropdown-item  ">
                        <span className="text-muted text-sm ">
                          <img
                            className={styles.switch_dots}
                            src={
                              // process.env.PUBLIC_URL +
                              "/Images/Navbarimg/cx-f.ico"
                            }
                            alt="icon"
                          />
                        </span>
                        Cxdeployer
                      </div>
                    </Link>
                  </div>
                  <div className="dropdown-divider"></div>
                  <div className=" ">
                    <Link
                      className={classNames(styles.dropdown_link, "link_css")}
                      onClick={handleDomainChange.bind(this, risk)}
                    >
                      <div className="dropdown-item  ">
                        <span className="text-muted text-sm ">
                          <img
                            className={styles.switch_dots}
                            src={
                              // process.env.PUBLIC_URL +
                              "/Images/Navbarimg/Risk.png"
                            }
                            alt="icon"
                          />
                        </span>
                        Riskdeployer(Coming Soon)
                      </div>
                    </Link>
                  </div>

                  <div className="dropdown-divider"></div>
                  <div className=" ">
                    <Link
                      className={classNames(styles.dropdown_link, "link_css")}
                      onClick={handleDomainChange.bind(this, whiteboards)}
                    >
                      <div className="dropdown-item  ">
                        <span className="  text-muted text-sm ">
                          <img
                            className={styles.switch_dots}
                            src={
                              // process.env.PUBLIC_URL +
                              "/Images/Navbarimg/Whiteboard-f.png"
                            }
                            alt="icon"
                          />
                        </span>
                        Whiteboard
                      </div>
                    </Link>
                  </div>
                </ul>
              </div>
            </Nav.Item>
            <Nav.Item className={styles.nav_icons}>
              {img ? (
                <img
                  className={styles.text_circle}
                  src={img}
                  onClick={handleClick}
                  alt=""
                />
              ) : (
                <div className={styles.text_circle} onClick={handleClick}>
                  {newUser}
                </div>
              )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <div
                  className="d-flex gap-2 px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/profile")}
                >
                  <div>
                    {" "}
                    {img ? (
                      <img className={styles.text_circle} src={img} alt="" />
                    ) : (
                      <div className={styles.text_circle}>{newUser}</div>
                    )}
                  </div>

                  <h6 className="py-1"> Profile</h6>
                </div>

                <div
                  className="px-2 d-flex gap-1 "
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                >
                  {/* <ListItemIcon className="Name"> */}
                  <LogoutIcon />

                  <h6>Logout</h6>
                </div>
              </Menu>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderAll;
