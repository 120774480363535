import React from "react";
import { Button, Card, Overlay, Popover } from "react-bootstrap";
import HoverOverlay from "../HoverOverlay";
import styles from "../../css/CreateForm.module.css";
import AddCardPage from "../../pages/AddCardPage";

export function ToolsRight({
  sectionIndex,
  isConditional,
  sectionId,
  handleClickTimer,
  timeRef,
  showTimer,
  targetTimer,
  timer,
  setFormData,
  formData,
  setTimer,
  handleEditFormOnEnter,
  formId,
  setShowTimer,
  handleClickDatePicker,
  dateRef,
  showDatePicker,
  targetDatePicker,
  formDate,
  setFormDate,
  setShowDatePicker,
  handleAddField,
  handleAddNewSection,
  setChildData,
  handleUpdateVideoData,

  formTypeToggle,
  isQuiz,
}) {
  return (
    <div className={`${styles.root_col} ${styles.root_col_2}`}>
      {sectionIndex === 0 && (
        <>
          <HoverOverlay
            placement="left"
            hoverText="Auto Submit timer"
            mainInput={
              <Card
                className={`${styles.timer_btn} `}
                onClick={handleClickTimer}
                ref={timeRef}
              >
                <i
                  className={`fa-solid fa-stopwatch-20 ${styles.timer_icon}`}
                />
              </Card>
            }
          />
          <Overlay
            show={showTimer}
            target={targetTimer}
            placement="bottom"
            container={timeRef}
            containerPadding={100}
          >
            <Popover id="popover-contained">
              <Popover.Header as="h3">Time</Popover.Header>
              <Popover.Body>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="time"
                    value={timer}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        timer: e.target.value,
                      });
                      setTimer(e.target.value);
                    }}
                    style={{ margin: "0.25rem" }}
                  />
                  <Button
                    size="sm"
                    onClick={() => {
                      handleEditFormOnEnter(timer, "timer", formId);
                      setShowTimer(false);
                    }}
                  >
                    done
                  </Button>
                </div>
              </Popover.Body>
            </Popover>
          </Overlay>
        </>
      )}

      {sectionIndex === 0 && (
        <>
          <HoverOverlay
            placement="left"
            hoverText="Form Date"
            mainInput={
              <Card
                className={`${styles.timer_btn} `}
                onClick={handleClickDatePicker}
                ref={dateRef}
              >
                <i
                  className={`fa-solid fa-calendar-days ${styles.timer_icon}`}
                />
              </Card>
            }
          />
          <Overlay
            show={showDatePicker}
            target={targetDatePicker}
            placement="top"
            container={dateRef}
            containerPadding={100}
          >
            <Popover id="popover-contained">
              <Popover.Header as="h3">Calendar</Popover.Header>
              <Popover.Body>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="date"
                    value={formDate}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        date: e.target.value,
                      });
                      setFormDate(e.target.value);
                    }}
                    style={{ margin: "0.25rem" }}
                  />
                  <Button
                    size="sm"
                    onClick={() => {
                      handleEditFormOnEnter(formDate, "date-time", formId);
                      setShowDatePicker(false);
                    }}
                  >
                    done
                  </Button>
                </div>
              </Popover.Body>
            </Popover>
          </Overlay>
        </>
      )}
      <AddCardPage
        handleAddField={handleAddField}
        handleAddNewSection={handleAddNewSection}
        setChildData={setChildData}
        updateVideoData={handleUpdateVideoData}
        sectionIndex={sectionIndex}
        isConditional={isConditional}
        sectionId={sectionId}
        formId={formId}
        formType={formData.formType}
        formTypeToggle={formTypeToggle}
        isQuiz={formData?.isQuiz}
      />
    </div>
  );
}
