import Rating from "@mui/material/Rating";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fileUpl from "../../src/assets/fileUpload.png";
import CountdownTimer from "../components/CountDownTimer";
import styles from "../css/Preview.module.css";
import Loader from "../loader";

import {
  fetchFormQuestions,
  formOpened,
  handleEmailChange,
  handleInputChange,
  handleUsernameChange,
  setFormErrors,
  submitForm,
  uploadFiles,
} from "../redux/slice/previewSlice";
import NormalPreview from "./NormalPreview";
import AddressQuestion from "./QuestionTypes/AddressQuestion";
import CheckboxQuestion from "./QuestionTypes/CheckboxQuestion";
import DateQuestion from "./QuestionTypes/DateQuestion";
import DropdownQuestion from "./QuestionTypes/DropdownQuestion";
import FileUploadQuestion from "./QuestionTypes/FileUploadQuestion";
import MultipleChoiceQuestion from "./QuestionTypes/MultipleChoiceQuestion";
import ParagraphQuestion from "./QuestionTypes/ParagraphQuestion";
import PhoneQuestion from "./QuestionTypes/PhoneQuestion";
import ShortAnswerQuestion from "./QuestionTypes/ShortAnswerQuestion";
import TextQuestion from "./QuestionTypes/TextQuestion";
import TimeQuestion from "./QuestionTypes/TimeQuestion";
import SurveyPreview from "./SurveyPreview";
import classNames from "classnames";

const Preview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isSelfPreviewPage, setIsSelfPreviewPage] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [formAccessDate, setFormAccessDate] = useState(true);
  const [validEmail, setValidEmail] = useState(true);

  const {
    loading,
    fileLoading,
    formTitle,
    formId,
    formDescription,
    submitButtonText,
    formQuestions,
    submissionData,
    formErrors,
    formType,
    formData,
    timer,
    date,
    imageTheme,
    isQuiz,
    username,
  } = useSelector((data) => data.preview);
  const { sections } = formData;

  const [isTimerEnd, setIsTimerEnd] = useState(false);
  const handleTimerEnd = useCallback(() => {
    let filledAllRequired = true;

    if (!submissionData.user || !emailRegex.test(submissionData.user)) {
      submissionData?.reply?.forEach((item) => {
        const question = formQuestions.find(
          (question) => question._id === item.questionId,
        );

        if (question.required && !item.answer) {
          filledAllRequired = false;
        }
      });
    }

    if (filledAllRequired) {
      setIsTimerEnd(true);
      setIsButtonDisabled(true);
      handleSubmit();
    } else {
      navigate("/submissionFailure");
    }
  }, [formQuestions, navigate, submissionData]);

  const [imagePreview, setImagePreview] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);

  const isImageFile = useCallback((fileName) => {
    const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp"];
    const lowerCaseFileName = fileName.toLowerCase();
    return imageExtensions.some((extension) =>
      lowerCaseFileName.endsWith(extension),
    );
  }, []);

  // function to handle file upload
  const handleFileChange = useCallback(
    (e, id) => {
      const file = e.target.files[0];
      setImagePreview(null);
      setUploadedFileName(null);

      // Check if a file is selected
      if (file) {
        // Read the file and set the image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          if (isImageFile(file.name)) {
            setImagePreview(reader.result);
          } else {
            setUploadedFileName(file.name);
          }
        };
        reader.readAsDataURL(file);

        // Dispatch your file upload action
        dispatch(
          uploadFiles({
            file: file,
            id: formId,
            qid: id,
            alert,
          }),
        )
          .then((res) => {
            if (isImageFile(file.name)) {
              setImagePreview(res?.payload?.filePath);
            } else {
              setImagePreview(null);
              setUploadedFileName(res?.payload?.filePath);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setImagePreview(null);
        setUploadedFileName(null);
      }
    },
    [dispatch, alert, formId, isImageFile],
  );



  useEffect(() => {
    if (date?.date) {
      const formDate = new Date(date.date);
      const todaysDate = new Date();

      // Set time components to midnight
      formDate.setHours(0, 0, 0, 0);
      todaysDate.setHours(0, 0, 0, 0);
      const isOnOrAfterDeadline = formDate >= todaysDate;

      setFormAccessDate(isOnOrAfterDeadline);
    }

    const pathParts = window.location.pathname.split("/");
    const id = pathParts[pathParts?.length - 1];
    setIsSelfPreviewPage(pathParts.includes("selfPreview"));
    if (!pathParts.includes("selfPreview")) {
      dispatch(formOpened(id));
    }

    dispatch(fetchFormQuestions(id));
  }, [date?.date, dispatch]);

  const emailValidityTest = useCallback((email) => {
    return emailRegex.test(email);
  }, []);

  const handleSubmit = useCallback(async () => {
    const errors = [];
    const isEmailValid = submissionData?.user?.length === 0;

    if (isEmailValid) {
      errors.push("E-mail must be valid to get responses.");
    }

    if (submissionData.user?.length !== 0) {
      if (!submissionData.user || !emailRegex.test(submissionData.user)) {
        setValidEmail(false);
        errors.push("E-mail must be valid to get responses.");
      }
    } else if (isQuiz) {
      setValidEmail(false);
      errors.push("Email is required!!");
    }

    submissionData?.reply?.forEach((item) => {
      const question = formQuestions.find(
        (question) => question._id === item.questionId,
      );

      if (question.required && !item.answer) {
        errors.push(`Please fill the required field: ${question.question}`);
      }
    });

    if (errors?.length > 0) {
      dispatch(setFormErrors(errors));
      return;
    }

    try {
      const lowercaseEmail = submissionData.user.toLowerCase();
      const payload = {
        formId: submissionData.formId,
        user: lowercaseEmail,
        username: submissionData.username,
        reply: submissionData.reply,
      };
      setIsButtonDisabled(true);
      // Using await to handle the asynchronous dispatch
      const response = await dispatch(submitForm(payload));

      // Check if the response type is "preview/submitForm/fulfilled"
      if (response.type === "preview/submitForm/fulfilled") {
        // alert.success(response.data.message);
        setIsSubmitting(false);
        navigate("/responsesRecorded", {
          state: {
            result: response?.payload?.result,
            formId,
            submissonId: response?.payload?.submissionId,
          },
        });

        dispatch(setFormErrors([]));

        const formElements = document.querySelectorAll(
          "form input:not([type=radio]), form select, form textarea",
        );
        formElements.forEach((element) => {
          if (element.type !== "submit") {
            element.value = "";
          }
        });

        const radioButtons = document.querySelectorAll(
          "form input[type=radio]",
        );
        radioButtons.forEach((radioButton) => {
          radioButton.checked = false;
        });
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting form", error);
    }
  }, [formQuestions, submissionData, dispatch, formId, isQuiz, navigate]);

  const renderQuestion = useCallback(
    (question, fileLoading, index, ind) => {
      const {
        _id,
        type,
        question: questionText,
        options,
        required,
        qImgUrl,
        points,
      } = question;
      let value = "";
      let checked = "";
      submissionData.reply.map((rep) => {
        if (rep.questionId === _id && rep.type === type) {
          if (type === "multiple_choice" || type === "checkbox") {
            checked = rep.answer;
          }
          value = rep.answer;
        }
      });

      switch (type) {
        case "text":
          return (
            <TextQuestion
              id={_id}
              required={required}
              questionText={questionText}
              isSelfPreviewPage={isSelfPreviewPage}
              type={type}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
              ind={ind}
              qImgUrl={qImgUrl}
              value={value}
            />
          );

        case "short_answer":
          return (
            <ShortAnswerQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              type={type}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
              ind={ind}
              value={value}
            />
          );

        case "paragraph":
          return (
            <ParagraphQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              type={type}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
              ind={ind}
              value={value}
            />
          );
        // changes here
        case "rating":
          return (
            <Card className={`${styles.question_card} `}>
              <div className={styles.ratingCard}>
                <div className="d-flex">
                  <p className="d-flex me-1">
                    {required && <span className={styles.required}>*</span>}{" "}
                    {questionText?.length !== 0 && `${ind + 1}. `}
                  </p>
                  <div>{questionText}</div>
                </div>
                {qImgUrl && qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                <Rating
                  name="size-large"
                  defaultValue={0}
                  precision={0.5}
                  className="ms-3"
                  style={{ width: "fit-content" }}
                  value={value}
                  size="large"
                  onChange={(event, newValue) =>
                    dispatch(
                      handleInputChange({
                        questionId: _id,
                        value: newValue,
                      }),
                    )
                  }
                />
                {formErrors.includes(
                  `Please fill the required field: ${questionText}`,
                ) ? (
                  <p
                    className={styles.invalidText}
                  >{`Please fill the required field: ${questionText}`}</p>
                ) : null}
              </div>
            </Card>
          );

        case "date":
          return (
            <DateQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              type={type}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
              ind={ind}
              value={value}
            />
          );

        case "address":
          return (
            <AddressQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              type={type}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
              ind={ind}
              value={value}
            />
          );
        case "phone":
          return (
            <PhoneQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              type={type}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
              ind={ind}
              value={value}
            />
          );

        case "time":
          return (
            <TimeQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              type={type}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
              ind={ind}
              value={value}
            />
          );

        case "checkbox":
          return (
            <CheckboxQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              options={options}
              formErrors={formErrors}
              ind={ind}
              submissionData={submissionData}
            />
          );

        case "multiple_choice":
          return (
            <MultipleChoiceQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              isQuiz={isQuiz}
              points={points}
              options={options}
              checked={checked}
              formErrors={formErrors}
              ind={ind}
            />
          );

        case "file_upload":
          return (
            <FileUploadQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              handleFileChange={handleFileChange}
              formErrors={formErrors}
              ind={ind}
              fileLoading={fileLoading}
              imagePreview={imagePreview}
              uploadedFileName={uploadedFileName}
              fileUpl={fileUpl}
            />
          );

        case "dropdown":
          return (
            <DropdownQuestion
              _id={_id}
              questionText={questionText}
              qImgUrl={qImgUrl}
              required={required}
              isSelfPreviewPage={isSelfPreviewPage}
              options={options}
              handleInputChange={handleInputChange}
              formErrors={formErrors}
              ind={ind}
              value={value}
            />
          );

        default:
          return null;
      }
    },
    [
      isQuiz,

      dispatch,
      formErrors,
      submissionData,

      isSelfPreviewPage,

      handleFileChange,
      imagePreview,
      uploadedFileName,
    ],
  );

  // =====================================================================

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [validName, setValidName] = useState(false);

  const handleNextQuestion = useCallback(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors = [];

    if (!submissionData.user || !emailRegex.test(submissionData.user)) {
      errors.push("E-mail must be valid to get responses.");
    }

    if (isQuiz) {
      if (submissionData?.username?.length === 0) {
        setValidName(true);
        errors.push("Enter your name.");
      }
    }

    const checkAnswer = (data) => {
      return submissionData?.reply.some((ele) => {
        if (ele.questionId === data._id) {
          return ele?.answer === "";
        }
        return false;
      });
    };

    const currentSection = sections[currentSectionIndex];
    const questionId = currentSection.questions[currentQuestionIndex];

    if (questionId.required) {
      if (checkAnswer(questionId)) {
        errors.push(`Please fill the required field: ${questionId.question}`);
      }
    }

    if (errors?.length > 0) {
      dispatch(setFormErrors(errors));
      return;
    }

    if (currentQuestionIndex < currentSection.questions?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentSectionIndex < sections.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
      setCurrentQuestionIndex(0);
    }
  }, [
    currentQuestionIndex,
    currentSectionIndex,
    dispatch,
    isQuiz,
    sections,
    submissionData,
  ]);

  const handlePreviousQuestion = useCallback(() => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
      setCurrentQuestionIndex(
        sections[currentSectionIndex - 1]?.questions?.length - 1,
      );
    }
  }, [currentQuestionIndex, sections, currentSectionIndex]);
  const handleNextSection = useCallback(
    (toSection = 0) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const errors = [];
      if (!submissionData.user || !emailRegex.test(submissionData.user)) {
        errors.push("E-mail must be valid to get responses.");
      }

      if (isQuiz) {
        if (submissionData?.username?.length === 0) {
          setValidName(true);
          errors.push("Enter your name.");
        }
      }

      const checkAnswer = (id) => {
        return submissionData?.reply.some((ele) => {
          if (ele.questionId === id) {
            return ele?.answer === "";
          }
          return false;
        });
      };

      const isEmailValid =
        submissionData?.user?.length === 0 ||
        emailRegex.test(submissionData.user);
      const questionInSection = sections[currentSectionIndex].questions;
      const err = [];

      questionInSection.map((ele, index) => {
        if (ele.required) {
          if (checkAnswer(ele._id) === true) {
            err.push(`Please fill the required field: ${ele.question}`);
          }
        }
      });

      if (errors.length > 0) {
        dispatch(setFormErrors(errors));
        return;
      }

      if (isEmailValid) {
        if (toSection > 0) {
          setCurrentSectionIndex(toSection);
        } else if (currentSectionIndex < sections?.length - 1) {
          setCurrentSectionIndex(currentSectionIndex + 1);
        }
      } else {
        setValidEmail(false);
      }
    },
    [currentSectionIndex, sections, dispatch, isQuiz, submissionData, alert],
  );

  const handlePrevSection = useCallback((previousIndex) => {
    console.log("previousIndex",previousIndex)
    if (previousIndex >=0) {
      setCurrentSectionIndex(previousIndex);
    }
  },[]);

  const renderCurrentQuestion = useCallback(() => {
    if (sections) {
      const currentSection = sections[currentSectionIndex];
      const currentQuestion = currentSection.questions[currentQuestionIndex];

      return currentQuestion
        ? renderQuestion(
            currentQuestion,
            fileLoading,
            currentSectionIndex,
            currentQuestionIndex,
          )
        : null;
    }
  }, [
    sections,
    currentSectionIndex,
    currentQuestionIndex,
    renderQuestion,
    fileLoading,
  ]);
  let nextButtonText;
  if (sections) {
    const isLastQuestionInSection =
      currentQuestionIndex ===
      sections[currentSectionIndex]?.questions?.length - 1;
    const isLastSection = currentSectionIndex === sections?.length - 1;

    nextButtonText =
      isLastSection && isLastQuestionInSection ? submitButtonText : "Next";
  }

  return (
    <>
      {formAccessDate === false ? (
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "800px",
            margin: "auto",
          }}
        >
          {
            "Oops! It seems like this form has expired. The form is no longer accepting submissions."
          }
        </h2>
      ) : (
        <div className={styles.preview_main}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Form Answer</title>
            <link rel="canonical" href="http://mysite.com/example" />
          </Helmet>
          <div className={`${styles.preview_container} w-full`}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Card className={`${styles.preview_header} mb-3`}>
                  <Card.Body>
                    {imageTheme && imageTheme !== undefined ? (
                      <div className={classNames("Nothing", styles.formImage)}>
                        <img
                          className={styles.customize_theme}
                          src={imageTheme}
                          alt="Theme"
                        />
                      </div>
                    ) : null}
                    <Card.Title className={`${styles.preview_title}`}>
                      <h2 className="m-0">
                        {formTitle ? formTitle : sections?.[0].title}
                      </h2>
                    </Card.Title>
                    <Card.Text className={`${styles.preview_desc}`}>
                      {sections?.[0].description}
                    </Card.Text>
                  </Card.Body>
                  {/* <Card.Footer className={styles.indicates}>
                    * Indicates required question
                  </Card.Footer> */}
                </Card>
                <Form className={styles.formSection}>
                  <p className={styles.requiredPoint}>
                    (* mentioned are required questions)
                  </p>
                  {!validEmail ||
                  (currentSectionIndex === 0 && currentQuestionIndex === 0) ? (
                    <Card className={styles.question_card}>
                      <Form.Group className={`${styles.headerCards} mb-3 my-3`}>
                        {isQuiz && <span className={styles.required}>*</span>}
                        <Form.Label className="">
                          Email
                          {!validEmail && (
                            <div className="ms-2 text-small">
                              Please fill the correct email to get your
                              responses.
                            </div>
                          )}
                        </Form.Label>
                        <Form.Control
                          disabled={isSelfPreviewPage}
                          className={`${styles.text_input} ${styles.short_text_input}`}
                          type="email"
                          placeholder="Your email"
                          onBlur={(e) =>
                            setValidEmail(emailValidityTest(e.target.value))
                          }
                          onChange={(e) => {
                            const lowercaseValue = e.target.value.toLowerCase();
                            dispatch(handleEmailChange(lowercaseValue));
                          }}
                          required={true}
                          value={submissionData?.user}
                          isInvalid={formErrors.includes(
                            "E-mail must be valid to get responses.",
                          )}
                        />
                        <Form.Control.Feedback type="invalid" className="ms-3">
                          E-mail must be valid to get responses.
                        </Form.Control.Feedback>
                      </Form.Group>
                      {isQuiz && (
                        <Form.Group className={styles.headerCards}>
                          <span className={styles.required}>*</span>
                          <Form.Label className="mb-3">Name</Form.Label>
                          <Form.Control
                            disabled={isSelfPreviewPage}
                            className={`${styles.text_input} ${styles.short_text_input}`}
                            type="text"
                            placeholder="Your name"
                            onBlur={(e) => {
                              dispatch(handleUsernameChange(e.target.value));
                            }}
                            onChange={(e) => {
                              const newName = e.target.value;
                              dispatch(handleUsernameChange(newName));
                              setValidName(newName?.length === 0);
                            }}
                            required={true}
                            value={submissionData?.username}
                            isInvalid={formErrors.includes("Enter your name.")}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="ms-3"
                          >
                            Enter Your name
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    </Card>
                  ) : null}

                  {formType && formType === "normal" ? (
                    <NormalPreview
                      sections={sections}
                      currentSectionIndex={currentSectionIndex}
                      renderQuestion={renderQuestion}
                      fileLoading={fileLoading}
                      handlePrevSection={handlePrevSection}
                      isButtonDisabled={isButtonDisabled}
                      isSelfPreviewPage={isSelfPreviewPage}
                      handleNextSection={handleNextSection}
                      handleSubmit={handleSubmit}
                      submitButtonText={submitButtonText}
                    />
                  ) : (
                    <SurveyPreview
                      sections={sections}
                      currentSectionIndex={currentSectionIndex}
                      renderCurrentQuestion={renderCurrentQuestion}
                      currentQuestionIndex={currentQuestionIndex}
                      handlePreviousQuestion={handlePreviousQuestion}
                      isButtonDisabled={isButtonDisabled}
                      isSelfPreviewPage={isSelfPreviewPage}
                      nextButtonText={nextButtonText}
                      handleSubmit={handleSubmit}
                      handleNextQuestion={handleNextQuestion}
                    />
                  )}

                  {formType === "normal" && sections?.length < 2 && (
                    <div>
                      {isSubmitting ? (
                        <Button
                          className="disabled"
                          disabled={isButtonDisabled || isSelfPreviewPage}
                          onClick={handleSubmit}
                        >
                          <Spinner
                            animation="border"
                            className="me-1"
                            size="sm"
                          />{" "}
                          {submitButtonText}
                        </Button>
                      ) : (
                        <Button
                          disabled={isButtonDisabled || isSelfPreviewPage}
                          id="nextPreviousButton"
                          className={`${styles.buttonNextPrev} m-auto`}
                          onClick={handleSubmit}
                        >
                          {submitButtonText}
                        </Button>
                      )}
                    </div>
                  )}
                  {timer && timer !== "00:00" && (
                    <div className={styles.timer_div}>
                      {!isTimerEnd && timer && (
                        <CountdownTimer
                          initialTime={timer}
                          onTimerEnd={handleTimerEnd}
                          formId={formId}
                        />
                      )}
                      {isTimerEnd && "Timer Ended"}
                    </div>
                  )}
                </Form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Preview;
