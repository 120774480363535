import React from "react";
import BarGraph from "./BarGraph";
import PieChart from "./PieChart";
import HorizontalBarChart from "./HorizontalBarChart";
import "../../css/Graph.css";
import DoughNut from "./DoughNut";

const Graphs = ({ questions, submissions, formId }) => {
  return (
    <>
      <h1 className="mt-4">Graphs</h1>
      <div className="graph_container">
        {questions.map((question) => {
          switch (question.type) {
            case "text":
            case "short_answer":
              return (
                <BarGraph
                  questionId={question._id}
                  questions={questions}
                  submissions={submissions}
                  formId={formId}
                />
              );
            case "multiple_choice":
              return (
                <PieChart
                  questionId={question._id}
                  questions={questions}
                  submissions={submissions}
                  formId={formId}
                />
              );

            case "dropdown":
              return (
                <DoughNut
                  questionId={question._id}
                  questions={questions}
                  submissions={submissions}
                  formId={formId}
                />
              );
            case "checkbox":
              return (
                <HorizontalBarChart
                  questionId={question._id}
                  questions={questions}
                  submissions={submissions}
                  formId={formId}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </>
  );
};

export default Graphs;