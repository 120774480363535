import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Loader from "../src/loader";
// import LoginForm from "./login/login";
import "./App.css";
import EditForm from "./pages/EditForm";
import FormsTable from "./pages/FormsTable";
import Home from "./pages/Home";
import Preview from "./pages/Preview";
import ResponseTable from "./pages/ResponseTable";
import ThankYou from "./pages/ThankYou";
import UserResponse from "./pages/UserResponse";
import CreateForms from "./pages/CreateForm";
import { cookieToken } from "./service/url";

function App() {
  const location = useLocation();
  const Url = location.pathname.split("/");

  let Urls = ["previewform", "responsesRecorded", "responses"];
  const isSendFormURL = Urls.includes(Url[1]);

  if (!isSendFormURL) {
    // Check for the token and handle redirection and localStorage setup
    if (!cookieToken) {
      window.location.href =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000"
          : "https://accounts.appsdeployer.com";
    }
  }

  return (
    <>
      {!cookieToken ? (
        <Routes>
           {/* <Route path="/" element={<LoginForm />} /> */}
          <Route path="/" element={<Loader />} />
          <Route path="/previewform/:formid" element={<Preview />} />
          <Route path="/responsesRecorded" element={<ThankYou />} />
          <Route
            path="/responses/:formId/userResponse/:submissionId"
            element={<UserResponse />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/forms" element={<FormsTable />} />
          <Route path="/createForm" element={<CreateForms />} />
          <Route path="/createForm/:workspaceId" element={<CreateForms />} />
          <Route path="/selfPreview/:formId" element={<Preview />} />
          <Route path="/editForm/:formId" element={<EditForm />} />
          <Route path="/previewform/:formid" element={<Preview />} />
          <Route path="/responses/:formId" element={<ResponseTable />} />

          <Route exact path="/responsesRecorded" element={<ThankYou />} />

          <Route
            path="/responses/:formId/userResponse/:submissionId"
            element={<UserResponse />}
          />
        </Routes>
      )}
    </>
  );
}

export default App;
