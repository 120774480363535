import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { driveconfig } from "../services/tokenstore";
// import { SOCKET_URL } from "../services/url";
import { addWorkspace, getWorkspace } from "../../redux/action/workspace";
import "./addWorkspacePopup.css";

const AddWorkspacePopup = (props) => {
  const setCreateWorkspacePopup = props.setCreateWorkspacePopup;
  const setNewWorkspaceCreated = props.setNewWorkspaceCreated;

  const alert = useAlert();
  const ref = useRef();
  const location = useLocation();
  const [updateIdea, setUpdateIdea] = useState(false);
  const [ideaId, setIdeaId] = useState(null);
  const [workspaceIdForIdea, setWorkspaceIdForIdea] = useState(null);
  const navigate = useNavigate();
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceColor, setWorkspaceColor] = useState("");
  const [workspaceType, setWorkspaceType] = useState("");
  const [workspaceDesc, setWorkspaceDesc] = useState("");
  const [alertShown, setAlertShown] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    props.setCreateWorkspacePopup(false);
    setWorkspaceName("");
    setButtonClicked(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);

    let inputValue = {
      workspaceName,
      workspaceColor,
      workspaceDesc,
      workspaceType,
    };
    dispatch(addWorkspace(inputValue))
      .then(() => {
        alert.success("Workspace Created!");
      })
      .catch((err) => {
        alert.error(err.message);
      });
    props.setCreateWorkspacePopup(false);
    handleClose();
    setWorkspaceDesc("");
    setButtonClicked(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.setCreateWorkspacePopup(false);
        setButtonClicked(false);
        setWorkspaceDesc("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      setButtonClicked(false);
      setWorkspaceDesc("");
    };
  }, [ref]);

  return (
    <Modal
      centered
      show={props.createWorkspacePopup}
      size="m"
      onHide={handleClose}
      className="workspace-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="mainheading_div">
            <h4 className="fw-bold">Add Workspace</h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <h6>
            Workspace Name{" "}
            <span style={{ color: "red", position: "relative", left: "-10px" }}>
              {" "}
              *
            </span>
          </h6>
          <input
            type="text"
            className="border-0 border-bottom bg-light workspacename_input"
            placeholder="enter workspace name"
            value={workspaceName}
            onChange={(e) => {
              setWorkspaceName(e.target.value);
            }}
          />
          <h6>
            Description <span>Optional</span>
          </h6>
          <textarea
            type="text"
            rows={2}
            name="textarea"
            className="border-0 border-bottom bg-light workspace_desc"
            placeholder="Our team organizes everything here."
            value={workspaceDesc}
            onChange={(e) => setWorkspaceDesc(e.target.value)}
          />
          <div style={{ textAlign: "center" }}>
            <Button
              className=" mb-4 continue_btn"
              disabled={!workspaceName.length || buttonClicked}
              type="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </Modal.Body>
      {/* <WorkspaceCreatedPopup /> */}
    </Modal>
  );
};
AddWorkspacePopup.propTypes = {
  setCreateWorkspacePopup: PropTypes.node.isRequired,
};

export default AddWorkspacePopup;
