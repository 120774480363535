import React, { useEffect } from "react";
import InviteSpaceInput from "./InviteSpaceInput";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import PropTypes from "prop-types";
import "./InviteWorkspace.css";

const InviteWorkspace = ({
  workspaceId,
  workspaceName,
  setSharePopup,
  setIsOpen,
  formId,
  isForm,
}) => {
  const [isShareClicked, setIsShareClicked] = useState(false);
  const location = useLocation();
  const isWorkspace = (location.pathname.includes("workspace"));
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <span
        style={{ textAlign: "start" }}
        onClick={() => {
          setIsShareClicked(true)
          setIsOpen(false)
        }}
      >
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          height="1em"
          width="1em"
          style={{ marginRight: ".5rem" }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M10 3v2H5v14h14v-5h2v6a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1h6zm7.707 4.707L12 13.414 10.586 12l5.707-5.707L13 3h8v8l-3.293-3.293z" />
        </svg>
        Share
      </span>
      {isShareClicked && (
        <div
          className="position-fixed top-50 start-50 translate-middle p-4 bg-white rounded shadow popup_main_container"
          style={{ zIndex: 1000 }}
        >
          <div className="d-flex align-items-center justify-content-between gap-5">
            <h1 className="popup_heading">
              { isWorkspace ? "Share form" : "Share workspace" }
            </h1>
            <h3 onClick={() => setIsShareClicked(false)}>
              <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                  clipRule="evenodd"
                />
              </svg>
            </h3>
          </div>
          <div className="popup_divider"></div>
          <InviteSpaceInput
            workspaceId={workspaceId}
            workspaceName={workspaceName}
            setSharePopup={setSharePopup}
            setIsOpen={setIsOpen}
            isForm={isForm}
            formId={formId}
          />
        </div>
      )}
    </div>
  );
};

InviteWorkspace.propTypes = {
  workspaceId: PropTypes.node.isRequired,
  workspaceName: PropTypes.node.isRequired,
  setSharePopup: PropTypes.node.isRequired,
};
export default InviteWorkspace;
