import React from "react";
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJs.register(Tooltip, Title, ArcElement, Legend);

const DoughNut = ({ questions, submissions, questionId,  }) => {
  const question = questions.find((q) => q._id === questionId);
  const optionsArray = question.options.map((option) => option.option);
  const optionCounts = {};

  submissions.forEach((item) => {
    const reply = item.reply.find((r) => r.questionId === questionId);
    if (reply && reply.answer) {
      let selectedOptions = [];
      if (typeof reply.answer === "string") {
        selectedOptions = [reply.answer];
      } else if (reply.answer.option) {
        selectedOptions = [reply.answer.option];
      }

      selectedOptions.forEach((selectedOption) => {
        if (optionCounts[selectedOption]) {
          optionCounts[selectedOption]++;
        } else {
          optionCounts[selectedOption] = 1;
        }
      });
    }
  });

  const colors = [
    "rgb(255, 0, 0)",
    "rgb(255, 255, 0)",
    "rgb(0, 128, 0)",
    "rgb(255, 165, 0)",
    "rgb(128, 0, 128)",
    "rgb(0, 0, 255)",
    "rgb(255, 0, 255)",
    "rgb(103, 58, 183)",
    "rgb(0, 255, 0)",
    "rgb(0, 255, 255)",
  ];

  const data = {
    labels: optionsArray,
    datasets: [
      {
        label: "User Responses",
        data: optionsArray.map((option) => optionCounts[option] || 0),
        backgroundColor: optionsArray.map(
          (_, index) => colors[index % colors.length]
        ),
        borderColor: "black",
        borderWidth: 0.5,
        tension: 0.1,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false	// Don't maintain w/h ratio
  }

  return (
    <div className="graphs">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughNut;
