import React, { useCallback, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader";
import { getTemplateCategories } from "../../redux/slice/templateSlice";
import styles from "./template.module.css";

function Template() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTemplateCategories());
  }, [dispatch]);

  const templateCategories = useSelector(
    (state) => state.template.templateCategories,
  );
  const loading = useSelector((state) => state.template.loading);

  const handleSelectCategory = useCallback(
    (category) => {
      navigate(`/templateCategory/${category._id}`);
    },
    [navigate],
  );

  return (
    <div className={styles.parent}>
      <div className="headingandbutton_div">
        <h5 className="workspace_heading">Featured Templates</h5>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="feed">
          <div className="feed__inputContainer">
            <div className="tab-content recentWorkspace recent active">
              <Table hover>
                <tbody className="table_body">
                  <tr className="w-100" style={{ height: "7px" }} />
                  {templateCategories?.map((category) => (
                    <tr key={category._id} className="table_row">
                      <td onClick={() => handleSelectCategory(category)}>
                        {category?.category}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Template;
