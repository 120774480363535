import classNames from "classnames/bind";
import React from "react";
import _debounce from "lodash/debounce";
import { Card, Form } from "react-bootstrap";
import styles from "../css/CreateForm.module.css";

const ShowConditionQuestion = ({ section,handleSkipToSection }) => {
  

  return (
    <div className={styles.questionpage_container}>
      <Card className={classNames(styles.questionpage_card, "shadow")}>
        <div className={styles.inputandSelect_container}>
          <div className={styles.inputandImg_container}>
            <div className={styles.question_input}> {section.condition?.question}</div>
          </div>
        </div>

        {/*------------------------------------------- MultipleChoice ----------------------------*/}
        {section.condition.options.map((option, index) => (
          <Form.Group key={option?._id}>
            <div key={option._id} className="mb-3 ms-3">
              <Form.Check
                type="radio"
                id={index}
                name="optionsGroup"
                label={option.option}
                onClick={() => handleSkipToSection(option.redirectIndex)}
              />
            </div>
          </Form.Group>
        ))}
      </Card>
    </div>
  );
};

export default ShowConditionQuestion;
