import React, { useState, useRef,useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useAlert } from "react-alert";
import { BsLink } from "react-icons/bs";
import { sendFormLink } from "../redux/slice/formSlice";
import { useDispatch, } from "react-redux";
import styles from "../css/SendPopup.module.css"

export const Sendpopup = ({ showform }) => {
  const dispatch=useDispatch()
  const [showSendForm, setShowSendForm] = useState(true);
  const [activeSection, setActiveSection] = useState("");
  useEffect(()=>{
    setActiveSection("email")
  },[])
  const [email, setEmail] = useState();
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const alert = useAlert();


  const handleSendForm = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (!email) {
      alert.error("Email address missing");
      return;
    }
    
    if (!emailRegex.test(email)) {
      alert.error("Invalid email address");
      return;
    }

    const payload = {
      sendTo: email,
      subject: subject,
      message: message,
      link: NewURL,
    };

    try {
      const response = await dispatch(sendFormLink(payload));
     if (sendFormLink.fulfilled.match(response)) {
       
        alert.success("Form link sent successfully");
        setEmail("");
        setSubject("");
        setMessage("");
        setShowSendForm(false);
      } else {
        alert.error("Failed to send form link");
      }
    } catch (error) {
      console.error("Error sending form link:", error);
      alert.error("An error occurred while sending form link");
    }
  };

  const closeSendForm = () => {
    setShowSendForm(false);
    showform();
  };
  const inputRef = useRef(null);
  const currentURL = window.location.href;
  const id = currentURL.split("/").pop();
  const NewURL = `https://forms.appsdeployer.com/previewform/${id}`;
  // const NewURL = `http://localhost:3000/previewform/${id}`;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(NewURL)
      .then(() => {
        alert.success("Copied to the clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  return (
    <Modal show={showSendForm} onHide={closeSendForm} centered>
      <Modal.Header closeButton>
        <Modal.Title>Send Form</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          

          {activeSection === "email" && (
            <>
              <Form.Group controlId="to">
                <Form.Label>To:</Form.Label>
                <Form.Control
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="subject">
                <Form.Label>Subject:</Form.Label>
                <Form.Control
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Label>Message:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={message}
                  rows={3}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
            </>
          )}

          {activeSection === "link" && (
            <Form.Group controlId="link">
              <Form.Label>Link:</Form.Label>
              <Form.Control
                type="text"
                ref={inputRef}
                value={NewURL}
                readOnly
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.select();
                  }
                }}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <div
          className="d-flex flex-wrap gap-2"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <Button variant="secondary" className={styles.btn} onClick={handleCopy}>
            <span className="px-2">Copy link</span>
            <BsLink className=" " size={20} />
          </Button>
          <div className="d-flex gap-2">
            <Button variant="secondary" className={styles.btn} onClick={closeSendForm}>
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={handleSendForm} className={styles.btn}
            >
              {activeSection === "email" ? "Send" : "Copy Link"}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
