import React from "react";
import styles from "../components/Recent/Recent.module.css";
import RecentAllForms from "../components/Recent/RecentAllForms";
import AllTrashForms from "../components/trash/AllTrashForms";
import Workspace from "../components/Workspace/Workspace";


const Trash = () => {
  return (
    <>
      <div className={styles.userboard}>
        {/* <div className="headingandbutton_div">
          <h5 className="workspace_heading">Trash</h5>
        </div> */}
        <AllTrashForms />
      </div>
    </>
  );
};

export default Trash;
