import React from "react";
import AllForms from "./AllForms";

const Home = () => {
  return (
    <>
      <AllForms />
    </>
  );
};

export default Home;
