import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AuthRoute from "../Routes/AuthRoutes.js";
import HeaderAll from "../components/Headers/HeaderAll";
import { pathName } from "../components/constant/path.js";
import Sidebar from "../components/sidebar/sidebar.jsx";
import "../css/AllForms.css";
import { fetchFormsData, setPageNumber } from "../redux/slice/formSlice.js";

const AllForms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formsData = useSelector((state) => state.forms.formsData);
  const loading = useSelector((state) => state.forms.loading);
  const pageNumber = useSelector((state) => state.forms.pageNumber);
  const pageCount = useSelector((state) => state.forms.pageCount);
  const pageSize = useSelector((state) => state.forms.pageSize);
  useEffect(() => {
    dispatch(fetchFormsData({ page_size: pageSize, page_number: pageNumber }));
  }, [dispatch, pageSize, pageNumber]);

  const handlePageChange = useCallback(
    ({ selected }) => {
      dispatch(setPageNumber(selected + 1));
    },
    [dispatch],
  );
  const [search, setSearch] = useState();
  const filteredForms = search
    ? formsData.filter((form) =>
        form.sections[0].title.toLowerCase().includes(search.toLowerCase()),
      )
    : formsData;


  const locationObj = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  let locationPath = locationObj.pathname.split("/");
  const sidebarAction = localStorage.getItem("sidebaropen");
  let ispath = pathName.includes(`/${locationPath[1]}`);

  return (
    <>
      <div>
        <HeaderAll setSearch={setSearch} search={search} showBreak={true} />
        <div
          className={`app ${!isSidebarOpen ? "sidebar-close" : "sidebar-open"}`}
          style={{
            backgroundColor: ispath ? "white" : null,
          }}
        >
          <Sidebar
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
          />
          <main
            className={
              window.innerWidth <= 500 && sidebarAction === "true"
                ? "maincontent2-"
                : "main-content"
            }
          >
            <AuthRoute />
          </main>
        </div>
      </div>
    </>
  );
};

export default AllForms;
