import { createSlice } from "@reduxjs/toolkit";
// import { createAsyncThunk } from '@reduxjs/toolkit';
const userSlice = createSlice({
  name: "user",
  initialState: {
    user: [],
    loader: false,
  },
  reducers: {
    userLogout: (state) => {
      state.user = null;
      state.loader = false;
    },
    userLogin: (state, action) => {
      state.user.push(action.payload);
      state.loader = false;
    },
  },
});
export const { logoutUser, userLogin } = userSlice.actions;
export default userSlice.reducer;
