import React, { useCallback, useEffect } from "react";
import { Container, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserResponse } from "../redux/slice/responseSlice";
import styles from "../css/UserResponse.module.css";
import HeaderAll from "../components/Headers/HeaderAll";
import Loader from "../loader";
import classNames from "classnames/bind";
import cns from "classnames";

const UserResponse = () => {
  const dispatch = useDispatch();
  const userSubmission = useSelector((state) => state.responses.userSubmission);
  const isQuiz = userSubmission?.formId?.isQuiz;
  const questions = useSelector((state) => state.responses.questions);
  const loading = useSelector((state) => state.responses.loading);
  const formTitle = useSelector((state) => state.responses.formTitle);

  const pathParts = window.location.pathname.split("/");
  // const formIddd = pathParts[pathParts.length - 3];
  const submissionId = pathParts[pathParts.length - 1];

  useEffect(() => {
    dispatch(fetchUserResponse(submissionId));
  }, [dispatch, submissionId]);

  const getAnswerForQuestion = useCallback(
    (questionId) => {
      const answer = userSubmission?.reply?.find(
        (reply) => reply?.questionId === questionId,
      );
      if (answer) {
        switch (answer?.type) {
          case "checkbox":
            
            // return answer?.answer?.map((option) => option?.option);
            if (Array.isArray(answer?.answer)) {
              return answer?.answer?.map((option) => option?.option);
            }
            return ""
          case "multiple_choice":
            return answer?.answer?.optionId;
          default:
            return answer?.answer;
        }
      }
      return "";
    },
    [userSubmission.reply],
  );

  const getAnswerForQuestionIndex = useCallback(
    (questionId) => {
      const answer = userSubmission.reply.find(
        (reply) => reply?.questionId === questionId,
      );
      if (answer) {
        if (answer?.type === "checkbox") {
          return answer?.answer.map((option) => option.option);
        } else if (answer?.type === "multiple_choice") {
          return answer?.answer?.optionIndex;
        } else {
          return answer?.answer;
        }
      }
      return "";
    },
    [userSubmission.reply],
  );

  const findAnswerforOtherOption = (optionId) => {
    return userSubmission.reply.map((option) => {
      if (optionId === option?.answer?.optionId) {
        return option?.answer?.option;
      }
    });
  };

  const getOptionsForQuestion = (question) => {
    return question.options || [];
  };

  const isInputFieldDisabled = (questionType) => {
    return (
      questionType === "text" ||
      questionType === "short_answer" ||
      questionType === "paragraph" ||
      questionType === "date" ||
      questionType === "time" ||
      questionType === "file_upload" ||
      questionType === "phone" ||
      questionType === "address" ||
      questionType === "rating" ||
      questionType === "dropdown"
    );
  };

  return (
    <>
      {/* <HeaderAll /> */}
      <Container className={cns("mb-4", styles.container_width)}>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.response_container}>
            <div className="titlepage_container">
              <Card
                className={classNames(styles.titlepage_card_response, "shadow")}
              >
                <span className={styles.titlepagecard_span_response}></span>

                <div className={cns(styles.card_design)}>
                  <span>Responses can not be edited</span>
                  <h1 className="pt-2">{formTitle}</h1>
                  <p className="">User Email: {userSubmission.user}</p>
                </div>
              </Card>
            </div>
            <div className={styles.questionpage_container_response}>
              {questions.map((question, index) => (
                <Card
                  key={question.id}
                  className={classNames(
                    styles.questionpage_card_response,
                    "shadow",
                  )}
                >
                  <div className={cns(styles.card_design)}>
                    <p className={styles.question_input_response}>
                      {question.question}
                    </p>
                    {question.qImgUrl && (
                      <div className={styles.qImgUrl_div}>
                        <img
                          src={question.qImgUrl}
                          alt=""
                          className={styles.qImgUrl}
                        />
                      </div>
                    )}

                    {isInputFieldDisabled(question.type) ? (
                      <div>
                        {question.type === "file_upload" ? (
                          <>
                            <a
                              href={getAnswerForQuestion(question._id)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View File
                            </a>
                          </>
                        ) : (
                          <p className={styles.text_message}>
                            {getAnswerForQuestion(question._id)}
                          </p>
                        )}
                      </div>
                    ) : null}

                    {question.type === "checkbox" && (
                      <>
                        {getOptionsForQuestion(question).map(
                          (option, index) => (
                            <div
                              key={index}
                              className={
                                question?.answer === index.toString()
                                  ? styles.correct_option
                                  : styles.option
                              }
                            >
                              <Form.Check
                                key={option._id}
                                type="checkbox"
                                label={option.option}
                                checked={getAnswerForQuestion(
                                  question._id,
                                ).includes(option.option)}
                                readOnly
                              />
                            </div>
                          ),
                        )}
                      </>
                    )}
                    {/* {question.type === "dropdown" && (
                      <div>
                        <Form.Control
                          as="select"
                          value={getAnswerForQuestion(question._id)}
                          readOnly
                        >
                          {getOptionsForQuestion(question).map((option) => (
                            <option key={option._id} value={option.option}>
                              {option.option}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    )} */}
                    {/* {console.log(question)} */}
                    {question.type === "multiple_choice" && (
                      <>
                        {getOptionsForQuestion(question).map(
                          (option, index) => (
                            <div
                              key={index}
                              className={ isQuiz === true ? 
                                question?.answer === index.toString()
                                  ? styles.correct_option
                                  : getAnswerForQuestion(question._id) ===
                                    option._id
                                  ? styles.incorrect_option
                                  : styles.option : styles.option
                              }
                            >
                              <Form.Check
                                key={option._id}
                                type="radio"
                                id={option._id}
                                label={
                                  option.option ||
                                  findAnswerforOtherOption(option._id)
                                }
                                checked={
                                  getAnswerForQuestion(question._id) ===
                                  option._id
                                }
                                readOnly
                              />
                            </div>
                          ),
                        )}
                      </>
                    )}
                  </div>
                </Card>
              ))}
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default UserResponse;
