import React, { useEffect } from "react";
import styles from "./UserProfile.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData } from "../../redux/slice/profileSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HeaderAll from "../Headers/HeaderAll";
import Loader from "../../loader";

const UserProfile = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.profile.email);
  const name = useSelector((state) => state.profile.name);
  const role = useSelector((state) => state.profile.role);
  const organization = useSelector((state) => state.profile.organization);
  const img = useSelector((state) => state.profile.img);
  const loading = useSelector((state) => state.profile.loading);
  useEffect(() => {
    dispatch(fetchProfileData());
  }, [dispatch]);
  return (
    <>
      <HeaderAll />
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.profile_edit_page}>
            <div className={styles.profile_edit_icons}>
              <div>
                {img ? (
                  <img src={img} alt="Profile" className={styles.image_view} />
                ) : (
                  <AccountCircleIcon className={styles.profile_icon} />
                )}
              </div>
            </div>
            <div className={styles.div_of_user_details}>
              <p className={styles.names}>
                {/* Display skeleton if name is not available */}
                {name || <Skeleton width={200} />}
              </p>
              <span className={styles.email}>
                {/* Display skeleton if email is not available */}
                Email:&nbsp;
                {email || <Skeleton width={150} />}
              </span>
              <div style={{ marginTop: "1rem" }}>
                <span className={styles.email}>
                  {/* Display skeleton if roleId is not available */}
                  Role: &nbsp;
                  {role || <Skeleton width={100} />}
                </span>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <span className={styles.email}>
                  {/* Display skeleton if organizationId is not available */}
                  Organization: &nbsp;
                  {organization || <Skeleton width={150} />}
                </span>
              </div>
            </div>
            {/* <Modal show={show} onHide={() => setShow(false)} size="large">
          <Modal.Header closeButton className={styles.modal_headers}>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modal_headers}>
            <form className={styles.form_head_section}>
              <div className={styles.perent_div_of_image}>
                <span>Profile:</span>
                <div className={styles.image_preview_container}>
                  <img
                    src={
                      image
                        ? URL.createObjectURL(image)
                        : (profile && profile[0]?.userId.image) ||
                         
                            "/Images/Navbarimg/user-profile.png"
                    }
                    alt="Profile"
                    id="image-preview"
                    className={styles.image_preview}
                  />
                  <div className={styles.upload_image_div}>
                    <p
                      className={styles.upload_picture_text}
                      onClick={handleImageClick}
                    >
                      Upload a picture
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="file"
                id="image-inputProfilePage"
                placeholder="Please Select Your Image"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />

              <div className={styles.edit_area_container}>
                <span>Name:</span>
                <input
                  className={styles.edit_name_input}
                  type="text"
                  value={name} // Use 'value' prop to bind input value
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </form>
            <div className={styles.form_head_section}>
              <button
                type="button"
                className={styles.save_button}
                onClick={handleEditProfile}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </Modal> */}
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfile;
