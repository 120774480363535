import React from "react";
import { useSelector } from "react-redux";
import { NextSectionSelect } from "./NextSectionSelect";

function NextSection({ currentSectionIndex }) {
  const formData = useSelector((store) => store.preview.formData);

  if (formData?.sections?.length > 1)
    return (
      <div className="d-flex flex-column align-items-center justify-content-start flex-lg-row">
        <span>After section {currentSectionIndex + 1}</span>
        <NextSectionSelect
          formData={formData}
          currentSectionIndex={currentSectionIndex}
        />
      </div>
    );
}

export default NextSection;
