import classNames from "classnames/bind";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { CSVLink } from "react-csv"; // Added import for CSV export
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";
import Graphs from "../components/Graphs/Graphs";
import Header from "../components/Headers/Header";
import styles from "../css/ResponseTable.module.css";
import Loader from "../loader";
import {
  fetchSubmissionsData,
  fetchUserResponse,
} from "../redux/slice/responseSlice";

const ResponseTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [csvData, setCsvData] = useState([]); // Added state for CSV data
  const [excelData, setExcelData] = useState([]);
  const submissions = useSelector((state) => state.responses.submissions);
  const formTitle = useSelector((state) => state.responses.formTitle);
  const isQuiz = useSelector((state) => state.responses.isQuiz);

  const questions = useSelector((state) => state.responses.questions);
  const loading = useSelector((state) => state.responses.loading);
  const formId = useSelector((state) => state?.responses?.formId);
  const pathParts = window.location.pathname.split("/");
  const id = pathParts[pathParts.length - 1];

  const count = useSelector((state) => state?.responses.count);

  useEffect(() => {
    dispatch(fetchSubmissionsData(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (!loading && submissions && submissions.length > 0) {
      const csvData = [];
      const excelData = [];

      const headerRow = ["User"];
      const questionIds = submissions[0].reply.map((rep) => rep.questionId);
      headerRow.push(
        ...questionIds.map((questionId) => {
          return (
            questions.find((q) => q._id === questionId)?.question || "Unknown"
          );
        }),
      );
      if (isQuiz) {
        headerRow.push(
          "Total Question",
          "Total Right Answer",
          "Total Marks",
          "Total Points",
        );
      }
      csvData.push(headerRow);
      excelData.push(headerRow);

      submissions.forEach((submission) => {
        const userData = [submission.user];

        questionIds.forEach((questionId) => {
          const reply = submission.reply.find(
            (rep) => rep.questionId === questionId,
          );
          let answer;
          if (reply?.type === "multiple_choice" || reply?.type === "checkbox") {
            answer = reply?.answer?.option;
          } else {
            answer = reply?.answer;
          }

          userData.push(answer);
        });
        if (isQuiz) {
          userData.push(
            submission.totalQuestion,
            submission.totalRightAnswer,
            submission.totalMarks,
            submission.totalPoint,
          );
        }
        csvData.push(userData);
        excelData.push(userData);
      });

      setCsvData(csvData);
      setExcelData(excelData);
    }
  }, [submissions, questions, isQuiz, loading]);

  const exportToExcel = useCallback(() => {
    if (excelData && excelData?.length > 0) {
      const ws = XLSX.utils.aoa_to_sheet(excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Responses");

      // Generate a blob for the Excel file
      let blob;
      if (typeof Blob !== "undefined") {
        // Modern browsers
        blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      } else {
        // Handle browsers that don't support Blob
        console.error("Your browser doesn't support blob creation.");
        return;
      }

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a link and trigger a click event to download the Excel file
      const a = document.createElement("a");
      a.href = url;
      a.download = "responses.xlsx";
      a.click();

      // Clean up
      URL.revokeObjectURL(url);
    } else {
      // Handle the case when excelData is not yet available or empty
      console.error("excelData is not available or empty.");
    }
  }, [excelData]);

  const handleUserResponse = useCallback(
    (submissionId) => {
      navigate(`/responses/${formId}/userResponse/${submissionId}`);
      dispatch(fetchUserResponse(submissionId));
    },
    [navigate, dispatch, formId],
  );

  const lastIndex = submissions?.length - 1;
  const questionIds = submissions?.[lastIndex]?.reply?.map(
    (rep) => rep.questionId,
  );
  const users = [
    ...new Set(
      submissions?.map((data) => ({
        user: data?.user,
        submissionId: data?._id,
      })),
    ),
  ];

  const handleTruncate = useCallback((answer) => {
    // if (answer && answer.length > 15) {
    //   return `${answer.slice(0, 20)}...`;
    // }
    return answer;
  }, []);

  if (
    !loading &&
    (!submissions ||
      submissions.length === 0 ||
      !questions ||
      questions.length === 0)
  ) {
    return (
      <>
        <Header showTheme={false} title={formTitle} />
        <Container>
          <div className={styles.container_response}>
            <h1>0 Responses</h1>
            <p>No response Found</p>
          </div>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header title={formTitle} />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <div className={styles.response_main}>
            <div className="">
              {/* <Card className="titlepage_card shadow"> */}
              <Card
                className={classNames(styles.titlepage_card_response, "shadow")}
              >
                <span className={styles.titlepagecard_span_response}></span>
                <div className="m-2 d-flex flex-wrap justify-content-between mt-5">
                  <div className="d-flex flex-column align-items-start">
                    <h4 className="">
                      {count?.submitCount}{" "}
                      <span className="text-decoration-underline">
                        Responses
                      </span>
                    </h4>
                    <h4 className="">
                      <span className="text-decoration-underline">
                        Was Opened{" "}
                      </span>
                      {count?.openCount} times
                    </h4>
                  </div>
                  <div>
                    <CSVLink
                      data={csvData}
                      filename={"responses.csv"}
                      className={styles.export_button}
                    >
                      Export to CSV
                    </CSVLink>
                    <a onClick={exportToExcel} className={styles.export_button}>
                      Export to Excel
                    </a>
                  </div>
                </div>
              </Card>
            </div>

            <>
              <h3 className="mt-2">Responses for form: {formTitle}</h3>
              <div className="mt-3">
                <Table striped bordered hover responsive>
                  <thead>
                    <tr className="">
                      <th className="">User</th>
                      {questionIds.map((questionId) => (
                        <th className="" key={questionId}>
                          {questions.find((q) => q._id === questionId)
                            ?.question || "Unknown"}
                        </th>
                      ))}
                      {isQuiz && (
                        <>
                          <th className="text-truncate">Total Question</th>
                          <th className="text-truncate">Total Right Answer</th>
                          <th className="text-truncate">Total Marks</th>
                          <th className="text-truncate">Total Points</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => {
                      const submission = submissions.find(
                        (data) => data._id === user.submissionId,
                      );

                      return (
                        <tr
                          key={index}
                          className={styles.cursor}
                          onClick={() => handleUserResponse(user.submissionId)}
                        >
                          <th>{user.user}</th>
                          {questionIds.map((questionId) => {
                            const reply = submission.reply.find(
                              (rep) => rep.questionId === questionId,
                            );
                            const answer = reply?.answer;
                            return (
                              <>
                                <td key={`${questionId}-${user.user}`}>
                                  {Array.isArray(answer) ? (
                                    <>
                                      {answer.map((ans, index, array) => (
                                        <span key={index}>
                                          {ans.option}
                                          {index !== array.length - 1 && ", "}
                                        </span>
                                      ))}
                                    </>
                                  ) : typeof answer === "object" ? (
                                    <span>{answer.option}</span>
                                  ) : (
                                    handleTruncate(answer) || "-"
                                  )}
                                </td>
                              </>
                            );
                          })}
                          {isQuiz && (
                            <>
                              <td>{submission.totalQuestion}</td>
                              <td>{submission.totalRightAnswer}</td>
                              <td>{submission.totalMarks}</td>
                              <td>{submission.totalPoint}</td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <Graphs
                formId={formId}
                submissions={submissions}
                questions={questions}
              />
            </>
          </div>
        </Container>
      )}
    </>
  );
};

export default ResponseTable;
