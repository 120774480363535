import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import FormLogo from "../assets/form logo.png";
import "../css/thankyou.css";
import { NavLink, useLocation, useParams } from "react-router-dom";

const ThankYou = () => {
  const linkData = [
    {
      name: "Drive",
      image: "/Images/Navbarimg/d-favicon.png",
      href: "https://drive.appsdeployer.com",
    },
    {
      name: "Taskdeployer",
      image: "/Images/Navbarimg/Task.ico",
      href: "https://task.appsdeployer.com",
    },
    {
      name: "Ideadeployer",
      image: "/Images/Navbarimg/Idea-deployer-favicon.ico",
      href: "https://idea.appsdeployer.com",
    },
    {
      name: "Cxdeployer",
      image: "/Images/Navbarimg/cx-f.ico",
      href: "https://cx.appsdeployer.com",
    },
    // Add more items as needed
  ];

  const location = useLocation();
  const formId = location?.state?.formId;
  const submissonId = location?.state?.submissonId;

  // console.log("totalPoints",totalMarks,totalPoint)
  return (
    <Container fluid className="  text-center">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6} className="text-center">
          {location?.state?.result && (
            <h1 className="row">
              <b>
                Your Score {location?.state?.result?.totalPoint} /{" "}
                {location?.state?.result?.totalMarks}{" "}
              </b>
            </h1>
          )}

          <img src={FormLogo} alt="Form Logo" className="mt-5 mb-4 form-logo" />

          <h1>
            Thank You! <FaCheckCircle className="mb-2 text-success" />
          </h1>

          <p>
            Your response has been recorded. We appreciate your time and
            feedback!
          </p>

          <p>
            If you have more forms to fill or want to explore our services, you
            can visit our website:{" "}
            <a
              href="https://forms.appsdeployer.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              forms.appsdeployer.com
            </a>
          </p>

          <NavLink to={`/responses/${formId}/userResponse/${submissonId}`}>
            Please click here to see your responses.
          </NavLink>

          <div className="d-flex justify-content-center flex-wrap gap-3 mt-4">
            {linkData.map((item, index) => (
              <a
                key={item.name}
                href={item.href}
                className="text-dark text-decoration-none service-link"
              >
                <img
                  src={process.env.PUBLIC_URL + item.image}
                  alt={`Icon ${index + 1}`}
                  className="service-icon"
                />
                <p className="service-name">{item?.name}</p>
              </a>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ThankYou;
