import PropTypes from "prop-types";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { editWorkspace, getWorkspace } from "../../redux/action/workspace";
import styles from "./EditWorkspace.module.css";

const EditWorkspace = ({
  setEditModal,
  editModal,
  toggleEditModal,
  workspaceId: propWorkspaceId,
  workspaceName: propWorkspaceName,
  workspaceDesc: propWorkspaceDesc,
}) => {
  const selectedWorkspace = useSelector(
    (state) => state.workspace.selectedWorkspace,
  );
  const [buttonClicked, setButtonClicked] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  {
    /* .................... Workspace _id Variable .................... */
  }
  const _id =
    propWorkspaceId || (selectedWorkspace && selectedWorkspace.workspaceId);

  const [workspaceName, setWorkspaceName] = useState(
    propWorkspaceName || selectedWorkspace.workspaceName,
  );
  const [workspaceDesc, setWorkspaceDesc] = useState(
    propWorkspaceDesc || selectedWorkspace.workspaceDesc,
  );
  const [workspaceType, setWorkspaceType] = useState("");

  {
    /* ....................Cancel Click button Function.................... */
  }

  const cancelClick = () => {
    setEditModal(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);
    const workspaceData = {
      workspaceName,
      workspaceDesc,
      workspaceType,
    };

    dispatch(
      editWorkspace(workspaceData, _id, async function (response) {
        if (response) {
          setEditModal(false);
          alert.success(response.data.message);
        }
        dispatch(getWorkspace());
      }),
    );
  };

  return (
    <>
      <Modal show={editModal} onHide={toggleEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title> Edit Workspace</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            {/* ....................Workspace Name Section.................... */}

            <Form onSubmit={handleSubmit} style={{ border: "none" }}>
              <Form.Group className="mb-3 " controlId="workspaceName">
                <span className="">
                  <small className="text-muted fw-bold">
                    Name <span className="text-danger">*</span>
                  </small>
                </span>
                <Form.Control
                  type="text"
                  value={workspaceName}
                  onChange={(event) => setWorkspaceName(event.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="Textarea">
                <small className="text-muted fw-bold">
                  Workspace Description (Optional)
                </small>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={workspaceDesc}
                  onChange={(event) => setWorkspaceDesc(event.target.value)}
                />
              </Form.Group>

              {/* ....................Save and Cancel Button Section.................... */}
              <section>
                <div className="d-flex">
                  <Button
                    type="submit"
                    disabled={buttonClicked}
                    className={styles.save_btn}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={cancelClick}
                    variant="secondary"
                    className={styles.cancel_btn}
                  >
                    Cancel
                  </Button>
                </div>
              </section>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
EditWorkspace.propTypes = {
  workspaceId: PropTypes.node.isRequired,
  setEditModal: PropTypes.node.isRequired,
  editModal: PropTypes.node.isRequired,
  toggleEditModal: PropTypes.node.isRequired,
  workspaceName: PropTypes.node.isRequired,
  workspaceDesc: PropTypes.node.isRequired,
};
export default EditWorkspace;
