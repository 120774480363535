import React, { useCallback, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFormsData,
  deleteFormsData,
  setRespnseStatus,
  updateRecentVisitedForm,
} from "../../redux/slice/formSlice";
import { useAlert } from "react-alert";
import { useEffect } from "react";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { Button } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../../css/AllFormsCard.module.css";
import InviteForm from "../Workspace/InviteWorkspace";
import { SharePopup } from "../Workspace/Workspace";
import { getAllFormsOfWorkSpace } from "../../redux/action/workspace";

const AllFormsCard = ({ form, index }) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const pageNumber = useSelector((state) => state.forms.pageNumber);
  const pageSize = useSelector((state) => state.forms.pageSize);
  const [imageTheme, setImageTheme] = useState();
  useEffect(() => {
    setImageTheme(form?.image ? form?.image : "");
  }, [form]);

  const handlePreview = () => {
    const currentDate = new Date();
    const currentFormattedDate = currentDate.toISOString().split("T")[0];
    const currentFormattedTime = currentDate.toLocaleTimeString();
    const formData = {
      visitedTime: currentFormattedTime,
      visitedDate: currentFormattedDate,
    };
    dispatch(updateRecentVisitedForm({ formId: form?._id, formData }));
    navigate(`/editForm/${form?._id}`, {
      state: {
        show: true,
        formId: form?._id,
        form: form,
        imageTheme: imageTheme,
      },
    });
  };
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
  const [sharePopupDiv, setSharePopupDiv] = useState(false);

  const handleSharePopup = () => {
    setSharePopup(!sharePopup);
    setSharePopupDiv(true);
    handleClose();
  };

  const { workspaceId } = useParams();

  const [showDelteModal, setShowDeleteModal] = useState(false);
  const handleRemove = useCallback(async () => {
    try {
      const response = await dispatch(deleteFormsData(form?._id)).then(() => {
        dispatch(getAllFormsOfWorkSpace(workspaceId));
        alert.success("Form added to trash");
        dispatch(setRespnseStatus());
        setShowDeleteModal(false);
      });
    } catch (error) {
      console.error("Error deleting form:", error);
    }
  }, [dispatch, form, alert, workspaceId]);

  const [anchorEle, setAnchorEle] = useState(null);
  const handleClose = () => {
    setAnchorEle(null);
  };
  const handleCloseDeletePopup = () => {
    setShowDeleteModal(false);
    // setAnchorEle(null);
  };
  const handleClick = (event) => {
    setAnchorEle(event.currentTarget);
  };
  const handleOpenModel = () => {
    setShowDeleteModal(true);
    handleClose()
    // setAnchorEle(null);
  };

  return (
    <>
      {/* To render the popup of share form */}
      {sharePopupDiv ? (
        <SharePopup
          workspaceId={workspaceId}
          formId={form?._id}
          workspaceName={
            form?.formTitle ? form?.formTitle : form?.sections[0].title
          }
          isForm={"isForm"}
          setSharePopup={setSharePopup}
          setIsOpen={setIsOpen}
          setSharePopupDiv={setSharePopupDiv}
        />
      ) : null}
      {/* dialog box for the confirm delete modal */}
      <Dialog
        open={showDelteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="responsive-dialog-title"
        fullWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDeletePopup}>
            Cancel
          </Button>
          <Button onClick={handleRemove} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className={styles.workspace_challenge__body}>
        <div className={styles.workspace_challenge__card}>
          <div onClick={handlePreview}>
            <div className={styles.workspace_challenge__card_header}>
              <p className={styles.workspace_challenge__card_header_text}>
                {form?.formTitle ? form?.formTitle : form?.sections[0].title}
              </p>
            </div>
            <div
              onClick={handlePreview}
              className={styles.workspace_challenge__card_desc}
            >
              {form?.description}
            </div>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEle}
            keepMounted
            open={Boolean(anchorEle)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenModel}>Delete</MenuItem>
            <MenuItem onClick={handlePreview}>Edit</MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/responses/${form?._id}`);
              }}
            >
              Response
            </MenuItem>
            <MenuItem onClick={handleSharePopup}>
              <InviteForm
                workspaceId={workspaceId}
                formId={form?._id}
                workspaceName={
                  form?.formTitle ? form?.formTitle : form?.sections[0].title
                }
                isForm={"isForm"}
                setSharePopup={setSharePopup}
                setIsOpen={setIsOpen}
              />
            </MenuItem>
          </Menu>

          <div className={styles.workspace_challenge__card_footer}>
            <div
              onClick={handlePreview}
              className={styles.workspace_challenge__card_footer_left}
            >
              <img
                className={styles.switch_dots_form}
                src={
                  // process.env.PUBLIC_URL +
                  "/Images/Navbarimg/form-f.svg"
                }
                alt="icon"
              />
              <p className={styles.workspace_challenge__count}>
                {new Date(form?.updatedAt).toLocaleString("en-IN", options)}
              </p>
            </div>
            <div>
              <IconButton
                className={styles.svg_ions}
                key={index}
                onClick={handleClick}
              >
                <svg
                  width="3"
                  height="12"
                  viewBox="0 0 3 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="1.5" cy="1.5" r="1.5" fill="#444444" />
                  <circle cx="1.5" cy="6" r="1.5" fill="#444444" />
                  <circle cx="1.5" cy="10.5" r="1.5" fill="#444444" />
                </svg>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllFormsCard;
