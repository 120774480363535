import React from "react";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const getRandomColor = () => {
  const colorValues = [
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
  ];
  return `rgb(${colorValues.join(",")})`;
};

const HorizontalBarChart = ({ questions, submissions, questionId }) => {
  const question = questions.find((q) => q._id === questionId);
  const optionsArray = question.options.map((option) => option.option);
  const optionCounts = question.options.map((option) => ({
    optionId: option._id,
    count: 0,
  }));

  // submissions.forEach((item) => {
  //   const reply = item.reply?.find((rep) => rep.questionId === questionId);
  //   if (reply) {
  //     reply?.answer &&
  //       reply?.answer?.forEach((selectedOption) => {
  //         if (selectedOption.optionId) {
  //           const optionId = selectedOption.optionId.toString();
  //           const optionFound = optionCounts.find(
  //             (opt) => opt.optionId === optionId,
  //           );
  //           if (optionFound) {
  //             optionFound.count += 1;
  //           }
  //         }
  //       });
  //   }
  // });

  const data = {
    labels: optionsArray,
    datasets: [
      {
        label: "User Responses",
        data: optionCounts.map((option) => option.count),
        backgroundColor: optionCounts.map(() => getRandomColor()),
        borderWidth: 1,
        tension: 0.1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
  };

  return (
    <div className="graphs">
      <Bar data={data} options={options} />
    </div>
  );
};

export default HorizontalBarChart;
