const initialState = {
  allWorkspaces: [],
  selectedWorkspace: {},
  guestWorkspace: [],
  newWorkspace: {},
  trashedWorkspace: [],
  selectedWorkspaceId: "",
  new_user: false,
  loader: true,
  sidebarOpen: false,
  forms: [],
};
const WorkspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NEWUSER":
      return {
        ...state,
        new_user: action.payload,
        loader: false,
      };
    case "SET_SIDEBAR":
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    case "SET_WORKSPACES":
      return {
        ...state,
        allWorkspaces: action.payload.allWorkspaces,
        guestWorkspace: action.payload.allguestWorkspace,
        // selectedWorkspace:{},
        new_user: false,
        loader: false,
      };

    case "SET_SELECTED_WORKSPACES":
      return {
        ...state,
        selectedWorkspace: action.payload,
      };

    case "RESET_SELECTEDWORKSPACE":
      return {
        ...state,
        selectedWorkspace: action.payload,
      };
    case "SET_SELECTED_WORKSPACES_ID":
      return {
        ...state,
        selectedWorkspaceId: action.payload.workspaceId,
        selectedWorkspace: action.payload.workspace,
      };
    case "SET_NEW_WORKSPACES":
      return {
        ...state,
        allWorkspaces: [...state.allWorkspaces, action.payload.newWorkspace],
        newWorkspace: action.payload.newWorkspace,
      };

    case "SET_WORKSPACE":
      return {
        ...state,
        allWorkspaces: [action.payload, ...state.allWorkspaces],
      };
    case "UPDATE_WORKSPACE":
      return {
        ...state,
        allWorkspaces: state.allWorkspaces.map((workspace) =>
          workspace._id === action.payload.workspaceId
            ? action.payload
            : workspace,
        ),
        selectedWorkspace: action.payload,
      };
    case "UPDATE_STAR_WORKSPACE":
      return {
        ...state,
        allWorkspaces: state.allWorkspaces.map((item) =>
          item._id === action.payload.workspaceId
            ? { ...item, star: action.payload.starred }
            : item,
        ),
      };
    case "LEAVE_WORKSPACES":
      return {
        ...state,
        guestWorkspace: state.guestWorkspace.filter(
          (workspace) => workspace._id !== action.payload._id,
        ),
      };
    case "DELETE_WORKSPACES":
      return {
        ...state,
        allWorkspaces: state.allWorkspaces.filter(
          (workspace) => workspace._id !== action.payload._id,
        ),
      };
    case "TRASHED_WORKSPACE":
      return {
        ...state,
        loader: false,
        trashedWorkspace: action.payload,
      };
    case "WORKSPACE_CLEAR":
      return {
        ...state,
        allWorkspaces: [],
        selectedWorkspace: {},
        guestWorkspace: [],
        trashedWorkspace: [],
        newWorkspace: {},
        selectedWorkspaceId: "",
        new_user: false,
        loader: true,
      };
    case "SET_WORKSPACE_FORMS":
      return {
        ...state,
        forms: action.payload,
      };
    default:
      return state;
  }
};

export default WorkspaceReducer;
