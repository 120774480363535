import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Table } from "react-bootstrap";
import { BiBookmarkMinus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getWorkspace,
  setSelectedWorkspace,
  starredWorkspace,
} from "../../redux/action/workspace.js";
import EditWorkspace from "./EditWorkspace";
import InviteWorkspace from "./InviteWorkspace.js";
import "./Workspace.css";
import WorkspacePopup from "./WorkspaceCreatedPopup.jsx";
import WorkspaceLeaveDeletePopup from "./WorkspaceLeaveDeletePopup.jsx";
import AddWorkspacePopup from "./addWorkspacePopup";
import InviteSpaceInput from "./InviteSpaceInput.js";

const Workspace = () => {
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const createParam = searchParams.get("mode");
  const userId = localStorage.getItem("user_id");
  const [isCheckPopup, setIsCheckPopup] = useState(false);
  const [leaveDeletePopup, setLeaveDeletePopup] = useState(false);
  const [leaveDeleteData, setLeaveDeleteData] = useState([]);
  const dispatch = useDispatch();
  const { allWorkspaces, guestWorkspace } = useSelector((state) => ({
    allWorkspaces: state.workspace.allWorkspaces,
    guestWorkspace: state.workspace.guestWorkspace,
  }));

  useEffect(() => {
    dispatch(getWorkspace());
  }, [dispatch]);

  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const [activeTab, setActiveTab] = useState("recentWorkspace");
  const [createWorkspacePopup, setCreateWorkspacePopup] = useState(false);
  const [isWorkspaceId, setIsWorkspaceId] = useState(null);
  const user_id = localStorage.getItem("user_id");
  const [forEditWorkspace, setForEditWorkspace] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenId, setIsOpenId] = useState(null);
  const [isStarButtonClicked, setIsStarButtonClicked] = useState(false);
  const [newWorkspaceCreated, setNewWorkspaceCreated] = useState(false);
  const [shareId, setShareId] = useState(null);
  const [shareName, setShareName] = useState(null);
  const [sharePopupDiv, setSharePopupDiv] = useState(false);

  const handleOpen = (workspace) => {
    setIsWorkspaceId(workspace?._id);
    if (workspace?._id === isOpenId) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(true);
      setIsOpenId(workspace?._id);
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);

  const handleSharePopup = (id, name) => {
    console.log(id, name);
    setShareId(id);
    setShareName(name);
    setSharePopup(!sharePopup);
    setSharePopupDiv(true);
    setIsOpen(false);
  };

  const handleSelectWorkspace = useCallback(
    (workspace) => {
      setShowPopup(!showPopup);
      // dispatch(setSelectedBoard(workspace));
      const workspaceId = workspace?._id;
      navigate(`/workspace/${workspaceId}`);
    },
    [dispatch, navigate, showPopup],
  );

  const handleLeaveDeletePopup = (workspace) => {
    setLeaveDeleteData(workspace);
    setLeaveDeletePopup((prev) => !prev);
  };
  const handleStarred = useCallback(
    (workspace, isStar) => {
      if (isStarButtonClicked) {
        return;
      }
      setIsStarButtonClicked(true);
      const workspaceId = workspace?._id;
      const workspaceName = workspace?.workspaceName;
      let payload = { workspaceId, isStar };
      dispatch(
        starredWorkspace(payload, (res) => {
          alert.success(workspaceName + " " + res?.data?.message);
          setIsStarButtonClicked(false);
        }),
      );
    },
    [userId, dispatch, navigate],
  );

  const toggleEditModal = (workspace) => {
    dispatch(
      setSelectedWorkspace(workspace, () => {
        setForEditWorkspace(workspace);
        setEditModal((prevEditModal) => !prevEditModal);
      }),
    );
  };

  const createWorkspacehandleClick = useCallback(() => {
    if (createWorkspacePopup === false) {
      setCreateWorkspacePopup(!createWorkspacePopup);
    }
  }, [createWorkspacePopup]);

  useEffect(() => {
    if (
      allWorkspaces?.length === 0 &&
      guestWorkspace?.length === 0 &&
      createParam === "create"
    ) {
      dispatch({
        type: "SET_WORKSPACES",
        payload: {
          allWorkspaces: [],
          allguestWorkspace: [],
        },
      });

      dispatch({
        type: "SET_SELECTED_WORKSPACES",
        payload: {},
      });
      dispatch({
        type: "SET_NEWUSERFORBOARD",
        payload: true,
      });
      // navigate("/");
    }
    if (allWorkspaces?.length === 0 && guestWorkspace?.length === 0) {
      dispatch({
        type: "SET_NEWUSERFORBOARD",
        payload: true,
      });
      // navigate("/");
    } else if (createParam === "create" && isCheckPopup === false) {
      setCreateWorkspacePopup(true);
      setIsCheckPopup(true);
    }
    if (allWorkspaces?.length === 0 && guestWorkspace?.length === 0) {
      dispatch({
        type: "SET_NEWUSERFORBOARD",
        payload: true,
      });
      // navigate("/");
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedAllWorkspaces = allWorkspaces.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const paginatedGuestWorkspaces = guestWorkspace?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );
  const combinedWorkspaces = [...allWorkspaces, ...guestWorkspace];

  const filteredCombinedWorkspaces = combinedWorkspaces.filter(
    (workspace) => workspace?.workspaceType !== "guest",
  );

  const paginatedCombinedWorkspaces = filteredCombinedWorkspaces.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handleAllWorkspaceClick = useCallback(() => {
    setActiveTab("recentWorkspace");
    setCurrentPage(1);
    setItemPerPage(10);
  }, []);

  const handleMyWorkspaceClick = useCallback(() => {
    setActiveTab("allworkspace");
    setCurrentPage(1);
    setItemPerPage(10);
  }, []);

  const handleGuestWorkspaceClick = useCallback(() => {
    setActiveTab("guestworkspace");
    setCurrentPage(1);
    setItemPerPage(10);
  }, []);

  const totalItems = allWorkspaces?.length + guestWorkspace?.length;
  const allWorkspace = totalItems > 10;
  const totalguestWorkspace = guestWorkspace?.length > 10;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1,
  );
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsOpenId(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="userboard">
        {/* To render the popup of share form */}
        {sharePopupDiv ? (
          <SharePopup
            workspaceId={shareId}
            workspaceName={shareName}
            setSharePopup={setSharePopup}
            setIsOpen={setIsOpen}
            setSharePopupDiv={setSharePopupDiv}
          />
        ) : null}
        <div className="headingandbutton_div">
          <h5 className="workspace_heading">My Workspace</h5>
          <button
            className="addworkspace_btn d-flex align-items-center justify-content-center"
            onClick={createWorkspacehandleClick}
          >
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <defs>
                <style />
              </defs>
              <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
              <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />
            </svg>
            <div>Add Workspace</div>
          </button>
        </div>
        <div className="feed">
          <div className="feed__inputContainer">
            {/* Tabs */}
            <div className="tabsand_btn">
              <div className="tab-container">
                <div
                  className={`tab ${
                    activeTab === "recentWorkspace" ? "active" : ""
                  }`}
                  onClick={handleAllWorkspaceClick}
                >
                  ALL WORKSPACES
                </div>
                <div
                  className={`tab ${
                    activeTab === "allworkspace" ? "active" : ""
                  }`}
                  onClick={handleMyWorkspaceClick}
                >
                  MY WORKSPACES
                </div>
                <div
                  className={`tab ${
                    activeTab === "guestworkspace" ? "active" : ""
                  }`}
                  onClick={handleGuestWorkspaceClick}
                >
                  SHARED WORKSPACES
                </div>
              </div>
            </div>

            <AddWorkspacePopup
              createWorkspacePopup={createWorkspacePopup}
              onClose={createWorkspacehandleClick}
              setCreateWorkspacePopup={setCreateWorkspacePopup}
              newWorkspaceCreated={newWorkspaceCreated}
              setNewWorkspaceCreated={setNewWorkspaceCreated}
            />

            {/* Tab Content */}
            {activeTab === "recentWorkspace" && (
              <div
                className={`tab-content recentWorkspace ${
                  activeTab === "recent" ? "active" : ""
                }`}
              >
                <Table hover>
                  <thead className="workspace_table_heading">
                    <tr className="curved-border">
                      <th>Workspaces</th>
                      <th>Owner</th>
                      <th>Date Created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table_body">
                    <tr style={{ width: "100%", height: "7px" }}></tr>
                    {paginatedCombinedWorkspaces &&
                      paginatedCombinedWorkspaces?.map((workspace, key) => (
                        <tr key={key} className="table_row">
                          <td onClick={() => handleSelectWorkspace(workspace)}>
                            {workspace?.workspaceName}
                          </td>
                          <td>{workspace?.createdByName}</td>
                          <td>{moment(workspace?.createdAt).format("ll")}</td>
                          <td className="doted_icon">
                            <svg
                              viewBox="0 0 128 512"
                              fill="currentColor"
                              height="1em"
                              width="1em"
                              onClick={() => handleOpen(workspace)}
                              className="ellipsis_td"
                            >
                              <path d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm56-104c0 30.9-25.1 56-56 56S8 126.9 8 96s25.1-56 56-56 56 25.1 56 56z" />
                            </svg>
                            {isOpen && isOpenId === workspace?._id && (
                              <>
                                <ul className="table_popup" ref={dropdownRef}>
                                  <li
                                    onClick={() => toggleEditModal(workspace)}
                                  >
                                    <svg
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      style={{ marginRight: ".5rem" }}
                                    >
                                      <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
                                      <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
                                    </svg>
                                    Edit
                                  </li>
                                  {workspace?.createdBy === user_id ? (
                                    <li
                                      onClick={() =>
                                        handleLeaveDeletePopup(workspace)
                                      }
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        height="1em"
                                        width="1em"
                                        style={{ marginRight: ".5rem" }}
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                                      </svg>
                                      Delete
                                    </li>
                                  ) : (
                                    <li
                                      onClick={() =>
                                        handleLeaveDeletePopup(workspace)
                                      }
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        height="1em"
                                        width="1em"
                                        style={{ marginRight: ".5rem" }}
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                                      </svg>
                                      Leave
                                    </li>
                                  )}
                                  <li
                                    onClick={() =>
                                      handleSharePopup(
                                        workspace?._id,
                                        workspace?.workspaceName,
                                      )
                                    }
                                  >
                                    <InviteWorkspace
                                      workspaceId={workspace?._id}
                                      workspaceName={workspace?.workspaceName}
                                      setSharePopup={setSharePopup}
                                      setIsOpen={setIsOpen}
                                    />
                                  </li>
                                  {workspace?.workspaceMember?.map((member) => {
                                    if (user_id === member?.w_memberid) {
                                      return (
                                        <>
                                          {workspace?.star === true ? (
                                            <li
                                              key={member._id}
                                              onClick={() =>
                                                handleStarred(
                                                  workspace,
                                                  !workspace?.star,
                                                )
                                              }
                                              className={
                                                isStarButtonClicked
                                                  ? "disable_Star_Option"
                                                  : ""
                                              }
                                            >
                                              <svg
                                                viewBox="0 0 1024 1024"
                                                fill="yellow"
                                                height="1em"
                                                width="1em"
                                                style={{
                                                  marginRight: ".7rem",
                                                }}
                                              >
                                                <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z" />
                                              </svg>
                                              Unstar
                                            </li>
                                          ) : (
                                            <li
                                              key={member._id}
                                              onClick={() =>
                                                handleStarred(
                                                  workspace,
                                                  !workspace?.star,
                                                )
                                              }
                                              className={
                                                isStarButtonClicked
                                                  ? "disable_Star_Option"
                                                  : ""
                                              }
                                            >
                                              <svg
                                                viewBox="0 0 1024 1024"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                                style={{
                                                  marginRight: ".5rem",
                                                }}
                                              >
                                                <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z" />
                                              </svg>
                                              Star
                                            </li>
                                          )}
                                        </>
                                      );
                                    }
                                    return null; // Return null for members where userId is not equal to w_memberid
                                  })}
                                </ul>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                {editModal && (
                  <EditWorkspace
                    editModal={editModal}
                    setEditModal={setEditModal}
                    toggleEditModal={toggleEditModal}
                    workspaceName={forEditWorkspace?.workspaceName}
                    workspaceColor={forEditWorkspace?.workspaceColor}
                    workspaceDesc={forEditWorkspace?.workspaceDesc}
                    workspaceId={forEditWorkspace?._id}
                  />
                )}

                {allWorkspace && (
                  <div className="pagination">
                    {currentPage > 1 && (
                      <button onClick={() => handlePageChange(currentPage - 1)}>
                        Previous
                      </button>
                    )}
                    {pageNumbers.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={pageNumber === currentPage ? "active" : ""}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}
                    {currentPage < totalPages && (
                      <button onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}

            {activeTab === "allworkspace" && (
              <>
                <div
                  className={`tab-content allworkspace ${
                    activeTab === "workspaces" ? "active" : ""
                  }`}
                >
                  <Table hover className="no-border">
                    <thead className="workspace_table_heading">
                      <tr className="curved-border">
                        <th>Workspaces</th>
                        <th>Owner</th>
                        <th>Date Created</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="table_body">
                      <tr style={{ width: "100%", height: "7px" }}></tr>
                      {paginatedAllWorkspaces?.length > 0 ? (
                        paginatedAllWorkspaces.map((workspace, key) => (
                          <tr key={key} className="table_row no-border">
                            <td
                              onClick={() => handleSelectWorkspace(workspace)}
                            >
                              {workspace?.workspaceName}
                            </td>
                            <td>{workspace?.createdByName}</td>
                            <td>{moment(workspace?.createdAt).format("ll")}</td>
                            <td className="doted_icon">
                              <svg
                                viewBox="0 0 128 512"
                                fill="currentColor"
                                height="1em"
                                width="1em"
                                onClick={() => handleOpen(workspace)}
                                className="ellipsis_td"
                              >
                                <path d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm56-104c0 30.9-25.1 56-56 56S8 126.9 8 96s25.1-56 56-56 56 25.1 56 56z" />
                              </svg>
                              {isOpen && isOpenId === workspace?._id && (
                                <ul className="table_popup" ref={dropdownRef}>
                                  <li
                                    onClick={() => toggleEditModal(workspace)}
                                  >
                                    <svg
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      style={{ marginRight: ".5rem" }}
                                    >
                                      <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
                                      <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
                                    </svg>
                                    Edit
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleLeaveDeletePopup(workspace)
                                    }
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      height="1em"
                                      width="1em"
                                      style={{ marginRight: ".5rem" }}
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                                    </svg>
                                    Delete
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleSharePopup(
                                        workspace?._id,
                                        workspace?.workspaceName,
                                      )
                                    }
                                  >
                                    <InviteWorkspace
                                      workspaceId={workspace?._id}
                                      workspaceName={workspace?.workspaceName}
                                      setSharePopup={setSharePopup}
                                      setIsOpen={setIsOpen}
                                    />
                                  </li>
                                  {workspace?.workspaceMember?.map((member) => {
                                    if (user_id === member.w_memberid) {
                                      return (
                                        <li
                                          key={member._id}
                                          onClick={() =>
                                            handleStarred(
                                              workspace,
                                              !workspace?.star,
                                            )
                                          }
                                          className={
                                            isStarButtonClicked
                                              ? "disable_Star_Option"
                                              : ""
                                          }
                                        >
                                          {workspace?.star ? (
                                            <>
                                              {" "}
                                              <svg
                                                viewBox="0 0 1024 1024"
                                                fill="yellow"
                                                height="1em"
                                                width="1em"
                                                style={{
                                                  marginRight: ".7rem",
                                                }}
                                              >
                                                <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z" />
                                              </svg>
                                              Unstar
                                            </>
                                          ) : (
                                            <>
                                              <svg
                                                viewBox="0 0 1024 1024"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                                style={{
                                                  marginRight: ".5rem",
                                                }}
                                              >
                                                <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z" />
                                              </svg>
                                              Star
                                            </>
                                          )}
                                        </li>
                                      );
                                    }
                                    return null; // Return null for members where userId is not equal to w_memberid
                                  })}
                                </ul>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="3"
                            style={{
                              textAlign: "center",
                              backgroundColor: "white",
                            }}
                          >
                            No workspaces found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  {editModal && (
                    <EditWorkspace
                      editModal={editModal}
                      setEditModal={setEditModal}
                      toggleEditModal={toggleEditModal}
                      workspaceName={forEditWorkspace?.workspaceName}
                      workspaceColor={forEditWorkspace?.workspaceColor}
                      workspaceDesc={forEditWorkspace?.workspaceDesc}
                      workspaceId={forEditWorkspace?._id}
                    />
                  )}
                </div>
                {allWorkspace && (
                  <div className="pagination">
                    {currentPage > 1 && (
                      <button onClick={() => handlePageChange(currentPage - 1)}>
                        Previous
                      </button>
                    )}
                    {pageNumbers.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={pageNumber === currentPage ? "active" : ""}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}
                    {currentPage < totalPages && (
                      <button onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                      </button>
                    )}
                  </div>
                )}
              </>
            )}

            {activeTab === "guestworkspace" && (
              <div
                className={`tab-content guestworkspace ${
                  activeTab === "guest" ? "active" : ""
                }`}
              >
                <Table hover>
                  <thead className="workspace_table_heading">
                    <tr className="curved-border">
                      <th>Workspaces</th>
                      <th>Owner</th>
                      <th>Date Created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table_body">
                    <tr style={{ width: "100%", height: "7px" }}></tr>
                    {paginatedGuestWorkspaces?.length > 0 ? (
                      paginatedGuestWorkspaces.map((workspace, key) => (
                        <tr key={key} className="table_row">
                          <td onClick={() => handleSelectWorkspace(workspace)}>
                            {workspace?.workspaceName}
                          </td>
                          <td>{workspace?.createdByName}</td>
                          <td>{moment(workspace?.createdAt).format("ll")}</td>

                          <td className="doted_icon">
                            <svg
                              viewBox="0 0 128 512"
                              fill="currentColor"
                              height="1em"
                              width="1em"
                              onClick={() => handleOpen(workspace)}
                              className="ellipsis_td"
                            >
                              <path d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm56-104c0 30.9-25.1 56-56 56S8 126.9 8 96s25.1-56 56-56 56 25.1 56 56z" />
                            </svg>
                            {isOpen && isOpenId === workspace?._id && (
                              <ul className="table_popup" ref={dropdownRef}>
                                <li onClick={() => toggleEditModal(workspace)}>
                                  <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    style={{ marginRight: ".5rem" }}
                                  >
                                    <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
                                    <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
                                  </svg>
                                  Edit
                                </li>
                                <li
                                  onClick={() =>
                                    handleLeaveDeletePopup(workspace)
                                  }
                                >
                                  <BiBookmarkMinus
                                    style={{ marginRight: ".5rem" }}
                                    color="#000"
                                  />{" "}
                                  Leave
                                </li>
                                <li
                                  onClick={() =>
                                    handleSharePopup(
                                      workspace?._id,
                                      workspace?.workspaceName,
                                    )
                                  }
                                >
                                  <InviteWorkspace
                                    workspaceId={workspace?._id}
                                    workspaceName={workspace?.workspaceName}
                                    setSharePopup={setSharePopup}
                                    setIsOpen={setIsOpen}
                                  />
                                </li>
                                {workspace?.workspaceMember?.map((member) => {
                                  if (user_id === member.w_memberid) {
                                    return (
                                      <li
                                        key={member._id}
                                        onClick={() =>
                                          handleStarred(
                                            workspace,
                                            !workspace?.star,
                                          )
                                        }
                                        className={
                                          isStarButtonClicked
                                            ? "disable_Star_Option"
                                            : ""
                                        }
                                      >
                                        {workspace?.star ? (
                                          <>
                                            <svg
                                              viewBox="0 0 1024 1024"
                                              fill="yellow"
                                              height="1em"
                                              width="1em"
                                              style={{
                                                marginRight: ".7rem",
                                              }}
                                            >
                                              <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z" />
                                            </svg>
                                            Unstar
                                          </>
                                        ) : (
                                          <>
                                            <svg
                                              viewBox="0 0 1024 1024"
                                              fill="currentColor"
                                              height="1em"
                                              width="1em"
                                              style={{
                                                marginRight: ".5rem",
                                              }}
                                            >
                                              <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z" />
                                            </svg>
                                            Star
                                          </>
                                        )}
                                      </li>
                                    );
                                  }
                                  return null; // Return null for members where userId is not equal to w_memberid
                                })}
                              </ul>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            textAlign: "center",
                            backgroundColor: "white",
                          }}
                        >
                          No shared workspaces found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {editModal && (
                  <EditWorkspace
                    editModal={editModal}
                    setEditModal={setEditModal}
                    toggleEditModal={toggleEditModal}
                    workspaceName={forEditWorkspace?.workspaceName}
                    workspaceColor={forEditWorkspace?.workspaceColor}
                    workspaceDesc={forEditWorkspace?.workspaceDesc}
                    workspaceId={forEditWorkspace?._id}
                  />
                )}
                {totalguestWorkspace && (
                  <div className="pagination">
                    {currentPage > 1 && (
                      <button onClick={() => handlePageChange(currentPage - 1)}>
                        Previous
                      </button>
                    )}
                    {pageNumbers.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={pageNumber === currentPage ? "active" : ""}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}
                    {currentPage < totalPages && (
                      <button onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {leaveDeletePopup && (
          <WorkspaceLeaveDeletePopup
            handleLeaveDeletePopup={handleLeaveDeletePopup}
            leaveDeletePopup={leaveDeletePopup}
            leaveDeleteData={leaveDeleteData}
          />
        )}
        {newWorkspaceCreated ? (
          <WorkspacePopup onClose={setNewWorkspaceCreated} />
        ) : null}
      </div>
    </>
  );
};

export default Workspace;

export const SharePopup = ({
  workspaceId,
  workspaceName,
  setSharePopup,
  setIsOpen,
  formId,
  isForm,
  setSharePopupDiv,
}) => {
  const location = useLocation();
  const isWorkspace = location.pathname.includes("workspace");
  return (
    <div
      className="position-fixed top-50 start-50 translate-middle p-4 bg-white rounded shadow popup_main_container"
      style={{ zIndex: 1000 }}
    >
      <div className="d-flex align-items-center justify-content-between gap-5">
        <h1 className="popup_heading">
          {isWorkspace ? "Share form" : "Share workspace"}
        </h1>
        <h3 onClick={() => setSharePopupDiv(false)}>
          <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em">
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
              clipRule="evenodd"
            />
          </svg>
        </h3>
      </div>
      <div className="popup_divider"></div>
      <InviteSpaceInput
        workspaceId={workspaceId}
        workspaceName={workspaceName}
        setSharePopup={setSharePopup}
        setSharePopupDiv={setSharePopupDiv}
        setIsOpen={setIsOpen}
        isForm={isForm}
        formId={formId}
      />
    </div>
  );
};
