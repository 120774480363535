import React, { useEffect } from "react";
import "./WorkspaceCreatedPopup.css";

const WorkspacePopup = ({ onClose }) => {
	const closePopup = () => {
		onClose(false);
	};

	useEffect(() => {
		const timeout = setTimeout(closePopup, 2000);
		return () => {
			clearTimeout(timeout);
		};
	});

	return (
		<div className="popup-overlay">
			<div className="workspace-popup">
				<span role="img" aria-label="tick" className="tick">
					✔️
				</span>
				<p className="popup-text">Workspace Created Successfully!</p>
				<button type="submit" className="close-icon" onClick={closePopup}>
					X
				</button>
			</div>
		</div>
	);
};

export default WorkspacePopup;
