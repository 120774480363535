import "iframe-resizer/js/iframeResizer.contentWindow";
import React from "react";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const options = {
  position: "top right",
  timeout: 4000,
  containerStyle: {
    zIndex: 1000000,
    marginTop: "70px",
  },
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
        <Provider store={store}>
          <App />
        </Provider>
      </AlertProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
reportWebVitals();
