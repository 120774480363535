import { configureStore } from "@reduxjs/toolkit";
import WorkspaceReducer from "./reducer/workspaceReducer";
import formReducer from "./slice/formSlice";
import previewReducer from "./slice/previewSlice";
import profileReducer from "./slice/profileSlice";
import responseReducer from "./slice/responseSlice";
import shareWorkspace_usersSliceReducer from "./slice/shareWorkspace_usersSlice";
import userReducer from "./slice/userSlice";
import templateReducer from "./slice/templateSlice";

const store = configureStore({
  reducer: {
    forms: formReducer,
    responses: responseReducer,
    preview: previewReducer,
    user: userReducer,
    profile: profileReducer,
    workspace: WorkspaceReducer,
    shareWorkspace_users: shareWorkspace_usersSliceReducer,
    template: templateReducer,
  },
});

export default store;
