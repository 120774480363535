import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../../css/AllFormsCard.module.css";
import { getWorkspace } from "../../redux/action/workspace";
import { createForm } from "../../redux/slice/formSlice";
import classNames from "classnames";

const AllTemplateCard = ({ form, index }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const [formTitle, setFormTitle] = useState(form?.formTitle);

  const pageNumber = useSelector((state) => state.forms.pageNumber);
  const pageSize = useSelector((state) => state.forms.pageSize);
  const [imageTheme, setImageTheme] = useState();
  useEffect(() => {
    setImageTheme(form?.image ? form?.image : "");
  }, [form]);

  const handlePreview = useCallback(() => {
    navigate(`/template/${form?._id}`, {
      state: {
        show: true,
        formId: form?._id,
        form: form,
        imageTheme: imageTheme,
      },
    });
  }, [navigate, form, imageTheme]);
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const [anchorEle, setAnchorEle] = useState(null);
  const handleClick = useCallback((event) => {
    setAnchorEle(event.currentTarget);
  }, []);

  const [show, setShow] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const workspaces = useSelector((store) => store.workspace.allWorkspaces);

  useEffect(() => {
    dispatch(getWorkspace());
  }, [dispatch]);

  useEffect(() => {
    if (workspaces?.length > 0) {
      setSelectedWorkspace(workspaces?.[0]._id);
    }
  }, [workspaces]);

  const handleClose = useCallback(() => {
    setShow(false);
    setAnchorEle(false);
  }, []);
  const handleShow = useCallback(() => {
    setShow(true);
    setAnchorEle(null);
  }, []);

  const handleAddToWorkspace = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        createForm({
          ...form,
          formTitle: formTitle,
          workspaceId: selectedWorkspace,
        }),
      )
        .then(() => {
          alert.success("Form added to workspace");
        })
        .then(() => {
          setShow(false);
          navigate(`/workspace/${selectedWorkspace}`);
        })
        .catch((err) => alert.error("Failed to add to workspace"));
    },
    [form, alert, navigate, dispatch, selectedWorkspace, formTitle],
  );

  const handleChangeWorkspace = useCallback((e) => {
    setSelectedWorkspace(e.target.value);
  }, []);

  return (
    <>
      <div className={styles.workspace_challenge__body}>
        <div className={styles.workspace_challenge__card}>
          <div onClick={handlePreview}>
            <div className={styles.workspace_challenge__card_header}>
              <p className={styles.workspace_challenge__card_header_text}>
                {form?.formTitle ? form?.formTitle : form?.sections[0].title}
              </p>
            </div>
            <div
              onClick={handlePreview}
              className={styles.workspace_challenge__card_desc}
            >
              {form?.description}
            </div>
          </div>

          <div className={styles.workspace_challenge__card_footer}>
            <div
              onClick={handlePreview}
              className={styles.workspace_challenge__card_footer_left}
            >
              <img
                className={styles.switch_dots_form}
                src={"/Images/Navbarimg/form-f.svg"}
                alt="icon"
              />
              <p className={styles.workspace_challenge__count}>
                {new Date(form?.updatedAt).toLocaleString("en-IN", options)}
              </p>
            </div>
            <div>
              <IconButton
                className={styles.svg_ions}
                key={index}
                onClick={handleClick}
              >
                <svg
                  width="3"
                  height="12"
                  viewBox="0 0 3 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="1.5" cy="1.5" r="1.5" fill="#444444" />
                  <circle cx="1.5" cy="6" r="1.5" fill="#444444" />
                  <circle cx="1.5" cy="10.5" r="1.5" fill="#444444" />
                </svg>
              </IconButton>
            </div>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEle}
            open={Boolean(anchorEle)}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleShow}>Add to workspace</MenuItem>
          </Menu>
        </div>
      </div>
      {show ? (
        <div
          className="position-fixed top-50 start-50 translate-middle p-4 bg-white rounded shadow d-flex flex-column align-items-center justify-content-center"
          style={{ zIndex: 1000 }}
        >
          <div
            className={classNames(
              "d-flex align-items-center justify-content-center gap-5 mb-4",
              styles.border,
            )}
          >
            <h1 style={{ fontSize: "1.2em", marginLeft: "10px" }}>
              Add template Form to Workspace
            </h1>
            <h3 onClick={() => setShow(false)}>
              <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                  clipRule="evenodd"
                />
              </svg>
            </h3>
          </div>
          <img
            src="https://task.appsdeployer.com/static/media/boardPopupLogo.14cda5dc635d1f13bc4828f5113d1e86.svg"
            alt="kanban"
          />
          <Form
            className="d-flex flex-column gap-2 w-100"
            onSubmit={handleAddToWorkspace}
          >
            <Form.Group>
              <Form.Label>
                Form Title
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                onChange={(e) => setFormTitle(e.target.value)}
                placeholder="Form Title"
                required
                value={formTitle}
                isInvalid={!formTitle.length}
              />
              <Form.Control.Feedback type="invalid">
                Form title is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Workspace</Form.Label>
              <Form.Select
                onChange={handleChangeWorkspace}
                aria-label="workspace select"
                required
                value={workspaces?.length > 0 ? workspaces?.[0]._id : ""}
              >
                <option disabled>Select any workspace</option>
                {workspaces?.map((workspace) => (
                  <option key={workspace._id} value={workspace._id}>
                    {workspace.workspaceName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </div>
      ) : null}
    </>
  );
};

export default AllTemplateCard;
