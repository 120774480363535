import React from "react";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const getRandomColor = () => {
  const colorValues = [
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
  ];
  return `rgb(${colorValues.join(",")})`;
};

const BarGraph = ({ submissions, questionId }) => {
  const answers = submissions.map((submission) => {
    const reply = submission.reply.find(
      (item) => item.questionId === questionId,
    );
    return reply ? reply.answer : null;
  });

  const answerCounts = answers.reduce((counts, answer) => {
    if (answer) {
      // counts[answer && answer?.slice(0,20)] = (counts[answer?.answer] || 0) + 1;
    }
    return counts;
  }, {});

  const data = {
    labels: Object.keys(answerCounts),
    datasets: [
      {
        label: "Answer Count",
        data: Object.values(answerCounts),
        backgroundColor: Object.values(answerCounts).map(() =>
          getRandomColor(),
        ),
        borderWidth: 1,
        tension: 0.1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, // Don't maintain w/h ratio
  };

  return (
    <div className="graphs">
      <div style={{ width: "100%", height: "auto" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarGraph;
