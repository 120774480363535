import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const HoverOverlay = (props) => {
  return (
    <OverlayTrigger
      placement={props.placement}
      overlay={<Tooltip id="text-tooltip">{props.hoverText}</Tooltip>}
    >
      {props.mainInput}
    </OverlayTrigger>
  );
};

export default HoverOverlay;
