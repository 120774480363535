import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styles from '../../css/Preview.module.css';
import { handleChoiceChange } from '../../redux/slice/previewSlice';

const MultipleChoiceQuestion = ({ _id, questionText, qImgUrl, required, isSelfPreviewPage, isQuiz, points, options, checked, formErrors, ind }) => {
  const dispatch = useDispatch();
  const [otherOptionText, setOtherOptionText] = useState('');

  return (
    <Card className={styles.question_card}>
      <Form.Group key={_id} disabled={isSelfPreviewPage} className={styles.cardChange}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="text-right w-100 mb-3">
            <div className="d-flex">
              <p className="d-flex me-1">
                {required && <span className={styles.required}>*</span>} {questionText?.length !== 0 && `${ind + 1}. `}
              </p>
              <div>{questionText}</div>
            </div>
            {qImgUrl && qImgUrl?.length !== 0 && (
              <>
                <img
                  src={qImgUrl}
                  alt=""
                  className={styles.preview_Question_image}
                />
              </>
            )}
          </div>

          {isQuiz && points && (
            <div className="text-left d-flex gap-2">
              <span>Points:</span> {points}
            </div>
          )}
        </div>

        {options.map((option, index) => (
          <React.Fragment key={option._id}>
            {option.otherOption ? (
              <div key={option._id} className="mb-3 ms-3" style={{ display: "flex" }}>
                <Form.Check
                  disabled={isSelfPreviewPage}
                  type="radio"
                  id={`${_id}-${option.option}`}
                  name={`question-${_id}`}
                  label={option.option}
                  checked={option._id === checked.optionId}
                  onChange={() =>
                    dispatch(
                      handleChoiceChange({
                        questionId: _id,
                        optionTitle: otherOptionText,
                        optionId: option._id,
                        optionIndex: index,
                      }),
                    )
                  }
                  isInvalid={formErrors.includes(
                    `Please fill the required field: ${questionText}`,
                  )}
                />
                <span>Other: </span>
                <input
                  type="text"
                  className={`${styles.text_input}`}
                  checked={option._id === checked.optionId}
                  onChange={(e) => {
                    setOtherOptionText(e.target.value);
                    dispatch(
                      handleChoiceChange({
                        questionId: _id,
                        optionTitle: e.target.value,
                        optionId: option._id,
                        optionIndex: index,
                      }),
                    );
                  }}
                />
              </div>
            ) : (
              <div key={option._id} className="mb-3 ms-3">
                <Form.Check
                  type="radio"
                  id={`${_id}-${option.option}`}
                  name={`question-${_id}`}
                  label={option.option}
                  checked={option._id === checked.optionId}
                  onChange={() =>
                    dispatch(
                      handleChoiceChange({
                        questionId: _id,
                        optionTitle: option.option,
                        optionId: option._id,
                        optionIndex: index,
                      }),
                    )
                  }
                  isInvalid={formErrors.includes(
                    `Please fill the required field: ${questionText}`,
                  )}
                />
              </div>
            )}
          </React.Fragment>
        ))}
        <Form.Control.Feedback type="invalid">
          Please fill the required field: {questionText}
        </Form.Control.Feedback>
      </Form.Group>
    </Card>
  );
};

export default MultipleChoiceQuestion;
