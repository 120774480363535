
import React, { useEffect, useState, useCallback } from "react";
import Table from "react-bootstrap/Table";
import { Button, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeaderAll from "../components/Headers/HeaderAll";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { fetchFormsData, setPageNumber } from "../redux/slice/formSlice.js";
  const FormsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);
  const formsData = useSelector((state) => state.forms.formsData);
  const loading = useSelector((state) => state.forms.loading);
  const pageNumber = useSelector((state) => state.forms.pageNumber);
  const pageCount = useSelector((state) => state.forms.pageCount);
   useEffect(() => {
    dispatch(fetchFormsData({ page_size: pageSize, page_number: pageNumber }));
  }, [dispatch, pageSize, pageNumber]);

  const handlePageChange = useCallback(
    ({ selected }) => {
      dispatch(setPageNumber(selected));
    },
    [dispatch]
  );

  const handleViewResponses = useCallback(
    (formId) => {
      navigate(`/responses/${formId}`);
    },
    [navigate]
  );

  return (
    <>
      <HeaderAll />
      <Container>
        <h1>Responses</h1>
        {loading ? (
          <Container className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Container>
        ) : (
          <>
            {!formsData || formsData.length === 0 ? (
              <p>No data Found</p>
            ) : (
              <>
                <div style={{ maxWidth: "900px" }}>
                  {formsData.map((form) => (
                    <div className="mt-4" key={form._id}>
                      <div className="d-flex gap-2 mb-2">
                        <h2>Form: {form.formTitle}</h2>
                        <Button onClick={() => handleViewResponses(form._id)}>
                          View Responses
                        </Button>
                      </div>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Question</th>
                            <th>Type</th>
                            <th>Required</th>
                          </tr>
                        </thead>
                        <tbody>
                          {form.questions.map((question) => (
                            <tr key={question._id}>
                              <td>{question.question}</td>
                              <td>{question.type}</td>
                              <td>{question.required ? "Yes" : "No"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </div>
                <div className="w-100 d-flex justify-content-center mt-4">
                  <ReactPaginate
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    forcePage={pageNumber}
                    previousLabel={<span aria-hidden="true">&laquo;</span>}
                    nextLabel={<span aria-hidden="true">&raquo;</span>}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    breakClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    activeLinkClassName="active"
                    activeStyle={{ color: "#7349bd" }}
                    disabledClassName="disabled"
                    breakLabel="..."
                    disableInitialCallback={true}
                    previousClassNameDisabled="disabled"
                    nextClassNameDisabled="disabled"
                    previousLinkClassNameDisabled="page-link disabled"
                    nextLinkClassNameDisabled="page-link disabled"
                    ariaLabelBuilder={(page) => `Page ${page}`}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};
export default FormsTable;
