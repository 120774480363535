import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import HoverOverlay from "../HoverOverlay";
import styles from "./sidebar.module.css";

const Sidebar = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const locationObj = useLocation();
  const activePath = locationObj.pathname;
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setIsSidebarOpen(false);
    localStorage.setItem("sidebaropen", false);
  }, [setIsSidebarOpen]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
    setIsSidebarOpen(true);
    localStorage.setItem("sidebaropen", true);
  }, [setIsSidebarOpen]);

  return (
    <div
      className={classNames(
        styles.sidebar,
        isOpen ? styles.open : styles.closed,
      )}
      style={{
        backgroundColor: "#ffffff",
        color: "#fff",
        border: "none none none 1px solid none",
      }}
    >
      <ul className={styles.sidebar_ul}>
        {isSidebarOpen ? (
          <HoverOverlay
            placement="right"
            hoverText="Close"
            mainInput={
              <span className={styles.sidebaropenicon} onClick={handleClose}>
                <svg
                  viewBox="0 0 320 512"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256l137.3-137.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
              </span>
            }
          />
        ) : (
          <HoverOverlay
            placement="right"
            hoverText="Open"
            mainInput={
              <span
                className={styles.sidebaricon_onisPath}
                onClick={handleOpen}
              >
                <svg
                  viewBox="0 0 320 512"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                  className={classNames(
                    styles.sidebarcaret_icons,
                    "text-black",
                  )}
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </span>
            }
          />
        )}

        <div>
          <HoverOverlay
            placement="right"
            hoverText="Workspace"
            mainInput={
              <NavLink to="/" className={styles.board_navlink}>
                <li
                  className={classNames(
                    activePath === "/" ? styles.active_navlink : "",
                    styles.sidebar_li,
                  )}
                  style={{
                    color: "#172b4d",
                  }}
                >
                  {isOpen ? (
                    <span className={styles.sidebar_heading}>
                      <svg
                        viewBox="0 0 640 512"
                        fill="currentColor"
                        height="1em"
                        width="1em"
                        className={styles.sidebarcaret_icon}
                      >
                        <path d="M211.2 96c0-35.3-28.7-64-64-64s-64 28.7-64 64 28.7 64 64 64 64-28.7 64-64zM32 256c0 17.7 14.3 32 32 32h85.6c10.1-39.4 38.6-71.5 75.8-86.6-9.7-6-21.2-9.4-33.4-9.4H96c-35.3 0-64 28.7-64 64zm461.6 32H576c17.7 0 32-14.3 32-32 0-35.3-28.7-64-64-64h-96c-11.7 0-22.7 3.1-32.1 8.6 38.1 14.8 67.4 47.3 77.7 87.4zm-102.4-61.6c-6.9-1.6-14.2-2.4-21.6-2.4h-96c-8.5 0-16.7 1.1-24.5 3.1-30.8 8.1-55.6 31.1-66.1 60.9-3.5 10-5.5 20.8-5.5 32 0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32 0-11.2-1.9-22-5.5-32-10.8-30.7-36.8-54.2-68.9-61.6zM563.2 96c0-35.3-28.7-64-64-64s-64 28.7-64 64 28.7 64 64 64 64-28.7 64-64zm-241.6 96c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zM32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32h576c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z" />
                      </svg>
                      Workspace
                    </span>
                  ) : (
                    <>
                      <svg
                        viewBox="0 0 640 512"
                        fill="currentColor"
                        height="1em"
                        width="1em"
                        className={styles.sidebarcaretclose_icon}
                      >
                        <path d="M211.2 96c0-35.3-28.7-64-64-64s-64 28.7-64 64 28.7 64 64 64 64-28.7 64-64zM32 256c0 17.7 14.3 32 32 32h85.6c10.1-39.4 38.6-71.5 75.8-86.6-9.7-6-21.2-9.4-33.4-9.4H96c-35.3 0-64 28.7-64 64zm461.6 32H576c17.7 0 32-14.3 32-32 0-35.3-28.7-64-64-64h-96c-11.7 0-22.7 3.1-32.1 8.6 38.1 14.8 67.4 47.3 77.7 87.4zm-102.4-61.6c-6.9-1.6-14.2-2.4-21.6-2.4h-96c-8.5 0-16.7 1.1-24.5 3.1-30.8 8.1-55.6 31.1-66.1 60.9-3.5 10-5.5 20.8-5.5 32 0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32 0-11.2-1.9-22-5.5-32-10.8-30.7-36.8-54.2-68.9-61.6zM563.2 96c0-35.3-28.7-64-64-64s-64 28.7-64 64 28.7 64 64 64 64-28.7 64-64zm-241.6 96c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zM32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32h576c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z" />
                      </svg>
                    </>
                  )}
                </li>
              </NavLink>
            }
          />

          <HoverOverlay
            placement="right"
            hoverText="Recent"
            mainInput={
              <NavLink to="/recent" className={styles.board_navlink}>
                <li
                  className={classNames(
                    activePath === "/recent" ? styles.active_navlink : "",
                    styles.sidebar_li,
                  )}
                  style={{
                    color: "#172b4d",
                  }}
                >
                  {isOpen ? (
                    <span className={styles.sidebar_heading}>
                      <svg
                        viewBox="0 0 940 1000"
                        fill="currentColor"
                        height="1em"
                        width="1em"
                        className={styles.sidebarcaret_icon}
                      >
                        <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
                      </svg>{" "}
                      Recent
                    </span>
                  ) : (
                    <svg
                      viewBox="0 0 940 1000"
                      fill="currentColor"
                      height="1em"
                      width="1em"
                      className={styles.sidebarcaretclose_icon}
                    >
                      <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
                    </svg>
                  )}
                </li>
              </NavLink>
            }
          />

          <HoverOverlay
            placement="right"
            hoverText="Template"
            mainInput={
              <NavLink to="/template" className={styles.board_navlink}>
                <li
                  className={classNames(
                    activePath === "/template" ? styles.active_navlink : "",
                    styles.sidebar_li,
                  )}
                  style={{
                    color: "#172b4d",
                  }}
                >
                  {isOpen ? (
                    <>
                      <span className={styles.sidebar_heading}>
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          className={styles.sidebarcaret_icon}
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 1.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-1zm-5 0A1.5 1.5 0 016.5 0h3A1.5 1.5 0 0111 1.5v1A1.5 1.5 0 019.5 4h-3A1.5 1.5 0 015 2.5v-1zm-2 0h1v1A2.5 2.5 0 006.5 5h3A2.5 2.5 0 0012 2.5v-1h1a2 2 0 012 2V14a2 2 0 01-2 2H3a2 2 0 01-2-2V3.5a2 2 0 012-2z"
                          />
                        </svg>{" "}
                        Template
                      </span>
                    </>
                  ) : (
                    <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      className={styles.sidebarcaretclose_icon}
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 1.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-1zm-5 0A1.5 1.5 0 016.5 0h3A1.5 1.5 0 0111 1.5v1A1.5 1.5 0 019.5 4h-3A1.5 1.5 0 015 2.5v-1zm-2 0h1v1A2.5 2.5 0 006.5 5h3A2.5 2.5 0 0012 2.5v-1h1a2 2 0 012 2V14a2 2 0 01-2 2H3a2 2 0 01-2-2V3.5a2 2 0 012-2z"
                      />
                    </svg>
                  )}
                </li>
              </NavLink>
            }
          />
        </div>

        <HoverOverlay
          placement="right"
          hoverText="Trash"
          mainInput={
            <NavLink to="/trash" className={styles.board_navlink}>
              <li
                className={
                  activePath === "/trash"
                    ? styles.active_navlink
                    : styles.sidebar_li
                }
                style={{
                  color: "#172b4d",
                }}
              >
                {isOpen ? (
                  <span className={styles.sidebar_heading}>
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="1em"
                      width="1em"
                      className={styles.sidebarcaret_icon}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                    </svg>
                    Trash
                  </span>
                ) : (
                  <>
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="1em"
                      width="1em"
                      className={styles.sidebarcaretclose_icon}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                    </svg>
                  </>
                )}
              </li>
            </NavLink>
          }
        />

        {/* 
        <HoverOverlay
          placement="right"
          hoverText="Resources"
          mainInput={
            <NavLink to="/resources" className={styles.board_navlink}>
              <li
                className={
                  activePath === "/resources"
                    ? styles.active_navlink
                    : styles.sidebar_li
                }
                style={{
                  color:
                    ispath ||
                    selectedBoard?.boardColor === "#ffffff" ||
                    selectedBoard?.boardColor === ""
                      ? "#172b4d"
                      : "white",
                }}
              >
                {isOpen ? (
                  <span
                    className={
                      locationPath[1] === "boards"
                        ? styles.heading_boardPage
                        : styles.sidebar_heading
                    }
                  >
                    <svg
                      height="1em"
                      width="1em"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.sidebarcaret_icon}
                    >
                      <path
                        d="M8.625 4.97917L11.25 6.29167L6 8.91667L0.75 6.29167L3.375 4.97917M8.625 7.89583L11.25 9.20833L6 11.8333L0.75 9.20833L3.375 7.89583M6 0.75L11.25 3.375L6 6L0.75 3.375L6 0.75Z"
                        stroke={
                          ispath ||
                          selectedBoard?.boardColor === "#ffffff" ||
                          selectedBoard?.boardColor === ""
                            ? "#172b4d"
                            : "white"
                        }
                        fill="none"
                      ></path>
                    </svg>{" "}
                    Resources
                  </span>
                ) : (
                  <>
                    <svg
                      height="1em"
                      width="1em"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={styles.sidebarcaretclose_icon}
                    >
                      <path
                        d="M8.625 4.97917L11.25 6.29167L6 8.91667L0.75 6.29167L3.375 4.97917M8.625 7.89583L11.25 9.20833L6 11.8333L0.75 9.20833L3.375 7.89583M6 0.75L11.25 3.375L6 6L0.75 3.375L6 0.75Z"
                        stroke={
                          ispath ||
                          selectedBoard?.boardColor === "#ffffff" ||
                          selectedBoard?.boardColor === ""
                            ? "#172b4d"
                            : "white"
                        }
                        fill="none"
                      ></path>
                    </svg>
                  </>
                )}
              </li>
            </NavLink>
          }
        /> */}
      </ul>
    </div>
  );
};

export default Sidebar;
