import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../service/url";
import axios from "axios";

export const getTemplateCategories = createAsyncThunk(
  "forms/templateCategories",
  async () => {
    try {
      const response = await axios.get(
        `${API_URL}/form/prefilledFormTemplate/category/`,
      );
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
);

export const getTemplatesOfCategory = createAsyncThunk(
  "forms/templates",
  async (categoryId) => {
    try {
      const response = await axios.get(
        `${API_URL}/form/prefilledFormTemplate/form/getFormByCategoryId/${categoryId}`,
      );
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
);

export const getTemplateForm = createAsyncThunk(
  "forms/templateForm",
  async (formId) => {
    try {
      const response = await axios.get(
        `${API_URL}/form/prefilledFormTemplate/form/getFormById/${formId}`,
      );
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
);

const initalState = {
  templateCategories: [],
  templates: [],
  loading: false,
  formData: {},
  currentCategory: "",
};

const templateSlice = createSlice({
  name: "template",
  initialState: initalState,
  reducers: {
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setCategories: (state, action) => {
      state.templateCategories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplateCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplateCategories.rejected, (state) => {
      state.loading = false;
      state.templateCategories = [];
    });
    builder.addCase(getTemplateCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.templateCategories = action.payload.data;
    });
    builder.addCase(getTemplatesOfCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplatesOfCategory.rejected, (state) => {
      state.loading = false;
      state.templates = [];
    });
    builder.addCase(getTemplatesOfCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.templates = action.payload.data;
      state.currentCategory = action.payload.category;
    });
    builder.addCase(getTemplateForm.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getTemplateForm.rejected, (state) => {
      state.loading = false;
      state.formData = {};
    });
    builder.addCase(getTemplateForm.fulfilled, (state, action) => {
      state.loading = false;
      state.formData = action.payload.data;
    });
  },
});

export const { setTemplates, setCategories } = templateSlice.actions;
export default templateSlice.reducer;
