import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { createForm } from "../../redux/slice/formSlice";
import styles from "./addForm.module.css";

function AddForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [formType, setFormType] = useState("normal");
  const [isQuiz, setIsQuiz] = useState(false);

  const loading = useSelector((store) => store.forms.loading);

  const alert = useAlert();
  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const data = {
        workspaceId,
        formTitle,
        formDescription,
        formType,
        isQuiz,
        timer: "00:00",
        sections: [],
      };

      dispatch(createForm(data)).then((res) => {
        if (res.payload.success) {
          alert.success(res.payload.message);
          navigate(`/editForm/${res.payload.formId}`, {
            state: { formType },
          });
        }
      });
    },
    [
      workspaceId,
      formTitle,
      formDescription,
      formType,
      dispatch,
      navigate,
      alert,
      isQuiz,
    ],
  );

  return (
    <div className="userboard">
      <Container
        style={{ marginTop: "1rem" }}
        className="h-screen flex justify-center items-center"
      >
        <div className="mb-4">
          <h2>Workspace Name</h2>
        </div>

        <div className={styles["main-container"]}>
          <Form className={styles["my-form"]} onSubmit={handleFormSubmit}>
            <h2>Form Details</h2>
            <Form.Group controlId="formName" className="mb-1">
              <Form.Label>
                Form Title <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                placeholder="Enter form title"
                required
                value={formTitle}
                onChange={(e) => setFormTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formDescription" className="mb-1">
              <Form.Label>Form Description</Form.Label>
              <Form.Control
                className="form-input"
                as="textarea"
                placeholder="Enter description"
                rows={3}
                value={formDescription}
                onChange={(e) => setFormDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formType" className="mb-1">
              <Form.Label>Form Type</Form.Label>
              <Form.Select
                onChange={(e) => setFormType(e.target.value)}
                value={formType}
              >
                <option value="normal">Normal</option>
                <option value="survey">Survey</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                checked={isQuiz}
                onChange={() => setIsQuiz((prev) => !prev)}
                label="Quiz"
              />
            </Form.Group>

            <div className="text-center">
              <button
                disabled={loading}
                className={styles["form-btn"]}
                type="submit"
              >
                Next
              </button>
            </div>
          </Form>
          <img
            className={styles["placeholder-image"]}
            src="https://via.placeholder.com/400"
            alt="placeholder"
          />
        </div>
      </Container>
    </div>
  );
}

export default AddForm;
