import Rating from "@mui/material/Rating";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Container, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import styles from "../css/SelfPreview.module.css";
import Loader from "../loader";

import { getTemplateForm } from "../redux/slice/templateSlice";

const TemplatePreview = () => {
  const dispatch = useDispatch();

  const { formId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [show, setShow] = useState(false);

  const { loading, formData } = useSelector((data) => data.template);

  useEffect(() => {
    const pathParts = window.location.pathname.split("/");
    if (pathParts.includes("preview")) {
      setShow(true);
    }
    dispatch(getTemplateForm(formId));
  }, [dispatch, formId]);

  const renderQuestion = useCallback((questions) => {
    const { id, type, question, required, options, qImgUrl } = questions;
    switch (type) {
      case "text":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question?.length !== 0 && question}
                {qImgUrl && qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}

                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                // className="text-input short-text-input"
                className={`${styles.text_input} ${styles.short_text_input}`}
                type={type}
                required={required}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );

      case "short_answer":
      case "paragraph":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question?.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                className={styles.text_input}
                type={type}
                required={required}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );

      case "address":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                className={styles.text_input}
                type={type}
                required={required}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );
      case "phone":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                className={styles.text_input}
                type={type}
                required={required}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );
      case "date":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                className={styles.date_input}
                type={type}
                required={required}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );

      case "time":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                className={styles.time_input}
                type={type}
                required={required}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );
      case "rating":
        return (
          <Card className={styles.question_card}>
            {question}
            <Rating name="size-large" defaultValue={0} precision={0.5} />
          </Card>
        );
      case "checkbox":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              {options.map((option, index) => (
                <div key={index} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={`${id}-${option.option}`}
                    label={option.option}
                    //   checked={
                    //     submissionData.reply.find(
                    //       (item) =>
                    //         item.questionId === id &&
                    //         Array.isArray(item.answer) &&
                    //         item.answer.some(
                    //           (selectedOption) =>
                    //             selectedOption.optionId === option.id,
                    //         ),
                    //     ) !== undefined
                    //   }
                  />
                </div>
              ))}
              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );

      case "multiple_choice":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              {options.map((option, index) => (
                <>
                  {option.otherOption ? (
                    <>
                      <div
                        key={index}
                        className={"mb-3 "}
                        style={{ display: "flex" }}
                      >
                        <Form.Check
                          type="radio"
                          id={`${id}-${option.option}`}
                          name={`question-${id}`}
                          label={option.option}
                        />
                        <span>Other: </span>
                        <input type="text" className={`${styles.text_input}`} />
                      </div>
                    </>
                  ) : (
                    <div key={index} className="mb-3">
                      <Form.Check
                        type="radio"
                        id={`${id}-${option.option}`}
                        name={`question-${id}`}
                        label={option.option}
                      />
                    </div>
                  )}
                </>
              ))}
              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );

      case "file_upload":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                className="file-upload"
                type="file"
                required={required}
              />
              <Form.Control.Feedback type="invalid" />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
          </Card>
        );

      case "dropdown":
        return (
          <Card className={styles.question_card}>
            <Form.Group key={id}>
              <Form.Label className="mb-3">
                {question.length !== 0 && question}
                {qImgUrl?.length !== 0 && (
                  <>
                    <img
                      src={qImgUrl}
                      alt=""
                      className={styles.preview_Question_image}
                    />
                  </>
                )}
                {required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Select className="dropdown" required={required}>
                <option value="">Choose</option>
                {options.map((option, index) => (
                  <option key={index}>{option.option}</option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                Please fill the required field: {question}
              </Form.Control.Feedback>
            </Form.Group>
          </Card>
        );

      default:
        return null;
    }
  }, []);

  // ================

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const handleNextQuestion = () => {
    const currentSection = formData?.sections?.[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentSectionIndex < formData?.sections?.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
      setCurrentQuestionIndex(0); // Start from the first question of the new section
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
      setCurrentQuestionIndex(
        formData?.sections?.[currentSectionIndex - 1].questions.length - 1,
      );
    }
  };

  const renderCurrentQuestion = () => {
    const currentSection = formData?.sections?.[currentSectionIndex];
    const currentQuestion = currentSection?.questions[currentQuestionIndex];

    return currentQuestion ? renderQuestion(currentQuestion) : null;
  };
  const isLastQuestionInSection =
    currentQuestionIndex ===
    formData?.sections?.[currentSectionIndex]?.questions?.length - 1;
  const isLastSection = currentSectionIndex === formData?.sections?.length - 1;

  const nextButtonText =
    isLastSection && isLastQuestionInSection
      ? formData?.submitButtonText
      : "Next";

  return (
    <Container className={classNames(styles.preview_container, "mt-5")}>
      {loading ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <>
          <Link to={`/templateCategory/${formData?.categoryId}`}>
            <Button className="mb-2">Back</Button>
          </Link>
          <Card className={styles.preview_header}>
            <Card.Body>
              <Card.Title className={styles.preview_title}>
                <h1>{formData?.sections?.[0]?.title}</h1>
              </Card.Title>
              <Card.Text>{formData?.sections?.[0]?.description}</Card.Text>
            </Card.Body>
            <Card.Footer style={{ background: "transparent", color: "red" }}>
              * Indicates required question
            </Card.Footer>
          </Card>

          <Form>
            <Card className={styles.question_card}>
              <Form.Group>
                <Form.Label className="mb-3">
                  Email
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  // className="text-input short-text-input"
                  className={`${styles.text_input} ${styles.short_text_input}`}
                  type="email"
                  required

                  //   value={submissionData?.user}
                />

                <Form.Control.Feedback type="invalid">
                  Please fill the correct email.
                </Form.Control.Feedback>
              </Form.Group>
            </Card>

            {formData?.formType === "normal" ? (
              <>
                {formData?.sections?.map((section, sectionIndex) => (
                  <>
                    {sectionIndex > 0 && <h6>Section {sectionIndex + 1}</h6>}
                    {section?.questions?.map((question) =>
                      renderQuestion(question),
                    )}
                  </>
                ))}
              </>
            ) : (
              <>
                {formData?.sections?.length > 1 &&
                  currentSectionIndex !== 0 && (
                    <h6 className={styles.section_indicator}>
                      section {currentSectionIndex + 1}:{" "}
                      {formData?.sections[currentSectionIndex].title}
                    </h6>
                  )}

                {renderCurrentQuestion()}
                <div className={styles.navigationButtons}>
                  <Button
                    onClick={handlePreviousQuestion}
                    disabled={
                      currentQuestionIndex === 0 && currentSectionIndex === 0
                    }
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={handleNextQuestion}
                    // disabled={
                    //   currentQuestionIndex ===
                    //     sections[currentSectionIndex].questions.length -
                    //       1 && currentSectionIndex === sections.length - 1
                    // }
                  >
                    {nextButtonText}
                  </Button>
                </div>
              </>
            )}

            {formData?.formData?.formType === "normal" && (
              <div>
                {isSubmitting ? (
                  <Button className="disabled">
                    <Spinner animation="border" className="me-1" size="sm" />{" "}
                    {formData?.submitButtonText
                      ? formData?.submitButtonText
                      : "Submit"}
                  </Button>
                ) : (
                  <Button>
                    {formData?.submitButtonText
                      ? formData?.submitButtonText
                      : "Submit"}
                  </Button>
                )}
              </div>
            )}
          </Form>
        </>
      )}
    </Container>
  );
};

export default TemplatePreview;
