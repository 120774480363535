import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, cookieToken } from "../../service/url";

export const fetchFormsData = createAsyncThunk(
  "forms/fetchFormsData",
  async (payload) => {
    try {
      const res = await axios.post(`${API_URL}/form/form/all`, payload, {
        headers: {
          "x-access-token": cookieToken,
        },
      });

      return res.data;
    } catch (error) {
      console.error("Error fetching forms data:", error);
      return { success: false };
    }
  },
);

export const deleteFormsData = createAsyncThunk(
  "forms/deleteFormsData",
  async (payload) => {
    try {
      const res = await axios.put(
        `${API_URL}/form/form/delete/${payload}`,
        {},
        {
          headers: {
            "x-access-token": cookieToken,
          },
        }
      );

      return res;
    } catch (error) {
      console.error("Error fetching forms data:", error);
      return { success: false };
    }
  }
);

export const sendFormLink = createAsyncThunk(
  "forms/sendFormLink",
  async (payload) => {
    try {
      const res = await axios.post(
        `${API_URL}/form/form/sendFormLink`,
        payload,
        {
          headers: {
            "x-access-token": cookieToken,
          },
        },
      );

      return res;
    } catch (error) {
      console.error("Error fetching forms data:", error);
      return { success: false };
    }
  },
);

export const sendFormImage = createAsyncThunk(
  "forms/sendFormImage",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_URL}/form/form/image/${payload.formId}`,
        payload.data,
        {
          headers: {
            "x-access-token": cookieToken,
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error sending form image:", error);
      return { success: false };
    }
  },
);

export const createForm = createAsyncThunk(
  "forms/createForm",
  async (payload) => {
    try {
      const response = await axios.post(`${API_URL}/form/form/add`, payload, {
        headers: {
          "x-access-token": cookieToken,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating form:", error);
      return { success: false };
    }
  },
);

export const editForm = createAsyncThunk("forms/editForm", async (payload) => {
  try {
    const response = await axios.put(
      `${API_URL}/form/form/edit/${payload.formId}`,
      payload.formData,
      {
        headers: {
          "x-access-token": cookieToken,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error editing form:", error);
    return { success: false };
  }
});

export const updateRecentVisitedForm = createAsyncThunk(
  "form/form/updateRecentVisitedForm",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_URL}/form/form/updateRecentVisitedForm/${payload.formId}`,
        payload.formData,
        {
          headers: {
            "x-access-token": cookieToken,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error updating recent visited form", error);
      return { success: false };
    }
  },
);

export const getRecentVisitedForms = createAsyncThunk(
  "forms/getRecentForms",
  async () => {
    try {
      const response = await axios.get(
        `${API_URL}/form/form/getRecentVisitedform/`,
        {
          headers: {
            "x-access-token": cookieToken,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching the recent forms", error);
      return { success: false };
    }
  },
);

export const getAllTrashForms = createAsyncThunk(
  "forms/getAllTrashForms",
  async () => {
    try {
      const response = await axios.get(
        `${API_URL}/form/form/getAllTrashForms/`,
        {
          headers: {
            "x-access-token": cookieToken,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching the recent forms", error);
      return { success: false };
    }
  },
);

export const restoreForm = createAsyncThunk(
  "forms/restoreForm",
  async (formId) => {
    try {
      const response = await axios.put(
        `${API_URL}/form/form/restoreForm/${formId}`,
        null,  // No data payload
        {
          headers: {
            "x-access-token": cookieToken,
          },
        },
      );
      console.log(response?.data?.data?.formStatus)
      return response.data;
    } catch (error) {
      console.error("Error restoring the form", error);
      return { success: false };
    }
  },
);

export const deleteFormsDataPerm = createAsyncThunk(
  "forms/deleteFormPerm",
  async (payload) => {
    try {
      const res = await axios.delete(`${API_URL}/form/form/deleteFormPerm/${payload}`, {
        headers: {
          "x-access-token": cookieToken,
        },
      });

      return res;
    } catch (error) {
      console.error("Error fetching forms data:", error);
      return { success: false };
    }
  },
);

const formSlice = createSlice({
  name: "forms",
  initialState: {
    formsData: [],
    loading: true,
    pageSize: 12,
    pageNumber: 1,
    pageCount: 0,
    total_data: 0,
    responseStatus: null,
    formId: null,
    recentForms: [],
    trashForms: []
    // responseData: null,
  },
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setRespnseStatus: (state) => {
      state.responseStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFormsData.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.formsData = action.payload.data;
          state.formsData.sort(
            (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
          );

          state.total_data = action.payload.metadata.total_data;
          state.pageCount = Math.ceil(
            action.payload.metadata.total_data / state.pageSize,
          );
        }
        state.loading = false;
      })
      .addCase(fetchFormsData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteFormsData.pending, (state) => {
        state.responseStatus = null;
      })
      .addCase(deleteFormsData.fulfilled, (state, action) => {
        if (action.payload.status == 200) {
          console.log("Action reps", action.payload.status);
          state.responseStatus = action.payload.status;
        }
      })
      .addCase(deleteFormsData.rejected, (state) => {
        state.responseStatus = null;
      })

      // For image
      .addCase(sendFormImage.pending, (state) => {
        state.responseStatus = null;
      })
      .addCase(sendFormImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.responseStatus = action.payload.success;
        }
      })
      .addCase(sendFormImage.rejected, (state) => {
        state.responseStatus = null;
      })

      .addCase(createForm.pending, (state) => {
        state.loading = true;
        state.responseStatus = null;
      })
      .addCase(createForm.fulfilled, (state, action) => {
        state.loading = false;
        state.responseStatus = action.payload.message;
        state.formId = action.payload.formId;
      })
      .addCase(createForm.rejected, (state) => {
        state.loading = false;
        state.responseStatus = null;
      })
      .addCase(editForm.pending, (state) => {
        state.responseStatus = null;
      })
      .addCase(editForm.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.responseStatus = action.payload.message;
          // You can update other relevant state properties if needed
        }
      })
      .addCase(editForm.rejected, (state) => {
        state.responseStatus = null;
      })
      // updateRecentVisitedForm
      .addCase(updateRecentVisitedForm.pending, (state) => {
        state.responseStatus = null;
      })
      .addCase(updateRecentVisitedForm.rejected, (state) => {
        state.responseStatus = null;
      })
      .addCase(updateRecentVisitedForm.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.responseStatus = action.payload.message;
        }
      })
      // get recent visited forms
      .addCase(getRecentVisitedForms.rejected, (state) => {
        state.responseStatus = null;
      })
      .addCase(getRecentVisitedForms.pending, (state) => {
        state.responseStatus = null;
      })
      .addCase(getRecentVisitedForms.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.recentForms = action.payload.data;
        }
      })
      // get trash forms
      .addCase(getAllTrashForms.rejected, (state) => {
        state.responseStatus = null;
      })
      .addCase(getAllTrashForms.pending, (state) => {
        state.responseStatus = null;
      })
      .addCase(getAllTrashForms.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.trashForms = action.payload.data;
        }
      })
      // restore form
      .addCase(restoreForm.rejected, (state) => {
        state.responseStatus = null;
      })
      .addCase(restoreForm.pending, (state) => {
        state.responseStatus = null;
      })
      .addCase(restoreForm.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.responseStatus = action.payload.success;
        }
      })
  },
});

export const { setPageNumber, setRespnseStatus, setFormData } =
  formSlice.actions;

export default formSlice.reducer;
