import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import "../../css/AllForms.css";
import Loader from "../../loader.js";
import {
  deleteFormsDataPerm,
  getAllTrashForms,
  restoreForm,
  setPageNumber,
} from "../../redux/slice/formSlice.js";
import { useAlert } from "react-alert";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FormDeletePopup from "./FormDeletePopup.jsx";
import {
  DeleteTrashedWorkspace,
  getTrashedWorkspace,
  restoreTrashedWorkspace,
} from "../../redux/action/workspace.js";

const AllTrashForms = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const loading = useSelector((state) => state.forms.loading);
  const pageNumber = useSelector((state) => state.forms.pageNumber);
  const pageCount = useSelector((state) => state.forms.pageCount);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenId, setIsOpenId] = useState(null);
  const profile = useSelector((state) => state?.profile);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const trashForms = useSelector((state) => state.forms.trashForms);
  const trashedWorkspace = useSelector(
    (state) => state?.workspace?.trashedWorkspace,
  );

  // Fetch initial data
  useEffect(() => {
    dispatch(getAllTrashForms());
    dispatch(getTrashedWorkspace());
  }, [dispatch]);

  // Reset current page when component mounts
  useEffect(() => {
    setCurrentPage(1);
  }, []);

  // Calculate total pages and items for trashedWorkspace
  const totalItemsWorkspace = trashedWorkspace?.length || 0;
  const totalPagesWorkspace = Math.ceil(totalItemsWorkspace / itemsPerPage);

  // Slice data based on current page for trashedWorkspace
  const startIndexWorkspace = (currentPage - 1) * itemsPerPage;
  const endIndexWorkspace = startIndexWorkspace + itemsPerPage;
  const currentDataWorkspace =
    trashedWorkspace?.slice(startIndexWorkspace, endIndexWorkspace) || [];
  console.log(currentDataWorkspace);

  // Calculate total pages and items for trashForms
  const totalItemsForms = trashForms?.length || 0;
  const totalPagesForms = Math.ceil(totalItemsForms / itemsPerPage);

  // Slice data based on current page for trashForms
  const startIndexForms = (currentPage - 1) * itemsPerPage;
  const endIndexForms = startIndexForms + itemsPerPage;
  const currentDataForms =
    trashForms?.slice(startIndexForms, endIndexForms) || [];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // function to delete form permanently
  const handleDeletePerm = useCallback(
    async (id) => {
      try {
        const response = await dispatch(deleteFormsDataPerm(id)).then(() => {
          dispatch(getAllTrashForms());
          alert.success("Form deleted successfully");
        });
      } catch (error) {
        console.error("Error deleting form:", error);
      }
    },
    [dispatch],
  );

  const handleWorkspacePerm = useCallback(
    async (id) => {
      console.log(id);
      try {
        const response = await dispatch(DeleteTrashedWorkspace(id)).then(() => {
          dispatch(getAllTrashForms());
          alert.success("Form deleted successfully");
        });
      } catch (error) {
        console.error("Error deleting form:", error);
      }
    },
    [dispatch],
  );

  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [isWorkspaceId, setIsWorkspaceId] = useState(null);

  const handleRestoreWorkspace = useCallback((workspaceId, userId) => {
    // logic
    dispatch(restoreTrashedWorkspace(workspaceId, userId));
  }, []);

  const handleSelectWorkspace = useCallback(
    (workspace) => {
      setShowPopup(!showPopup);
      // dispatch(setSelectedBoard(workspace));
      const workspaceId = workspace?._id;
      navigate(`/workspace/${workspaceId}`);
    },
    [dispatch, navigate, showPopup],
  );
  const [leaveDeletePopup, setLeaveDeletePopup] = useState(false);
  const [deletePopupPerm, setDeletePopupPerm] = useState(false);
  const [leaveDeleteData, setLeaveDeleteData] = useState([]);
  const [category, setCategory] = useState(null);
  const [id, setId] = useState(null);

  const handleLeaveDeletePopup = (workspace, category) => {
    setCategory(category);
    setLeaveDeleteData(workspace);
    setLeaveDeletePopup((prev) => !prev);
  };

  const handleOpen = (workspace) => {
    setIsWorkspaceId(workspace?._id);
    if (workspace?._id === isOpenId) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(true);
      setIsOpenId(workspace?._id);
    }
  };

  // Function to restore form
  const handleRestoreForm = useCallback((id) => {
    // logic to restore form
    dispatch(restoreForm(id)).then((res) => {
      if (res?.payload?.data?.formStatus === false) {
        alert.show("Please ask owner to restore the form");
      } else {
        alert.success("Form restored successfully");
        dispatch(getAllTrashForms());
      }
    });
  });
  const [activeTab, setActiveTab] = useState("recentWorkspace");
  const handleAllWorkspaceClick = useCallback(() => {
    setActiveTab("recentWorkspace");
    setCurrentPage(1);
    setItemPerPage(10);
  }, []);

  const handleMyWorkspaceClick = useCallback(() => {
    setActiveTab("allworkspace");
    setCurrentPage(1);
    setItemPerPage(10);
  }, []);

  const [search, setSearch] = useState("");
  const filteredForms = search
    ? trashForms.filter((form) =>
        form.sections[0].title.toLowerCase().includes(search.toLowerCase()),
      )
    : trashForms;

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsOpenId(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <Container>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="feed">
              <div className="feed__inputContainer">
                {/* Tabs */}
                <div className="tabsand_btn">
                  <div className="tab-container">
                    <div
                      className={`tab ${
                        activeTab === "recentWorkspace" ? "active" : ""
                      }`}
                      onClick={handleAllWorkspaceClick}
                    >
                      TRASH WORKSPACES
                    </div>
                    <div
                      className={`tab ${
                        activeTab === "allworkspace" ? "active" : ""
                      }`}
                      onClick={handleMyWorkspaceClick}
                    >
                      TRASH FORMS
                    </div>
                  </div>
                </div>

                {/* Workspace Trash */}
                {activeTab === "recentWorkspace" && (
                  <div
                    className={`tab-content recentWorkspace ${
                      activeTab === "recent" ? "active" : ""
                    }`}
                  >
                    <Table
                      style={{ border: "1px solid red" }}
                      hover
                      className="no-border"
                    >
                      <thead className="table_heading">
                        <tr className="curved-border">
                          <th>Workspaces</th>
                          <th>Owner</th>
                          <th>Date Created</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="table_body">
                        <tr style={{ width: "100%", height: "7px" }}></tr>
                        {currentDataWorkspace.length > 0 ? (
                          currentDataWorkspace.map((workspace, key) => (
                            <tr key={key} className="table_row">
                              <td>
                                {workspace?.workspaceName.split(" ")[0].length >
                                10
                                  ? `${workspace?.workspaceName
                                      .split(" ")[0]
                                      .substring(0, 10)}...`
                                  : workspace?.workspaceName}
                              </td>
                              <td>{workspace?.createdBy?.username}</td>
                              <td>
                                {moment(workspace?.createdAt).format("ll")}
                              </td>
                              <td className="doted_icon">
                                <svg
                                  viewBox="0 0 128 512"
                                  fill="currentColor"
                                  height="1em"
                                  width="1em"
                                  onClick={() => handleOpen(workspace)}
                                  className="ellipsis_td"
                                >
                                  <path d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm56-104c0 30.9-25.1 56-56 56S8 126.9 8 96s25.1-56 56-56 56 25.1 56 56z" />
                                </svg>
                                {isOpen && isOpenId === workspace?._id && (
                                  <>
                                    <ul
                                      className="table_popup"
                                      ref={dropdownRef}
                                    >
                                      <li
                                        onClick={() =>
                                          handleRestoreWorkspace(
                                            workspace._id,
                                            profile?.userId,
                                          )
                                        }
                                      >
                                        <svg
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill="#000000"
                                            d="M13 3a9 9 0 0 0-9 9H1l3.89 3.89l.07.14L9 12H6a7 7 0 0 1 7-7a7 7 0 0 1 7 7a7 7 0 0 1-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.896 8.896 0 0 0 13 21a9 9 0 0 0 9-9a9 9 0 0 0-9-9"
                                          />
                                        </svg>{" "}
                                        Restore
                                      </li>
                                      {workspace?.createdBy?._id ===
                                      profile?.userId ? (
                                        <li
                                          onClick={() =>
                                            handleLeaveDeletePopup(
                                              workspace,
                                              "workspace",
                                            )
                                          }
                                        >
                                          <svg
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            height="1em"
                                            width="1em"
                                            style={{ marginRight: ".5rem" }}
                                          >
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                                          </svg>
                                          Delete
                                        </li>
                                      ) : (
                                        <li
                                          onClick={() =>
                                            handleLeaveDeletePopup(
                                              workspace,
                                              "workspace",
                                            )
                                          }
                                        >
                                          <svg
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            height="1em"
                                            width="1em"
                                            style={{ marginRight: ".5rem" }}
                                          >
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                                          </svg>
                                          Leave
                                        </li>
                                      )}
                                    </ul>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="3"
                              style={{
                                textAlign: "center",
                                backgroundColor: "white",
                              }}
                            >
                              No workspaces found in trash.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {totalPagesWorkspace > 1 && (
                      <div className="pagination">
                        {currentPage > 1 && (
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            Previous
                          </button>
                        )}
                        {Array.from(
                          { length: totalPagesWorkspace },
                          (_, index) => index + 1,
                        ).map((pageNumber) => (
                          <button
                            key={pageNumber}
                            className={
                              pageNumber === currentPage ? "active" : ""
                            }
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        ))}
                        {currentPage < totalPagesWorkspace && (
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            Next
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* Form Trash */}
                {activeTab === "allworkspace" && (
                  <>
                    <div
                      className={`tab-content allworkspace ${
                        activeTab === "workspaces" ? "active" : ""
                      }`}
                    >
                      <Table hover className="no-border">
                        <thead className="table_heading">
                          <tr className="curved-border">
                            <th>Form</th>
                            <th>Owner</th>
                            <th>Date Created</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="table_body">
                          <tr style={{ width: "100%", height: "7px" }}></tr>
                          {currentDataForms?.length > 0 ? (
                            currentDataForms.map((workspace, key) => (
                              <tr key={key} className="table_row">
                                                         <td>
                                {workspace?.formTitle.split(" ")[0].length >
                                10
                                  ? `${workspace?.formTitle
                                      .split(" ")[0]
                                      .substring(0, 10)}...`
                                  : workspace?.formTitle}
                              </td>
                                <td>{workspace?.userId?.username}</td>
                                <td>
                                  {moment(workspace?.createdAt).format("ll")}
                                </td>
                                <td className="doted_icon">
                                  <svg
                                    viewBox="0 0 128 512"
                                    fill="currentColor"
                                    height="1em"
                                    width="1em"
                                    onClick={() => handleOpen(workspace)}
                                    className="ellipsis_td"
                                  >
                                    <path d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56-56-25.1-56-56 25.1-56 56-56zm56-104c0 30.9-25.1 56-56 56S8 126.9 8 96s25.1-56 56-56 56 25.1 56 56z" />
                                  </svg>
                                  {isOpen && isOpenId === workspace?._id && (
                                    <ul
                                      className="table_popup"
                                      ref={dropdownRef}
                                    >
                                      <li
                                        onClick={() =>
                                          handleRestoreForm(workspace._id)
                                        }
                                      >
                                        <svg
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill="#000000"
                                            d="M13 3a9 9 0 0 0-9 9H1l3.89 3.89l.07.14L9 12H6a7 7 0 0 1 7-7a7 7 0 0 1 7 7a7 7 0 0 1-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.896 8.896 0 0 0 13 21a9 9 0 0 0 9-9a9 9 0 0 0-9-9"
                                          />
                                        </svg>
                                        Restore
                                      </li>
                                      <li
                                        onClick={() =>
                                          handleLeaveDeletePopup(
                                            workspace,
                                            "form",
                                          )
                                        }
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          height="1em"
                                          width="1em"
                                          style={{ marginRight: ".5rem" }}
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                                        </svg>
                                        Delete
                                      </li>
                                    </ul>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="3"
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "white",
                                }}
                              >
                                No form found in trash.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {totalPagesForms > 1 && (
                        <div className="pagination">
                          {currentPage > 1 && (
                            <button
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              Previous
                            </button>
                          )}
                          {Array.from(
                            { length: totalPagesForms },
                            (_, index) => index + 1,
                          ).map((pageNumber) => (
                            <button
                              key={pageNumber}
                              className={
                                pageNumber === currentPage ? "active" : ""
                              }
                              onClick={() => handlePageChange(pageNumber)}
                            >
                              {pageNumber}
                            </button>
                          ))}
                          {currentPage < totalPagesForms && (
                            <button
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              Next
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {/* {pageCount > 1 && (
          <div className="w-100 d-flex justify-content-center mt-5">
            <ReactPaginate
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={6}
              onPageChange={handlePageChange}
              forcePage={pageNumber - 1}
              previousLabel={<span aria-hidden="true">&laquo;</span>}
              nextLabel={<span aria-hidden="true">&raquo;</span>}
              containerClassName="pagination"
              pageClassName="page-item"
              breakClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active"
              activeLinkClassName="active"
              activeStyle={{ color: "#7349bd" }}
              disabledClassName="disabled"
              breakLabel="..."
              disableInitialCallback={true}
              previousClassNameDisabled="disabled"
              nextClassNameDisabled="disabled"
              previousLinkClassNameDisabled="page-link disabled"
              nextLinkClassNameDisabled="page-link disabled"
              ariaLabelBuilder={(page) => `Page ${page}`}
            />
          </div>
        )} */}
      </Container>

      {leaveDeletePopup && (
        <FormDeletePopup
          handleLeaveDeletePopup={handleLeaveDeletePopup}
          leaveDeletePopup={leaveDeletePopup}
          leaveDeleteData={leaveDeleteData}
          category={category}
          handleDeletePerm={handleDeletePerm}
          handleWorkspacePerm={handleWorkspacePerm}
        />
      )}
    </>
  );
};

export default AllTrashForms;
