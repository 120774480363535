import React from "react";
import { Route, Routes } from "react-router-dom";
import Workspace from "../components/Workspace/Workspace";
import Recent from "../components/Recent/Recent";
import SingleWorkspace from "../components/SingleWorkspace/SingleWorkspace";
import Template from "../pages/Template/Template";
import AddForm from "../pages/AddFormPage/AddForm";
import TemplatCategory from "../pages/TemplateCategory/templatCategory";
import TemplatePreview from "../pages/TemplateForm";
import Trash from "../pages/Trash";
import UserProfile from "../components/profile/UserProfile";

const AuthRoute = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Workspace />} />
      <Route exact path="/recent" element={<Recent />} />
      <Route exact path="/trash" element={<Trash />} />
      <Route path="/profile" element={<UserProfile />} />
      <Route
        exact
        path="/workspace/:workspaceId"
        element={<SingleWorkspace />}
      />
      <Route exact path="/template" element={<Template />} />

      <Route path="/addForm/:workspaceId" element={<AddForm />} />
      <Route
        path="/templateCategory/:categoryId"
        element={<TemplatCategory />}
      />

      <Route path="/template/:formId" element={<TemplatePreview />} />
    </Routes>
  );
};

export default AuthRoute;
