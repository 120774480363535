import { useDispatch, useSelector } from "react-redux";
// import { getUsers } from "../../redux/action/user";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button, Card } from "react-bootstrap";
import { getAllUsersForInvite } from "../../redux/action/users";
import { shareWorkspace, shareForm } from "../../redux/action/workspace";
import styles from "./InviteSpaceInput.module.css";
const InviteSpaceInput = ({
  workspaceId: propWorkspaceId,
  workspaceName: propWorkspaceName,
  setSharePopupDiv,
  setSharePopup,
  setIsOpen,
  isForm,
  formId,
}) => {
  const [show, setShow] = useState(true);

  let dispatch = useDispatch();
  const [findUser, setfindUser] = useState([]);
  const [selectedUser, setselectedUser] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [memberid, setMemberId] = useState("");
  const alert = useAlert();
  const [btnClick, setBtnClick] = useState(true);
  const [email, setEmail] = useState("");

  const allUsers = useSelector((store) => store.shareWorkspace_users.users);
  // console.log(allUsers);
  // console.log(workspaceId, "workspace id of invite")
  const selectedWorkspace = useSelector(
    (store) => store.workspace.selectedWorkspace,
  );

  useEffect(() => {
    dispatch(getAllUsersForInvite());
  }, []);

  const handleSearchChange = (e) => {
    setselectedUser(e.target.value);
    let searchQuery = String(e.target.value).toLowerCase();

    if (searchQuery !== "") {
      let Sdata = [];
      Sdata = allUsers;

      var data = allUsers.filter((data) => {
        return (
          (data.username &&
            String(data.username).toLowerCase().includes(searchQuery)) ||
          (data.email && data.email.toLowerCase().includes(searchQuery))
        );
      });
      if (data.length > 0) {
        setfindUser(data);
        setShow(true);
      } else {
        if (!/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(searchQuery)) {
          setValidEmail(false);
          setEmail(searchQuery);
          setfindUser([]);
          setMemberId("");
          setBtnClick(true);
        } else {
          setEmail(searchQuery);
          setValidEmail(true);
          setfindUser([]);
          setMemberId("");
          setBtnClick(false);
        }
      }
    } else {
      setfindUser(allUsers);
      setShow(true);
    }
  };

  const handleSelect = (user) => {
    if (user.username) {
      setselectedUser(user.username);
    } else {
      setselectedUser(user.email);
    }
    setEmail(user.email);
    setMemberId(user._id);
    setBtnClick(false);
  };

  const handleShare = (payload, workspaceId) => {
    dispatch(
      shareWorkspace(payload, workspaceId, async function (response) {
        if (response.data.success) {
          alert.success(response.data.message);
          setSharePopup(true);
        } else if (!response.data.success) {
          alert.error(response.data.message);
        } else if (response.data.error) {
          alert.error(response.data.error);
        }
      }),
    );
  };
  const handleFormShare = (payload, workspaceId, formId) => {
    dispatch(
      shareForm(payload, workspaceId, formId, async function (response) {
        if (response.data.success) {
          alert.success(response.data.message);
          setSharePopup(true);
        } else if (!response.data.success) {
          alert.error(response.data.message);
        } else if (response.data.error) {
          alert.error(response.data.error);
        }
      }),
    );
  };
  const handleInvite = () => {
    const workspaceId =
      propWorkspaceId || (selectedWorkspace && selectedWorkspace._id);
    const workspaceName =
      propWorkspaceName ||
      (selectedWorkspace && selectedWorkspace.workspaceName);

    if (!memberid) {
      var member = allUsers.find((data) => {
        return (
          (data.username &&
            String(data.username).toLowerCase().includes(selectedUser)) ||
          (data.email &&
            String(data.email).toLowerCase().includes(selectedUser))
        );
      });
      if (member) {
        let payload = {
          memberid: member._id,
          email: member.email,
          workspaceName,
        };
        let Formpayload = {
          memberid: member?._id,
          email: member.email,
          formTitle: workspaceName,
        };
        if (isForm === "isForm") {
          handleFormShare(Formpayload, workspaceId, formId);
          payload, workspaceId, formId;
        } else {
          handleShare(payload, workspaceId);
        }
      }
    }
    if (memberid) {
      let payload = {
        memberid,
        email,
        workspaceName,
      };
      let Formpayload = {
        memberid,
        email,
        formTitle: workspaceName,
      };
      
      if (isForm === "isForm") {
        handleFormShare(Formpayload, workspaceId, formId);
      } else {
        handleShare(payload, workspaceId);
      }
    }
    setselectedUser("");
    setSharePopup(false);
    setSharePopupDiv(false);
  };

  return (
    <div style={{ zIndex: 10000 }} className="row mb-3">
      <div className="col-12">
        <div className="dropdown px-2" style={{ maginTop: "500px" }}>
          <input
            className="label_input icon-toggle-hide  p-2 w-100 border-0 shadow rounded-2 my-2"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            placeholder="Email address or name"
            value={selectedUser}
            onChange={(e) => handleSearchChange(e)}
            autoComplete="off"
          />

          <ul
            className="dropdown-menu scrollable-menu px-3 m-2"
            aria-labelledby="dropdownMenuLink"
            style={{
              minHeight: "70px",
              maxHeight: "250px",
              width: "325px",
              overflowY: "scroll",
              marginRight: "100px",
            }}
          >
            {findUser?.length > 0 ? (
              <>
                {show &&
                  findUser &&
                  findUser.map((user) => (
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSelect(user)}
                        className="d-flex mb-2 p-2 bg_hover_color p-3"
                      >
                        <span
                          className={styles.user_icon}
                          style={{
                            backgroundColor: user.userColor
                              ? user.userColor
                              : "#1960ee",
                            color: "white",
                          }}
                        >
                          {user && user.username && user.username ? (
                            user.username.charAt(0).toUpperCase()
                          ) : (
                            <i className="fa-regular fa-user"></i>
                          )}
                        </span>
                        <div
                          // onClick={() => handleSelect(user)}
                          className={styles.user_name}
                        >
                          {user && user.username && user.username}
                        </div>
                      </div>
                    </>
                  ))}
              </>
            ) : validEmail ? (
              <Card className={styles.card_styling}>
                <p className="text-muted text-center d-flex justify-content-center align-items-center p-2 m-0">
                  Invite {selectedUser}
                </p>
              </Card>
            ) : (
              <Card className={styles.card_styling}>
                <p className="text-muted text-center d-flex justify-content-center align-items-center p-2 m-0">
                  Add their email Address to Invite them.
                </p>
              </Card>
            )}
          </ul>
        </div>
      </div>
      <div className="col-12 text-center mt-3">
        <Button
          data-bs-dismiss="modal"
          className={styles.share_btn}
          onClick={handleInvite}
          disabled={!selectedUser}
        >
          Share
        </Button>
      </div>
    </div>
  );
};

InviteSpaceInput.propTypes = {
  workspaceId: PropTypes.node.isRequired,
  workspaceName: PropTypes.node.isRequired,
  setSharePopup: PropTypes.node.isRequired,
};
export default InviteSpaceInput;
