import LogoutIcon from "@mui/icons-material/Logout";
import { Menu } from "@mui/material";
import axios from "axios";
import classNames from "classnames/bind";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useCookies } from "react-cookie";
import { BiShow } from "react-icons/bi";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { IoMdColorPalette } from "react-icons/io";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DriveLogo from "../../assets/Drive.png";
import FormLogo from "../../assets/form logo.png";
import "../../css/Header.css";
import styles from "../../css/Header.module.css";
import { cx, drive, idea, risk, task, whiteboards } from "../../service/route";
import { API_URL, cookieToken } from "../../service/url";
import HoverOverlay from "../HoverOverlay";
import { Sendpopup } from "../sendpopup.jsx";
import { fetchProfileData } from "../../redux/slice/profileSlice.js";
const Header = ({
  title,
  handleFormTitleChange,
  formData,
  formId,
  // handleUndo,
  // handleRedo,
  // setFormData,
  handleImageTheme,
  setThemeImage,
  setSelectedImageUrl,
  showPreviews,
  showTheme,
  editing,
  handleEditFormOnEnter,
}) => {
  const [activeItem, setActiveItem] = useState("Question");

  // const handlePreviewClick = () => {
  //   setShowPreview(true);
  // };

  const [img, setImg] = useState("");

  const profileData = useSelector((store) => store.profile);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!profileData.userId) dispatch(fetchProfileData());
    setImg(profileData?.img);
  }, [profileData, dispatch]);
  // const handlePreviewClose = () => {
  //   setShowPreview(false);
  // };
  const token = cookieToken;

  const decoded = jwt_decode(token);
  // const [userName, setUserName] = useState();
  // const Data = userName.split(" ");
  // let newData = "";
  // if (Data.length === 2) {
  //   newData = `${Data[0][0]}${Data[1][0]}`;
  // } else {
  //   newData = `${Data[0][0]}`;
  // }
  const [newUser, setNewUser] = useState();
  useEffect(() => {
    setNewUser(decoded._doc.userId.username.toUpperCase().slice(0, 2));
    // setUserName(decoded._doc.userId.username)
  }, [decoded]);
  const [showResoponse, setShowResoponse] = useState(false);
  const navigate = useNavigate();
  const [showPop, setShowPop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showQuestions, setShowQuestions] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [showSend, setShowSend] = useState(true);
  useEffect(() => {
    const pathParts = window.location.pathname.split("/");
    if (pathParts.includes("createForm")) {
      setShowSend(false);
    }
  });

  const navigateToPreview = () => {
    navigate(`/selfPreview/${formId}`, { state: { formData: formData } });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [displayedFormTitle, setDisplayedFormTitle] = useState(title);
  const pathParts = window.location.pathname.split("/");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDisplayedFormTitle(title);
    }, 1200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [title]);

  useEffect(() => {
    if (pathParts.includes("createForm")) {
      setShowResoponse(true);
    } else if (pathParts.includes("responses")) {
      setShowQuestions(true);
      setShowResoponse(true);
    } else if (pathParts.includes("editForm")) {
      setShowQuestions(true);
      setShowResoponse(false);
    }
  }, [pathParts]);

  const handleInputChange = (e) => {
    setDisplayedFormTitle(e.target.value);
    handleFormTitleChange(e);
  };

  const clearCookieFromAllDomains = useCallback(() => {
    const domain = window.location.hostname;
    const path = "/";
    Cookies.remove("account_token", { domain, path });
    const subdomains = getSubdomains(domain);
    subdomains.forEach((subdomain) => {
      Cookies.remove("account_token", { domain: subdomain, path });
    });
    window.location.reload();
  }, []);

  const getSubdomains = useCallback((domain) => {
    const domainParts = domain.split(".");
    const subdomains = [];
    for (let i = domainParts.length - 2; i >= 0; i--) {
      subdomains.push(domainParts.slice(i).join("."));
    }
    return subdomains;
  }, []);

  // const [selectedDomain, setSelectedDomain] = useState("");
  const [cookies, setCookie] = useCookies(["selectedDomain"]);

  useEffect(() => {
    const storedDomain = cookies.selectedDomain;
    if (storedDomain) {
      // setSelectedDomain(storedDomain);
    }
  }, [cookies]);

  const handleDomainChange = useCallback(
    (newDomain) => {
      setCookie("selectedDomain", newDomain, { path: "/" });
      // setSelectedDomain(newDomain);
      try {
        const newTab = window.open("about:blank", "_blank");
        newTab.location.href = `https://${newDomain}`;
      } catch (error) {
        console.error("Error navigating to the selected domain:", error);
      }
    },
    [setCookie],
  );

  // const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    clearCookieFromAllDomains();
    window.location.reload();
    navigate("/");
  }, [clearCookieFromAllDomains, navigate]);
  const [show, setShow] = useState(false);

  const handleClose1 = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleImageChange = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const themeData = new FormData();
        themeData.append("themeImage", file);
        const response = await axios.post(
          `${API_URL}/form/form/themeImage`,
          themeData,
          {
            headers: {
              "x-access-token": cookieToken,
              "Content-Type": "multipart/form-data",
            },
          },
        );
        if (response?.data?.success) {
          handleImageTheme(response?.data?.url);
          // setThemeImage(response?.data?.url);
          // setSelectedImageUrl(URL.createObjectURL(file));
          // setSelectedImageUrl(response.data.url);
          handleClose1();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formWorkspace = useSelector((store) => store.preview.formWorkspace);
  const handleWorkspaceClick = useCallback(() => {
    navigate(`/workspace/${formWorkspace._id}`);
  }, [navigate, formWorkspace]);

  return (
    <Navbar
      bg="light"
      fixed="top"
      className={classNames(styles.nav_main, "shadow d-flex flex-wrap h-auto")}
      style={{
        zIndex: 10,
      }}
    >
      <Container fluid>
        <div className={styles.header_div_left}>
          <Navbar.Brand>
            <NavLink className="nav-link" to="/">
              <img
                src={FormLogo}
                className={styles.forms_logo}
                alt="logo-icon"
              />
            </NavLink>
          </Navbar.Brand>
          <div
            // className="display-6 fs-2 form_nav_name"
            className={classNames(styles.form_nav_name, "display-6 fs-2")}
          >
            <input
              type="text"
              onChange={handleInputChange}
              placeholder="Untitled Documents"
              className={classNames(styles.nav_title_input, "mb-0")}
              value={displayedFormTitle}
              disabled
            />
          </div>
          {/* <div>
              <button onClick={handlePreviewClick}>Preview</button>

              {showPreview && (
                <div>
                  <FormPreview formData={formData} />

                  <button onClick={handlePreviewClose}>Close</button>
                </div>
              )}
            </div> */}
        </div>

        <div
          role="button"
          className={styles.workspaceName}
          onClick={handleWorkspaceClick}
        >
          Go to Workspace
        </div>
        {/* <div className="header_div__middle  ">
            <Nav className="header_div_center pe-3 navbar-links">
              <Nav.Link href="#action1" className="mr">
                Quesiton
              </Nav.Link>
              <Nav.Link href="#action2" className="mr">
                Response
              </Nav.Link>
              <Nav.Link href="#action3" className="mr">
                Settings
              </Nav.Link>
            </Nav>
          </div> */}
        <Modal show={show} onHide={handleClose1} style={{ marginTop: "80px" }}>
          <Modal.Header closeButton>
            <Modal.Title>Customize Theme</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFile">
              {/* <Form.Label>Upload Image</Form.Label> */}
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="mt-3"
              />
            </Form.Group>
          </Modal.Body>
        </Modal>

        <Nav className="align-items-center d-flex flex-wrap gap-1 justify-content-end">
          {showSend ? (
            <Button
              variant="primary"
              onClick={() => setShowPop(!showPop)}
              // className="small-button"
              className={styles.small_button}
            >
              Send
            </Button>
          ) : null}

          {showPop && <Sendpopup showform={() => setShowPop(false)} />}

          {/* <Nav.Item className="nav_icons">
              <BiUndo onClick={handleUndo} />
            </Nav.Item>
            <Nav.Item className="nav_icons">
              <BiRedo onClick={handleRedo} />
            </Nav.Item>
            */}
          {showTheme && (
            <HoverOverlay
              placement="bottom"
              hoverText="Customise theme"
              mainInput={
                <Nav.Item className="nav_icons" onClick={handleShow}>
                  <IoMdColorPalette />
                </Nav.Item>
              }
            />
          )}

          {/* <HoverOverlay
              placement="bottom"
              hoverText="Preview"
              mainInput={
                <Nav.Item className="nav_icons" onClick={navigateToPreview}>
                  <BiShow />
                </Nav.Item>
              }
            /> */}

          <HoverOverlay
            placement="bottom"
            hoverText="Survey Form"
            mainInput={
              <Nav.Item className="nav_icons">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    // checked={isSurveyForm}
                    checked={formData?.formType === "survey" ? true : false}
                    onClick={() => {
                      if (editing) {
                        const value =
                          formData?.formType === "survey" ? "normal" : "survey";
                        handleEditFormOnEnter(value, "formType", formId);
                      }
                    }}
                  />
                </div>
              </Nav.Item>
            }
          />

          {showPreviews && (
            <HoverOverlay
              placement="bottom"
              hoverText="Preview"
              mainInput={
                <Nav.Item className="nav_icons" onClick={navigateToPreview}>
                  <BiShow />
                </Nav.Item>
              }
            />
          )}
          {/* <HoverOverlay
                placement="bottom"
                hoverText="Responses"
                mainInput={
                  <Nav.Item
                    className="nav_icons"
                    onClick={() => navigate("/forms")}
                  >
                    <VscFileSymlinkDirectory />
                  </Nav.Item>
                }
              /> */}
          <HoverOverlay
            placement="bottom"
            hoverText="All Forms"
            mainInput={
              <Nav.Item
                className={styles.nav_icons}
                onClick={() => navigate("/")}
              >
                <MdOutlineLibraryBooks />
              </Nav.Item>
            }
          />

          <Nav.Item className={styles.nav_icons}>
            <div className="dropdown ">
              <button
                className="d-flex hidden-arrow dropdown-toggle"
                // className={classNames(`${styles.hidden_arrow} ${styles.dropdown_toggle} ""`)}

                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <BsGrid3X3GapFill />
              </button>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start switch-to">
                <h6 className="switch__text01">Switch to</h6>
                <div className=" ">
                  <a
                    className={classNames(styles.dropdown_link, "link_css")}
                    href={`https://${drive}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="dropdown-item">
                      <span className="text-muted text-sm">
                        <img
                          className={styles.switch_dots}
                          src={DriveLogo}
                          alt="icon"
                        />
                      </span>
                      Drive
                    </div>
                  </a>
                </div>
                <div className="dropdown-divider" />
                <div className="">
                  <Link
                    className={classNames(styles.dropdown_link, "link_css")}
                    onClick={handleDomainChange.bind(this, task)}
                  >
                    <div className="dropdown-item">
                      <span className="text-muted text-sm">
                        <img
                          className={styles.switch_dots}
                          src={
                            // process.env.PUBLIC_URL +
                            "/Images/Navbarimg/Task.ico"
                          }
                          alt="icon"
                        />
                      </span>
                      Taskdeployer
                    </div>
                  </Link>
                </div>
                <div className="dropdown-divider"></div>

                <div className="">
                  <Link
                    className={classNames(styles.dropdown_link, "link_css")}
                    onClick={handleDomainChange.bind(this, idea)}
                  >
                    <div className="dropdown-item">
                      <span className="text-muted text-sm">
                        <img
                          className={styles.switch_dots}
                          src={
                            // process.env.PUBLIC_URL +
                            "/Images/Navbarimg/Idea-deployer-favicon.ico"
                          }
                          alt="icon"
                        />
                      </span>
                      Ideadeployer
                    </div>
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <div className="">
                  <Link
                    // className="link_css dropdown-link"
                    className={classNames(styles.dropdown_link, "link_css")}
                    onClick={handleDomainChange.bind(this, cx)}
                  >
                    <div className="dropdown-item">
                      <span className="text-muted text-sm">
                        <img
                          // className="switch-dots"
                          className={styles.switch_dots}
                          src={
                            // process.env.PUBLIC_URL +
                            "/Images/Navbarimg/cx-f.ico"
                          }
                          alt="icon"
                        />
                      </span>
                      Cxdeployer
                    </div>
                  </Link>
                </div>
                <div className="dropdown-divider"></div>

                <div className="">
                  <Link
                    className={classNames(styles.dropdown_link, "link_css")}
                    onClick={handleDomainChange.bind(this, risk)}
                  >
                    <div className="dropdown-item">
                      <span className="text-muted text-sm">
                        <img
                          className={styles.switch_dots}
                          src={
                            // process.env.PUBLIC_URL +
                            "/Images/Navbarimg/Risk.png"
                          }
                          alt="icon"
                        />
                      </span>
                      Riskdeployer(Coming Soon)
                    </div>
                  </Link>
                </div>

                <div className="dropdown-divider" />
                <div className="">
                  <Link
                    className={classNames(styles.dropdown_link, "link_css")}
                    onClick={handleDomainChange.bind(this, whiteboards)}
                  >
                    <div className="dropdown-item">
                      <span className="text-muted text-sm">
                        <img
                          className={styles.switch_dots}
                          src={
                            // process.env.PUBLIC_URL +
                            "/Images/Navbarimg/Whiteboard-f.png"
                          }
                          alt="icon"
                        />
                      </span>
                      Whiteboard
                    </div>
                  </Link>
                </div>
              </ul>
            </div>
          </Nav.Item>
          <Nav.Item className={styles.nav_icons}>
            {img ? (
              <img
                className={styles.text_circle}
                src={img}
                onClick={handleClick}
              />
            ) : (
              <div className={styles.text_circle} onClick={handleClick}>
                {newUser}
              </div>
            )}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem>
                  <ListItemIcon>
                    {img ? (
                      <img className={styles.text_circle} src={img} />
                    ) : (
                      <div className={styles.text_circle}>{newUser}</div>
                    )}
                  </ListItemIcon>
                  <NavLink className="nav-link" to="/profile">
                    <ListItemText primary="Profile" />
                  </NavLink>
                </MenuItem>
               

                <MenuItem>
                  <ListItemIcon className="Name" onClick={handleLogout}>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </MenuItem> */}

              <div
                className="d-flex gap-2 px-2 "
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/profile")}
              >
                <div>
                  {" "}
                  {img ? (
                    <img className={styles.text_circle} src={img} alt="" />
                  ) : (
                    <div className={styles.text_circle}>{newUser}</div>
                  )}
                </div>

                <h6 className="py-1"> Profile</h6>
              </div>

              <div
                className="px-2 d-flex gap-1 "
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                {/* <ListItemIcon className="Name"> */}
                <LogoutIcon />

                <h6>Logout</h6>
              </div>
            </Menu>
          </Nav.Item>
        </Nav>

        {/* </Navbar.Collapse> */}
      </Container>
      <div className="mx-auto d-flex gap-3 mt-3">
        {!showQuestions ? (
          <div
            onClick={() => {
              setActiveItem("Question");
            }}
            style={{
              borderBottom:
                activeItem === "Question" ? "4px solid #673ab7" : "none",
              cursor: "pointer",
            }}
          >
            Question
          </div>
        ) : null}
        {!showResoponse && showQuestions ? (
          <div
            onClick={() => {
              setActiveItem("Response");
              navigate(`/responses/${formId}`);
              setActiveItem("Response");
            }}
            style={{
              borderBottom:
                activeItem === "Response" ? "4px solid #673ab7" : "none",
              cursor: "pointer",
            }}
          >
            Response
          </div>
        ) : null}
      </div>
    </Navbar>
  );
};

export default Header;
