import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Rating from "@mui/material/Rating";
import Select from "@mui/material/Select";
import classNames from "classnames/bind";
import React from "react";
import { Card } from "react-bootstrap";
import { AiOutlineDownCircle } from "react-icons/ai";
import { BiTime } from "react-icons/bi";
import { BsImage, BsStar, BsTelephoneFill, BsTextCenter } from "react-icons/bs";
import { CgCalendarDates } from "react-icons/cg";
import { FaRegCircleDot } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { IoIosCheckbox } from "react-icons/io";
import { MdShortText } from "react-icons/md";
import { TfiText } from "react-icons/tfi";
import Switch from "react-switch";
import styles from "../../css/CreateForm.module.css";
import NextSection from "../NextSection/NextSection";

export default function RenderFields({
  handleUpdateQuestion,
  section,
  sectionIndex,
  handleQuestionSaveOnEnter,
  handleRemoveQuestionImage,
  handleImageChange,
  handleUpdateFieldType,
  isQuiz,
  handleUpdatedate,
  handleUpdatetime,
  handleUpdateimage,
  dhandleUpdateOption,
  dhandleAddOption,
  dhandleRemoveOption,
  handleUpdateOptionmcg,
  handleRemoveOptionmcg,
  handleAddOptionmcg,
  handleUpdateOption,
  handleAddOption,
  addingAnswer,
  handleRemoveOption,
  handleAddAnswer,
  handleAddAnswerClick,
  handleUpdatePoints,
  handleCopyField,
  handleRemoveField,
  handleUpdateQuestionRequired,
  childData
}) {
  return (
    <div className={styles.questionpage_container}>
      {section?.questions?.map((field, questionIndex) => (
        <Card
          key={field.id}
          className={classNames(styles.questionpage_card, "shadow")}
        >
          <div className={styles.inputandSelect_container}>
            <div className={styles.inputandImg_container}>
              <input
                type="text"
                placeholder={`Enter Question ${questionIndex + 1}`}
                className={styles.question_input}
                value={field.question}
                onChange={(e) =>
                  handleUpdateQuestion(field.id, e.target.value, sectionIndex)
                }
                onBlur={(e) => {
                  if (field.question.trim() === "") {
                    handleQuestionSaveOnEnter(
                      `Question ${questionIndex + 1}`,
                      "question",
                      field._id,
                      e,
                    );
                  } else {
                    handleQuestionSaveOnEnter(
                      field.question,
                      "question",
                      field._id,
                      e,
                    );
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleQuestionSaveOnEnter(
                      field.question,
                      "question",
                      field._id,
                      e,
                    );
                  }
                }}
              />
            </div>

            <div className="d-flex align-items-center">
              {field?.qImgUrl && field?.qImgUrl?.length > 1 ? (
                <div
                  key={field.id}
                  className={styles.removeQuestionImage}
                  onClick={() =>
                    handleRemoveQuestionImage(field.id, sectionIndex)
                  }
                >
                  <ImCross />
                </div>
              ) : (
                <></>
              )}

              <div className="select_image">
                <label htmlFor={`fileInput-${field.id}`}>
                  <BsImage />
                </label>

                <input
                  type="file"
                  id={`fileInput-${field.id}`}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    {
                      handleImageChange(
                        e,
                        field.id,
                        questionIndex,
                        sectionIndex,
                      );
                    }
                  }}
                  accept="image/*"
                />
              </div>
              <FormControl
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  m: 2,
                  minWidth: 150,
                }}
              >
                <Select
                  sx={{
                    height: "30px",
                    "& .MuiSelect-select": {
                      padding: "0px 14px",
                    },
                  }}
                  value={field.type}
                  label="Age"
                  // onChange={handleChange}
                  onChange={(e) => {
                    handleUpdateFieldType(
                      field.id,
                      e.target.value,
                      sectionIndex,
                    );
                    handleQuestionSaveOnEnter(
                      e.target.value,
                      "type",
                      field._id,
                    );
                  }}
                >
                  <MenuItem selected value="text">
                    <TfiText className="mx-2" />
                    Text
                  </MenuItem>

                  <MenuItem value="multiple_choice">
                    <FaRegCircleDot className="mx-2" />
                    Multiple choice
                  </MenuItem>

                  <MenuItem value="checkbox">
                    <IoIosCheckbox className="mx-2" />
                    Checkbox
                  </MenuItem>

                  <MenuItem value="short_answer">
                    <MdShortText className="mx-2" />
                    Short Answer
                  </MenuItem>
                  <MenuItem value="paragraph">
                    <BsTextCenter className="mx-2" />
                    Paragraph
                  </MenuItem>
                  <MenuItem value="dropdown">
                    <AiOutlineDownCircle className="mx-2" />
                    Dropdown
                  </MenuItem>
                  <MenuItem value="phone">
                    <BsTelephoneFill className="mx-2" />
                    Phone Number
                  </MenuItem>
                  <MenuItem value="address">
                    <BsTextCenter className="mx-2" />
                    Address
                  </MenuItem>
                  <MenuItem value="time">
                    <BiTime className="mx-2" />
                    Time
                  </MenuItem>
                  <MenuItem value="date">
                    <CgCalendarDates className="mx-2" />
                    Date
                  </MenuItem>
                  <MenuItem value="rating">
                    <BsStar className="mx-2" />
                    Rating
                  </MenuItem>
                  <MenuItem value="file_upload">
                    <BsStar className="mx-2" />
                    Document upload
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          {field?.qImgUrl && (
            <div key={field.id} className={styles.qImgUrl_div}>
              <img
                src={field?.qImgUrl}
                alt="Question "
                className={styles.qImgUrl}
              />
            </div>
          )}
          {field.img && (
            <div className={styles.image_question}>
              <img className="img-fluid" src={field.img} alt="Selected" />
            </div>
          )}

          {field.type === "linear_scale" && (
            <>
              <input
                className={styles.options_input}
                placeholder="label(optional)"
              />

              <input
                className={styles.options_input}
                placeholder="label(optional)"
              />
            </>
          )}

          {/* -------------------------------------------- Address -------------------------------------- */}
          {field.type === "address" && (
            <input
              type="text"
              className={styles.paragraph_input}
              placeholder="Enter the address"
              disabled
            />
          )}
          {/* --------------------------------------------text--------------------------------------- */}

          {field.type === "text" && (
            <input
              type="text"
              className={styles.paragraph_input}
              placeholder="Enter text"
              disabled
            />
          )}
          {/* -------------------------e-------------------ShortAnswer--------------------------------------- */}

          {field.type === "short_answer" && (
            <textarea
              className={`${styles.paragraph_input} ${styles.text_area}`}
              rows="1"
              placeholder="short_answer"
              disabled
            />
          )}
          {/* --------------------------------------------paragraph-------------------------------------- */}

          {field.type === "paragraph" && (
            <textarea
              className={`${styles.paragraph_input} ${styles.text_area}`}
              rows="3"
              placeholder="paragraph"
              disabled
            />
          )}
          {/* --------------------------------------------choose file-------------------------------------- */}
          {field.type === "file_upload" && (
            <input
              type="file"
              className={styles.paragraph_input}
              accept=".jpg, .jpeg, .png, .pdf" // Limiting accepted file types
              required // Making the field mandatory
              disabled
            />
          )}
          {/* -------------------------------------------- Number -------------------------------------- */}
          {field.type === "number" && (
            <input
              type="number"
              className={styles.paragraph_input}
              placeholder="Number"
              min={0} // Setting a minimum value
              max={100} // Setting a maximum value
              step={1} // Setting the step value (increments)
              required // Making the field mandatory
              disabled
            />
          )}
          {/* -------------------------------------------- Email -------------------------------------- */}
          {field.type === "email" && (
            <input
              type="email"
              className={styles.paragraph_input}
              placeholder="Email"
              required // Making the field mandatory
              disabled
            />
          )}
          {/* -------------------------------------------- Phone-------------------------------------- */}
          {field.type === "phone" && (
            <input
              type="tel" // Using 'tel' input type for phone numbers
              className={styles.paragraph_input}
              placeholder="Phone number"
              pattern="[0-9]{10}" // Adding a pattern for valid phone numbers (10 digits)
              required // Making the field mandatory
              disabled
            />
          )}
          {/* --------------------------------------------Link-------------------------------------- */}
          {field.type === "link" && (
            <input
              type="url"
              className={styles.paragraph_input}
              placeholder="Link"
              required // Making the field mandatory
              disabled
            />
          )}
          {/* -------------------------------------------- Rating -------------------------------------- */}
          {field.type === "rating" && (
            <Rating
              className="mx-3"
              name="half-rating-read"
              defaultValue={0}
              precision={0.5}
              readOnly
            />
          )}

          {/* -------------------------------------------- Signature -------------------------------------- */}
          {field.type === "signature" && (
            <input
              type="text"
              className={styles.paragraph_input}
              placeholder="Signature"
              required // Making the field mandatory
              disabled
            />
          )}
          {/* -------------------------------------------- Opinion Scale -------------------------------------- */}
          {field.type === "opinion_scale" && (
            <input
              type="range" // Using 'range' input type for opinion scale
              className={styles.paragraph_input}
              min={1} // Setting the minimum scale value
              max={5} // Setting the maximum scale value
              step={1} // Setting the step value (increments)
              required // Making the field mandatory
              disabled
            />
          )}
          {/* -------------------------------------------- statement -------------------------------------- */}
          {field.type === "statement" && (
            <input
              type="text"
              className={styles.paragraph_input}
              placeholder="Statement"
              required // Making the field mandatory
              disabled
            />
          )}
          {/* -------------------------------------------- Ranking -------------------------------------- */}
          {field.type === "ranking" && (
            <input
              type="number"
              className={styles.paragraph_input}
              placeholder="Ranking"
              min={1} // Setting a minimum ranking value
              max={10} // Setting a maximum ranking value
              step={1} // Setting the step value (increments)
              required // Making the field mandatory
              disabled
            />
          )}
          {/* -------------------------------------------- Website Link -------------------------------------- */}
          {field.type === "website_link" && (
            <input
              type="text"
              className={styles.paragraph_input}
              placeholder="Website link"
              disabled
            />
          )}
          {/* --------------------------------------------dropdown--------------------------------------- */}

          {/* {field.type === "dropdown" && (
                    <input type="text" className="options_input"
                    />
                  )} */}

          {/* --------------------------------------------date--------------------------------------- */}

          {field.type === "date" && (
            <div className="date_input_container">
              <label htmlFor={`date_${field.id}`} className="date_label" />
              <input
                type="date"
                id={`date_${field.id}`}
                className={styles.date_input}
                value={field.dateValue}
                onChange={(e) => handleUpdatedate(field.id, e.target.value)}
                disabled
              />
            </div>
          )}

          {/* --------------------------------------------time-------------------------------------- */}

          {field.type === "time" && (
            <input
              type="time"
              className={styles.time_input}
              value={field.timeValue}
              onChange={(e) => handleUpdatetime(field.id, e.target.value)}
              disabled
            />
          )}
          {/* --------------------------------------------image--------------------------------------- */}

          {field.type === "image" && (
            <div>
              <input
                type="file"
                accept="image/*"
                className={styles.image_input}
                onChange={(e) => handleUpdateimage(field.id, e.target.files[0])}
              />
              {field.image && (
                <img
                  src={URL.createObjectURL(field.image)}
                  alt="uploaded"
                  className="image_preview"
                />
              )}
            </div>
          )}

          {/*------------------------------------ This is for dropdown  ---------------------------------------------------*/}

          {field.type === "dropdown" && (
            <div className={styles.field_options}>
              {field.options.map((option, index) => (
                <div key={option}>
                  <input
                    id={`dropdown_optionInput_${index}_${field.id}`}
                    type="text"
                    className={styles.options_input}
                    value={option.option}
                    placeholder={`Option ${index + 1}`}
                    onChange={(e) =>
                      dhandleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onClick={(e) =>
                      dhandleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onFocus={(e) =>
                      dhandleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onBlur={() => {
                      handleQuestionSaveOnEnter(
                        field.options,
                        "editoptions",
                        field._id,
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dhandleAddOption(field.id, sectionIndex);
                      }
                    }}
                  />
                  <i
                    className="fa-solid fa-lg fa-multiply text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      dhandleRemoveOption(field.id, index, sectionIndex)
                    }
                  />
                </div>
              ))}
              <button
                // className="button button-secondary"
                type="button"
                className={`${styles.button} ${styles.button_secondry}`}
                onClick={() => dhandleAddOption(field.id, sectionIndex)}
              >
                Add Option
              </button>
            </div>
          )}

          {/* ---------------------multiple choice option ---------------------------------- */}

          {(field.type === "multiple_choice_option" ||
            field.type === "checkbox_grid ") && (
            <>
              <div className={styles.parent}>
                <div className={styles.row}>
                  {field.options.map((option, index) => (
                    <div key={option}>
                      {field.type === "multiple_choice_option" ? (
                        <i className="fa-regular fa-square text-muted" />
                      ) : (
                        <i className="fa-regular fa-circle text-muted" />
                      )}

                      <input
                        type="text"
                        className={styles.options_input}
                        value={option.option}
                        onChange={(e) =>
                          handleUpdateOptionmcg(field.id, index, e.target.value)
                        }
                        placeholder="Row"
                      />

                      <i
                        className="fa-solid fa-lg fa-multiply text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveOptionmcg(field.id, index)}
                      />
                    </div>
                  ))}

                  <button
                    type="button"
                    className="button button-secondary"
                    onClick={() => handleAddOptionmcg(field.id)}
                  >
                    Add Row
                  </button>
                </div>

                <div className={styles.column}>
                  {field.options.map((option, index) => (
                    <div key={option}>
                      {field.type === "multiple_choice_option" ? (
                        <i className="fa-regular fa-square text-muted" />
                      ) : (
                        <i className="fa-regular fa-circle text-muted" />
                      )}

                      <input
                        type="text"
                        className={styles.options_input}
                        value={option.option}
                        onChange={(e) =>
                          handleUpdateOptionmcg(field.id, index, e.target.value)
                        }
                        placeholder="Column"
                      />

                      <i
                        className="fa-solid fa-lg fa-multiply text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveOptionmcg(field.id, index)}
                      />
                    </div>
                  ))}

                  <button
                    type="button"
                    className="button button-secondary"
                    onClick={() => handleAddOptionmcg(field.id)}
                  >
                    Add Column
                  </button>
                </div>
              </div>
            </>
          )}

          {/*------------------------------------------- MultipleChoice ----------------------------*/}

          {field.type === "multiple_choice" && (
            <div className={styles.field_options}>
              {field.options.map((option, index) => (
                <div key={option}>
                  {field.answer?.toString() === index?.toString() && isQuiz ? (
                    <i className="fa-solid fa-lg fa-check text-success" />
                  ) : (
                    <i className="fa-regular fa-circle text-muted" />
                  )}
                  <input
                    id={`multiple_optionInput_${index}_${field.id}`}
                    type="text"
                    className={styles.options_input}
                    value={option.option}
                    placeholder={`Option ${index + 1}`}
                    onChange={(e) =>
                      handleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onClick={(e) =>
                      handleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onFocus={(e) =>
                      handleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onBlur={() => {
                      handleQuestionSaveOnEnter(
                        field.options,
                        "editoptions",
                        field._id,
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (
                          field.options.length - 1 === index &&
                          e.target.value.length
                        ) {
                          handleQuestionSaveOnEnter(
                            field.options,
                            "editoptions",
                            field._id,
                          );
                          handleAddOption(field.id, sectionIndex);
                        }
                      }
                    }}
                  />
                  {/* {field.options.length > 1 && ( */}
                  {isQuiz ? (
                    <>
                      {addingAnswer !== field.id ? (
                        <i
                          className="fa-solid fa-lg fa-multiply text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleRemoveOption(field.id, index, sectionIndex)
                          }
                        />
                      ) : (
                        <i
                          className="fa-solid fa-lg fa-check text-success"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleAddAnswer(
                              field.id,
                              sectionIndex,
                              index,
                              field._id,
                            )
                          }
                        />
                      )}
                    </>
                  ) : (
                    <i
                      className="fa-solid fa-lg fa-multiply text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleRemoveOption(field.id, index, sectionIndex)
                      }
                    />
                  )}
                  {/* )} */}
                </div>
              ))}
              <button
                type="button"
                // className="button button-secondary"
                className={`${styles.button} ${styles.button_secondry}`}
                onClick={() => handleAddOption(field.id, sectionIndex)}
              >
                Add Option
              </button>
            </div>
          )}

          {/*------------------------------------------- Checkbox ----------------------------*/}
          {field.type === "checkbox" && (
            <div className={styles.field_options}>
              {field.options.map((option, index) => (
                <div key={option}>
                  <i className="fa-regular fa-square text-muted" />
                  <input
                    id={`check_optionInput_${index}_${field.id}`}
                    type="text"
                    className={styles.options_input}
                    value={option.option}
                    placeholder={`Option ${index + 1}`}
                    onChange={(e) =>
                      handleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onClick={(e) =>
                      handleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onFocus={(e) =>
                      handleUpdateOption(
                        field.id,
                        index,
                        e.target.value,
                        sectionIndex,
                      )
                    }
                    onBlur={() => {
                      handleQuestionSaveOnEnter(
                        field.options,
                        "editoptions",
                        field._id,
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddOption(field.id, sectionIndex);
                      }
                    }}
                  />

                  {/* {field.options.length > 1 && ( */}
                  <i
                    className="fa-solid fa-lg fa-multiply text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleRemoveOption(field.id, index, sectionIndex)
                    }
                  />
                  {/* )} */}
                </div>
              ))}
              <button
                // className="button button-secondary"
                type="button"
                className={`${styles.button} ${styles.button_secondry}`}
                onClick={() => handleAddOption(field.id, sectionIndex)}
              >
                Add Option
              </button>
            </div>
          )}

          <div className={styles.questioncard_options}>
            {isQuiz &&
            addingAnswer !== field.id &&
            field.type === "multiple_choice" ? (
              <div className="text-muted d-flex align-items-center gap-1 w-100">
                <span
                  className="text-muted d-flex align-items-center gap-1 w-100"
                  onClick={() => handleAddAnswerClick(field.id, sectionIndex)}
                >
                  <i className="fa-solid fa-list-check" />
                  Add Answer
                </span>

                <span className="text-muted d-flex align-items-center gap-1 w-100">
                  <input
                    type="number"
                    placeholder={`Enter points ${questionIndex + 1}`}
                    className={styles.points_input}
                    value={field.points}
                    onChange={(e) =>
                      handleUpdatePoints(
                        field.id,
                        e.target.value,
                        sectionIndex,
                        field._id,
                      )
                    }
                  />
                </span>
              </div>
            ) : null}
            <i
              className="fa-solid fa-copy mx-2 mt-1 text-muted"
              onClick={() => handleCopyField(field.id, sectionIndex)}
            />
            <i
              className="fa-solid fa-trash mx-2 mt-1 text-muted"
              onClick={() => handleRemoveField(field.id, sectionIndex)}
            />
            <span className="ml-10 required_span">Required</span>
            <Switch
              className={styles.smallSwitch}
              checked={field.required}
              onChange={(checked) => {
                handleUpdateQuestionRequired(
                  field.id,
                  field.question,
                  checked,
                  sectionIndex,
                );
                handleQuestionSaveOnEnter(checked, "required", field._id);
              }}
            />
          </div>
        </Card>
      ))}
      <NextSection
        currentSectionIndex={sectionIndex}
      />

      {childData && (
        <Card className="videopage_card shadow">
          <iframe
            title="YouTube Video"
            width="40%"
            height="250"
            src={`https://www.youtube.com/embed/${childData.id}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Card>
      )}

      <div className={styles.button_container} />
    </div>
  );
}
