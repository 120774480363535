import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AllTemplateCard from "../../components/Cards/AllTemplateCard";
import Loader from "../../loader";
import { getTemplatesOfCategory } from "../../redux/slice/templateSlice";
import styles from "./templateCategory.module.css";

function TemplatCategory() {
  const dispatch = useDispatch();
  const { categoryId } = useParams();

  useEffect(() => {
    dispatch(getTemplatesOfCategory(categoryId));
  }, [dispatch, categoryId]);

  const templates = useSelector((store) => store.template.templates);
  const category = useSelector((store) => store.template.currentCategory);
  const loading = useSelector((store) => store.template.loading);

  return (
    <div className={styles.parent}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="headingandbutton_div">
            <h5 className="workspace_heading">
              {category?.category} Templates
            </h5>
          </div>
          <div className="feed">
            <div className="feed_inputContainer p-2">
              <ul className={styles.templatesList}>
                {templates?.map((form) => (
                  <li key={form}>
                    <AllTemplateCard form={form} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TemplatCategory;
