
import React, { useEffect, useState } from "react";
import _debounce from "lodash/debounce";

import { FormControl, MenuItem, Select } from "@mui/material";
import { editSectionOnEnter } from "../../redux/slice/previewSlice";
import { useDispatch } from "react-redux";


const NextSectionByCondition = ({
    optionIndex,
    optionId,
    handleChange,
    formData,
    currentSectionIndex,
    sectionId,
    handleEditQuestion,
  }) =>{

    const [nextIndex, setNextIndex] = useState("next");
    const dispatch = useDispatch();
    
    const handleOptionChange = (e) => {
        let value =e.target.value;
      setNextIndex(e.target.value);
      handleChange(optionId, value, sectionId, "redirectIndex");
        if (value !== "submit" && value !== "next") {
        let updateRedirectSection = {
          value: currentSectionIndex,
          update: "redirectPrevious",
          sectionId: formData?.sections?.[value]?._id,
        };
        dispatch(editSectionOnEnter(updateRedirectSection));
      }
    };
  
    useEffect(() => {
      const selectedOption =
        formData.sections[currentSectionIndex]?.condition?.options[optionIndex]
          ?.redirectIndex;
      setNextIndex(selectedOption || "next");
    }, [formData, currentSectionIndex, optionIndex]);
  
    return (
      <FormControl
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          m: 2,
          minWidth: 150,
        }}
      >
        <Select
          sx={{
            height: "30px",
            "& .MuiSelect-select": {
              padding: "0px 14px",
            },
          }}
          value={nextIndex}
          onChange={handleOptionChange}
          onBlur={(e) => {
            handleEditQuestion(sectionId, e);
          }}
          onKeyDown={(e) => {
            if (e.key === 13) {
              handleEditQuestion(sectionId, e);
            }
          }}
        >
          <MenuItem
            key={`conditional_section_next_${
              currentSectionIndex + 1
            }_option_${optionIndex}`}
            selected={nextIndex === "next"}
            value={"next"}
          >
            Continue to next section
          </MenuItem>
          {formData?.sections?.map((section, index) =>
            index !== currentSectionIndex ? (
              <MenuItem
                key={`conditional_section_${index}_option_${optionIndex}`}
                selected={nextIndex === index}
                value={index}
              >
                Continue to section {index + 1} ({section?.title})
              </MenuItem>
            ) : (
              ""
            ),
          )}
          <MenuItem
            key={`conditional_section_next_${
              currentSectionIndex + 1
            }_submit_option_${optionIndex}`}
            selected={nextIndex === "submit"}
            value={"submit"}
          >
            Submit Form
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
  export default NextSectionByCondition ;
