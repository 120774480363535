import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import "../../css/AllForms.css";
import Loader from "../../loader";
import {
  getRecentVisitedForms,
  setPageNumber,
} from "../../redux/slice/formSlice.js";
import AllFormsCard from "../Cards/AllFormsCard";

const RecentAllForms = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.forms.loading);
  const pageNumber = useSelector((state) => state.forms.pageNumber);
  const pageCount = useSelector((state) => state.forms.pageCount);

  const profile = useSelector((state) => state.profile);
  const recentForms = useSelector((state) => state.forms.recentForms);

  useEffect(() => {
    dispatch(getRecentVisitedForms());
  }, [dispatch]);

  // const handlePageChange = useCallback(
  //   ({ selected }) => {
  //     dispatch(setPageNumber(selected + 1));
  //   },
  //   [dispatch]
  // );

  const [search, setSearch] = useState("");
  const filteredForms = search
    ? recentForms.filter((form) =>
        form.sections[0].title.toLowerCase().includes(search.toLowerCase())
      )
    : recentForms;

  return (
    <>
      <Container className="mb-3 ms-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            {!filteredForms || filteredForms.length === 0 ? (
              <p>No data Found</p>
            ) : (
              <>
                <div className="d-flex flex-wrap gap-2">
                  {filteredForms.map((form, index) => (
                    <AllFormsCard
                      form={form}
                      index={index}
                      key={index}
                      search={search}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}

        {/* {pageCount > 1 && (
          <div className="w-100 d-flex justify-content-center mt-5">
            <ReactPaginate
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={6}
              onPageChange={handlePageChange}
              forcePage={pageNumber - 1}
              previousLabel={<span aria-hidden="true">&laquo;</span>}
              nextLabel={<span aria-hidden="true">&raquo;</span>}
              containerClassName="pagination"
              pageClassName="page-item"
              breakClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active"
              activeLinkClassName="active"
              activeStyle={{ color: "#7349bd" }}
              disabledClassName="disabled"
              breakLabel="..."
              disableInitialCallback={true}
              previousClassNameDisabled="disabled"
              nextClassNameDisabled="disabled"
              previousLinkClassNameDisabled="page-link disabled"
              nextLinkClassNameDisabled="page-link disabled"
              ariaLabelBuilder={(page) => `Page ${page}`}
            />
          </div>
        )} */}
      </Container>
    </>
  );
};

export default RecentAllForms;
