import Cookies from "js-cookie";

export const API_URL = "https://api.appsdeployer.com/api";
export const HOST_URL = "https://api.appsdeployer.com/";

// export const API_URL = "http://localhost:8082/api";
// export const HOST_URL ='http://localhost:8082/'

export const cookieToken =
  process.env.NODE_ENV === "development"
    ? Cookies.get("dev_account_token")
    : Cookies.get("account_token");
