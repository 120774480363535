import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styles from '../../css/Preview.module.css'; // Adjust the path
import { handleCheckboxChange } from '../../redux/slice/previewSlice';

const CheckboxQuestion = ({ _id, questionText, qImgUrl, required, isSelfPreviewPage, options, formErrors, ind, submissionData }) => {
  const dispatch = useDispatch();

  return (
    <Card className={styles.question_card}>
      <Form.Group key={_id} className={styles.cardChange}>
        <Form.Label className="mb-3 w-100">
          <div className="d-flex">
            <p className="d-flex me-1">
              {required && <span className={styles.required}>*</span>} {questionText?.length !== 0 && `${ind + 1}. `}
            </p>
            <div>{questionText}</div>
          </div>
          {qImgUrl && qImgUrl?.length !== 0 && (
            <>
              <img
                src={qImgUrl}
                alt=""
                className={styles.preview_Question_image}
              />
            </>
          )}
        </Form.Label>
        {options.map((option, index) => (
          <div key={option._id} className="mb-3 ms-3">
            <Form.Check
              disabled={isSelfPreviewPage}
              type="checkbox"
              id={`${_id}-${option.option}`}
              label={option.option}
              checked={
                submissionData?.reply?.find(
                  (item) =>
                    item.questionId === _id &&
                    Array.isArray(item.answer) &&
                    item.answer.some(
                      (selectedOption) =>
                        selectedOption.optionId === option._id,
                    ),
                ) !== undefined
              }
              className="form-check-input-lg"
              onChange={(e) =>
                dispatch(
                  handleCheckboxChange({
                    questionId: _id,
                    optionId: option._id,
                    optionTitle: option.option,
                    isChecked: e.target.checked,
                    optionIndex: index,
                  }),
                )
              }
              isInvalid={formErrors.includes(
                `Please fill the required field: ${questionText}`,
              )}
            />
          </div>
        ))}
        <Form.Control.Feedback type="invalid">
          Please fill the required field: {questionText}
        </Form.Control.Feedback>
      </Form.Group>
    </Card>
  );
};

export default CheckboxQuestion;
