import axios from "axios";
import { driveconfig } from "../../service/tokenstore";
import { API_URL } from "../../service/url";
import { setAllUsers } from "../slice/shareWorkspace_usersSlice";

export const getAllUsersForInvite = (callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_URL}/form/user/getAllUsers`,
        driveconfig,
      );
      dispatch(setAllUsers(response.data.AllUsersRole));
    } catch (err) {
      console.log(err);
    }
  };
};
