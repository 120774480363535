import axios from "axios";
import Cookies from "js-cookie";
import { driveconfig } from "../../service/tokenstore";
import { API_URL, HOST_URL, cookieToken } from "../../service/url";

export const getWorkspace = (callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_URL}/form/workspace/getAllWorkspace`,
        driveconfig,
      );
      dispatch({
        type: "SET_WORKSPACES",
        payload: response.data,
      });
    } catch (error) {
      // callback(error);
      console.log(error, "error");
    }
  };
};

export const getWorkspaceById = (workspaceId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        API_URL + `/form/workspace/getWorkspaceById/${workspaceId}`,
        driveconfig,
      );
      if (response.data.success === true) {
        dispatch({
          type: "SET_SELECTED_WORKSPACES",
          payload: response.data.data,
        });
      }
    } catch (error) {
      dispatch({
        type: "SET_SELECTED_WORKSPACES",
        payload: {},
      });
      console.log("Failed to get error in workspace");
    }
  };
};
export const setSelectedWorkspace = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_SELECTED_WORKSPACES",
        payload: data,
      });
      callback();
    } catch (error) {
      console.error(error);
    }
  };
};
export const setSelectedWorkspaceId = (workspaceId, workspace, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_SELECTED_WORKSPACES_ID",
        payload: {
          workspaceId,
          workspace,
        },
      });
    } catch (error) {
      callback(error);
    }
  };
};

export const addWorkspace = (inputValue) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_URL + `/form/workspace/addWorkspace`,
        inputValue,
        driveconfig,
      );

      dispatch({
        type: "SET_NEW_WORKSPACES",
        payload: {
          newWorkspace: response.data,
        },
      });
      dispatch(getWorkspace());
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const updateVisitedWorkspace = (workspaceId, payload, callback) => {
  return async () => {
    try {
      const userId = localStorage.getItem("user_id");
      const response = await axios.post(
        API_URL +
          `/form/workspace/updateRecentVisitedWorkspace/${userId}/${workspaceId}`,
        payload,

        driveconfig,
      );
      if (response.data.success === true) {
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};

export const editWorkspace = (inputValue, workspaceId, callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_URL + `/form/workspace/editWorkspace/${workspaceId}`,
        inputValue,
        driveconfig,
      );
      if (response.data) {
        dispatch({
          type: "UPDATE_WORKSPACE",
          payload: response?.data?.data,
        });
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};
export const newWorkspace = (inputValue, callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_URL + `/form/workspace/addworkspace`,
        inputValue,

        driveconfig,
      );
      if (response.data.success === true) {
        dispatch({
          type: "SET_NEW_WORKSPACES",
          payload: response.data,
        });
        dispatch({
          type: "SET_SELECTED_WORKSPACES",
          payload: response.data.workspaces[0],
        });

        dispatch(getWorkspace());
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};
export const inviteWorkspace = (payload, workspaceId, callback) => {
  return async () => {
    try {
      const response = await axios.post(
        API_URL + `/form/workspace/InviteOnWorkspace/${workspaceId}`,
        payload,

        driveconfig,
      );
      if (response.data.success === true) {
        callback(response);
      } else {
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};

export const shareWorkspace = (payload, workspaceId, callback) => {
  return async () => {
    try {
      const response = await axios.post(
        API_URL + `/form/workspace/shareWorkspace/${workspaceId}`,
        payload,

        driveconfig,
      );
      if (response.data.success === true) {
        callback(response);
      } else {
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};
export const shareForm = (payload, workspaceId, formId, callback) => {
  return async () => {
    try {
      const response = await axios.post(
        API_URL + `/form/form/share/${workspaceId}/${formId}`,
        payload,

        driveconfig,
      );
      if (response.data.success === true) {
        callback(response);
      } else {
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};
export const closeWorkspace = (workspaceId, alert) => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().profile.userId;
      const response = await axios.put(
        API_URL + `/form/workspace/closeWorkspace/${userId}/${workspaceId}`,
        {},
        driveconfig,
      );
      if (response.data.success === true) {
        dispatch(getWorkspace());
        alert.success("Workspace deleted successfully");
      } else {
        dispatch(getWorkspace());
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const leaveWorkspace = (workspaceId, callback) => {
  return async (dispatch) => {
    try {
      const userId = localStorage.getItem("user_id");

      const response = await axios.get(
        API_URL + `/form/workspace/workspaceLeave/${userId}/${workspaceId}`,
        driveconfig,
      );
      if (response.data.success === true) {
        dispatch(getWorkspace());
        dispatch({
          type: "LEAVE_WORKSPACES",
          payload: { _id: workspaceId },
        });
        callback(response);
      } else {
        dispatch(getWorkspace());
      }
    } catch (error) {
      callback(error);
    }
  };
};

export const deleteWorkspace = (workspaceId, callback) => {
  return async (dispatch) => {
    const userId = localStorage.getItem("user_id");
    try {
      const data = { userId, workspaceId };
      const response = await axios.delete(
        API_URL + `/form/workspace/deleteWorkspace/${workspaceId}`,
        driveconfig,
        data,
      );
      if (response.data) {
        dispatch({
          type: "DELETE_WORKSPACES",
          payload: { _id: workspaceId },
        });
        callback(response);
      } else {
        dispatch(getWorkspace());
      }
    } catch (error) {
      if (callback) {
        callback(null, error);
      }
    }
  };
};

export const starredWorkspace = (payload, callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_URL + `/form/workspace/starred`,
        payload,
        driveconfig,
      );
      if (response.data) {
        callback(response);
        dispatch({
          type: "UPDATE_STAR_WORKSPACE",
          payload: {
            workspaceId: payload?.workspaceId,
            starred: payload?.isStar,
          },
        });
      } else {
        callback(response);
      }
    } catch (error) {
      callback(error);
    }
  };
};

export const getTrashedWorkspace = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        API_URL + `/form/workspace/getAllTrashWorkspace`,
        driveconfig,
      );
      if (response.data) {
        dispatch({
          type: "TRASHED_WORKSPACE",
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: "TRASHED_WORKSPACE",
          payload: [],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const restoreTrashedWorkspace = (workspaceId, userId) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        API_URL + `/form/workspace/restoreWorkspace/${userId}/${workspaceId}`, {},
        driveconfig,
      );
      if (response.data) {
        console.log(response.data)
        // callback(response.data.message);
        dispatch(getTrashedWorkspace());
      }
    } catch (error) {
      console.log(error)
    }
  };
};

export const DeleteTrashedWorkspace = (workspaceId) => {
  return async (dispatch) => {
    try {
      const response = await axios.delete(
        API_URL + `/form/workspace/deleteWorkspace/${workspaceId}`,
        driveconfig
      );
      console.log(response)
      if (response.status === 200) {
        dispatch(getTrashedWorkspace());
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const updateIdeaByTask = (ideaID, workspaceID, callback) => {
  return async () => {
    try {
      await axios.post(
        HOST_URL + `api/cxdeployer/user/ideas/updateIdeaByTask/${ideaID}`,
        { workspaceId: workspaceID },
        driveconfig,
      );
      Cookies.remove("cx_project_name", {
        domain: ".appsdeployer.com",
        path: "/",
        sameSite: "none",
        secure: true,
      });
      Cookies.remove("cx_idea_id", {
        domain: ".appsdeployer.com",
        path: "/",
        sameSite: "none",
        secure: true,
      });
    } catch (error) {
      callback(error);
    }
  };
};

export const getAllFormsOfWorkSpace = (workspaceId, callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_URL}/form/form/getAllFormsOfWorkspace/${workspaceId}`,
        driveconfig,
      );
      dispatch({
        type: "SET_WORKSPACE_FORMS",
        payload: response.data,
      });
    } catch (error) {
      console.error("Unable to fetch forms", error);
    }
  };
};
