import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styles from '../../css/Preview.module.css'
import classNames from "classnames";

const FileUploadQuestion = ({
  _id,
  questionText,
  qImgUrl,
  required,
  isSelfPreviewPage,
  handleFileChange,
  formErrors,
  ind,
  fileLoading,
  imagePreview,
  uploadedFileName,
  fileUpl,
}) => {
  const dispatch = useDispatch();

  return (
    <Card className={styles.question_card}>
      <Form.Group
        key={_id}
        className={classNames(
          styles.documentUpload,
          "d-flex flex-column align-items-center justify-content-center",
        )}
      >
        <Form.Label className="mb-3 w-100">
          <div className="d-flex">
            <p className="d-flex me-1">
              {required && <span className={styles.required}>*</span>}{" "}
              {questionText.length !== 0 && `${ind + 1}. `}
            </p>
            <div>{questionText}</div>
          </div>
          {qImgUrl && qImgUrl?.length !== 0 && (
            <>
              <img
                src={qImgUrl}
                alt=""
                className={styles.preview_Question_image}
              />
            </>
          )}
        </Form.Label>
        {fileLoading && (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <div className={`${styles.inputFileContainer} ms-3`}>
          {imagePreview ? (
            <img
              className={styles.uploadedPreview}
              src={imagePreview}
              alt="Uploaded"
            />
          ) : uploadedFileName ? (
            <div className="py-3 d-flex flex-column justify-content-center align-items-center">
              <svg
                width="40"
                height="40"
                viewBox="0 0 384 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#C80E08"
                  d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9m-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8M86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2M248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24m-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2M377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9m-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9"
                />
              </svg>
              {uploadedFileName}
            </div>
          ) : (
            <img src={fileUpl} alt="Upload" />
          )}
          {!imagePreview && !uploadedFileName && (
            <p>Choose file or drag here</p>
          )}
          <Form.Control
            disabled={isSelfPreviewPage}
            className={`${styles.inputFile} file-upload`}
            type="file"
            required={required}
            onChange={(e) => handleFileChange(e, _id)}
            isInvalid={
              formErrors.includes(
                `Please fill the required field: ${questionText}`,
              ) ||
              formErrors.includes(
                `File size should be less than or equal to 1MB: ${questionText}`,
              )
            }
          />
        </div>
        <p className="text-danger mt-2 ms-3">File should be less than 1MB</p>
        {formErrors.includes(
          `Please fill the required field: ${questionText}`,
        ) ? (
          <p
            className={styles.invalidText}
          >{`Please fill the required field: ${questionText}`}</p>
        ) : null}
        <Form.Control.Feedback type="invalid">
          {formErrors.map((error) => {
            if (
              error.startsWith(
                `Please fill the required field: ${questionText}`,
              )
            ) {
              return error;
            }
          })}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          {formErrors.map((error) => {
            if (
              error.startsWith(
                `File size should be less than or equal to 1MB: ${questionText}`,
              )
            ) {
              return error;
            }
          })}
        </Form.Control.Feedback>
      </Form.Group>
    </Card>
  );
};

export default FileUploadQuestion;
