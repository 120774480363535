import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { closeWorkspace } from "../../redux/action/workspace";
import { useAlert } from "react-alert";

const workspaceLeaveDeletePopup = ({
  leaveDeletePopup,
  handleLeaveDeletePopup,
  leaveDeleteData,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const userId = localStorage.getItem("user_id");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleDeleteWorkspace = useCallback(
    (workspace) => {
      if (buttonClicked) {
        return;
      }

      setButtonClicked(true);

      const workspaceId = workspace?._id;

      dispatch(closeWorkspace(workspaceId, alert))
        .then(() => {
          handleLeaveDeletePopup();
        })
        .catch((error) => {
          console.error("Error closing workspace:", error);
        });
    },
    [dispatch, handleLeaveDeletePopup, alert, buttonClicked],
  );

  return (
    <>
      <Modal show={leaveDeletePopup} onHide={handleLeaveDeletePopup} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="mx-1">
              {leaveDeleteData?.createdBy === userId ? "Close" : "Leave"}
            </span>
            workspace
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to
          <span className="mx-1">
            {leaveDeleteData?.createdBy === userId ? "Close" : "leave"}
          </span>
          workspace?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLeaveDeletePopup}>
            Cancel
          </Button>
          <Button
            variant="danger"
            disabled={buttonClicked}
            onClick={() => handleDeleteWorkspace(leaveDeleteData)}
          >
            {leaveDeleteData?.createdBy === userId ? "Delete" : "Leave"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
workspaceLeaveDeletePopup.propTypes = {
  workspaceId: PropTypes.node.isRequired,
  workspaceName: PropTypes.node.isRequired,
  setSharePopup: PropTypes.node.isRequired,
};

export default workspaceLeaveDeletePopup;
