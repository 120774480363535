import classNames from "classnames/bind";
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import HoverOverlay from "../components/HoverOverlay";
import styles from "../css/AddCardPage.module.css";
import { editSectionOnEnter } from "../redux/slice/previewSlice";
import { useDispatch } from "react-redux";

const AddCardPage = ({
  handleAddField,
  handleAddNewSection,
  setChildData,
  updateVideoData,
  sectionIndex,
  formId,
  sectionId,
  isQuiz,
  formTypeToggle,
  isConditional,
}) => {
  const dispatch = useDispatch();
  const [showVideoCard, setShowVideoCard] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoData, setVideoData] = useState(null);
  const handleVideoButtonClick = () => {
    setShowVideoCard(true);
  };
  const handleCancelVideo = () => {
    setShowVideoCard(false);
    setVideoUrl("");
  };
  const handleVideoSelect = () => {
    // Implement the logic to save the selected video URL and perform any necessary actions
    // For now, let's just log the video URL to the console

    videoData["type"] = "video";
    setVideoData(videoData);

    setChildData(videoData);
    updateVideoData(videoData);
    // Reset the video card state
    setShowVideoCard(false);
    setVideoUrl("");
  };
  const handleSearchVideo = async () => {
    const mockVideoData = {
      id: "VIDEO_ID",
      type: "video",
      snippet: {
        title: "Test Video",
        thumbnails: {
          default: {
            url: "https://example.com/video-thumbnail.jpg",
            width: 120,
            height: 90,
          },
        },
      },
    };
    setVideoData(mockVideoData);
    try {
      // Call the YouTube Data API to fetch video details
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${extractVideoId(
          videoUrl,
        )}&key=AIzaSyA73rcfOlq5jhGYVpwc1oe6lUtBpVyc5_w`,
      );

      const data = await response.json();

      if (data.items.length > 0) {
        setVideoData(data.items[0]);
      } else {
        setVideoData(null);
      }
    } catch (error) {
      console.error("Error searching video:", error);
    }
  };
  const extractVideoId = (url) => {
    // Extract the video ID from the provided URL
    const videoIdMatch = url.match(
      /^(?:https?:\/\/)?(?:www\.)?youtube(?:\.com|-\w{2,3}\.\w{2})\/watch\?v=([^\s&]+)/,
    );

    if (videoIdMatch) {
      return videoIdMatch[1];
    }

    return null;
  };

  const handleAddConditionalQuestion = () => {
    const data = {
      value: !isConditional,
      update: "isConditional",
      sectionId,
    };

    dispatch(editSectionOnEnter(data));
  };

  return (
    <>
      <Card className="shadow text-muted">
        <div className={styles.addCard_options}>
          <HoverOverlay
            placement="left"
            hoverText="Add Question"
            mainInput={
              <i
                style={{ padding: "20px" }}
                className="fa-solid fa-circle-plus  fa-lg "
                onClick={() =>
                  handleAddField(
                    isQuiz ? "multiple_choice" : "text",
                    sectionIndex,
                  )
                }
              />
            }
          />

          {showVideoCard && (
            <Card
              className={classNames(
                styles.addCard_videoCard,
                "shadow text-muted",
              )}
            >
              <Card.Body>
                <Card.Title>Select Video</Card.Title>
                <div className={styles.videoCard_content}>
                  <div className={styles.videoCard_searchBox}>
                    <img
                      src="https://static.vecteezy.com/system/resources/previews/003/399/771/original/youtube-icon-editorial-free-vector.jpg"
                      alt="YouTube Logo"
                      className={styles.videoCard_logoImage}
                    />
                    <input
                      type="text"
                      className={styles.videoCard_searchInput}
                      placeholder="Paste YouTube video URL"
                      value={videoUrl}
                      onChange={(e) => setVideoUrl(e.target.value)}
                    />
                    <span className={styles.videoCard_searchIcon}>
                      <i
                        className="fa-solid fa-search "
                        onClick={handleSearchVideo}
                      />
                    </span>
                  </div>

                  {videoData && (
                    <div className={styles.videoCard_selectedVideo}>
                      {/* Display the video */}
                      <iframe
                        title="YouTube Video"
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoData.id}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  )}
                </div>
                <div className={styles.videoCard_buttons}>
                  <Button variant="primary" onClick={handleVideoSelect}>
                    Select
                  </Button>
                  <Button variant="secondary" onClick={handleCancelVideo}>
                    Cancel
                  </Button>
                </div>
              </Card.Body>
            </Card>
          )}

          <HoverOverlay
            placement="left"
            hoverText="Add Secton"
            mainInput={
              <i
                className="fa-solid fa-table-columns"
                style={{
                  fontSize: "1.5rem",
                  display: "flex",
                  margin: "8px ",
                  justifyContent: "center",
                }}
                onClick={() => handleAddNewSection(sectionIndex, "addSection", formId)}
              />
            }
          />

          <HoverOverlay
            placement="left"
            hoverText="Add conditional question"
            mainInput={
              <i
                className="fa-solid fa-question"
                style={{
                  fontSize: "1.5rem",
                  display: "flex",
                  margin: "8px ",
                  justifyContent: "center",
                  color: isConditional ? "green" : "grey",
                }}
                onClick={handleAddConditionalQuestion}
              />
            }
          />
        </div>
      </Card>

      {sectionIndex === 0 && (
        <Card>
          <div className={styles.addCard_options}>
            <HoverOverlay
              placement="left"
              hoverText="Change Form Type to Quiz"
              mainInput={
                <i
                  style={{ padding: "20px" }}
                  className={
                    !isQuiz
                      ? "fa-regular fa-lg fa-square"
                      : "fa-regular fa-lg fa-square-check"
                  }
                  onClick={formTypeToggle}
                />
              }
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default AddCardPage;
