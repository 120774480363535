import classNames from "classnames/bind";
import React from "react";
import { Card } from "react-bootstrap";
import { CiTimer } from "react-icons/ci";
import { TiDelete } from "react-icons/ti";
import styles from "../../css/CreateForm.module.css";

export default function Section({
  formData,
  sectionIndex,
  timer,
  setShowDeleteModal,
  setDeleteSectonDetails,
  section,
  formId,
  handleFormTitleChange,
  handleFormTitleChangeUndo,
  handleSectionSaveOnEnter,
  handleFormDescriptionChange,
  handleFormDescriptionChangeUndo,
  handleSubmitButtonTextChange,
  handleSubmitButtonTextChangeUndo,
  handleEditFormOnEnter,
}) {
  return (
    <div className={styles.titlepage_container}>
      <div className={styles.section_header_bar}>
        {formData.sections.length > 1 && (
          <span className={styles.section_indicator}>{`section ${
            sectionIndex + 1
          }/${formData.sections.length}`}</span>
        )}
        {sectionIndex === 0 && timer && timer !== "00:00" && (
          <div className={styles.timer_div}>
            <CiTimer fontSize={32} />
            {timer}
          </div>
        )}

        {formData.sections.length > 1 && (
          <span
            className={styles.section_delete_button}
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteSectonDetails({
                sectionId: section._id,
                update: "removeSection",
                formId,
              });
              // handleEditFormOnEnter(
              //   section._id,
              //   "removeSection",
              //   formId,
              // );
              // dispatch(handleSectionRemove(section._id));
            }}
          >
            <TiDelete />
          </span>
        )}
      </div>
      <Card className={classNames(styles.titlepage_card, "shadow")}>
        <span className={styles.titlepagecard_span} />

        <input
          type="text"
          className={styles.title_input}
          value={section.title}
          onChange={(e) => handleFormTitleChange(e, sectionIndex)}
          onBlur={() => {
            handleFormTitleChangeUndo(section.title);
            handleSectionSaveOnEnter(section.title, "title", section._id);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSectionSaveOnEnter(section.title, "title", section._id);
            }
          }}
        />
        <input
          type="text"
          className={styles.description_input}
          value={section.description}
          onChange={(e) => handleFormDescriptionChange(e, sectionIndex)}
          onBlur={() => {
            handleFormDescriptionChangeUndo(formData.description);
            handleSectionSaveOnEnter(
              section.description,
              "description",
              section._id,
            );
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSectionSaveOnEnter(
                section.description,
                "description",
                section._id,
              );
            }
          }}
        />
        {sectionIndex === 0 && (
          <input
            type="text"
            className={styles.description_input}
            value={formData.submitButtonText}
            onChange={(e) => handleSubmitButtonTextChange(e, sectionIndex)}
            onBlur={() => {
              handleSubmitButtonTextChangeUndo(section.submitButtonText);
              handleEditFormOnEnter(
                formData.submitButtonText,
                "submitButtonText",
                formId,
              );
              // handleSectionSaveOnEnter(
              //   section.submitButtonText,
              //   "submitButtonText",
              //   section._id,
              // )t
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleEditFormOnEnter(
                  formData.submitButtonText,
                  "submitButtonText",
                  formId,
                );
              }
            }}
          />
        )}
      </Card>
    </div>
  );
}
