import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, cookieToken } from "../../service/url";

export const fetchSubmissionsData = createAsyncThunk(
  "responses/fetchSubmissionsData",
  async (formIddd) => {
    try {
      const response = await axios.get(
        `${API_URL}/form/submission/getSubmissionByFormId/${formIddd}`,
        {
          headers: {
            "x-access-token": cookieToken,
            "Content-Type": "application/json",
          },
        },
      );

      return {
        formId: formIddd,
        data: response.data.data,
      };
    } catch (error) {
      console.error("Error fetching submissions data:", error);
      return { submissions: [], questions: [] };
    }
  },
);

export const fetchUserResponse = createAsyncThunk(
  "responses/fetchUserResponse",
  async (submissionId) => {
    try {
      const response = await axios.get(
        `${API_URL}/form/submission/getUserResponse/${submissionId}`,
        {
          headers: {
            "x-access-token": cookieToken,
            "Content-Type": "application/json",
          },
        },
      );
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error(error);
      return { userSubmission: [], questions: [] };
    }
  },
);

const responseSlice = createSlice({
  name: "responses",
  initialState: {
    userSubmission: {},
    submissions: [],
    questions: [],
    loading: true,
    formId: null,
    formTitle: null,
    isQuiz: "",
    count: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubmissionsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubmissionsData.fulfilled, (state, action) => {
      state.submissions = action.payload.data?.submissions;
      state.questions = action.payload.data?.questions;
      state.questions.sort((a, b) => a._id.localeCompare(b._id));
      state.formId = action.payload.formId;
      state.formTitle = action.payload.data.formTitle;
      state.formType = action.payload.data.formType;
      state.isQuiz = action.payload.data.isQuiz;
      state.loading = false;
      state.count = {
        submitCount: action.payload.data.submitCount,
        openCount: action.payload.data.openCount,
      };
    });
    builder.addCase(fetchSubmissionsData.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchUserResponse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserResponse.fulfilled, (state, action) => {
      state.userSubmission = action.payload.submission;
      state.questions = action.payload.questions;
      state.questions.sort((a, b) => a._id.localeCompare(b._id));

      state.formId = action.payload.submission?.formId?._id;
      state.formTitle = action.payload.submission?.title;
      state.loading = false;
    });
    builder.addCase(fetchUserResponse.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default responseSlice.reducer;
