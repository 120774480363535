import React from "react";
import { useParams } from "react-router-dom";

import classNames from "classnames/bind";
import { useCallback, useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { AiOutlineFileAdd } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AllFormsCard from "../../components/Cards/AllFormsCard";
import "../../css/AllForms.css";
import styles from "../../css/AllForms.module.css";
import Loader from "../../loader";
import { getAllFormsOfWorkSpace } from "../../redux/action/workspace.js";
import { setPageNumber } from "../../redux/slice/formSlice.js";

const SingleWorkspace = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.forms.loading);
  const pageNumber = useSelector((state) => state.forms.pageNumber);
  const pageCount = useSelector((state) => state.forms.pageCount);
  const pageSize = useSelector((state) => state.forms.pageSize);

  const forms = useSelector((state) => state.workspace.forms);

  useEffect(() => {
    dispatch(getAllFormsOfWorkSpace(workspaceId));
  }, [workspaceId, dispatch]);

  const handlePageChange = useCallback(
    ({ selected }) => {
      dispatch(setPageNumber(selected + 1));
    },
    [dispatch],
  );
  const [search, setSearch] = useState();
  const filteredForms = search
    ? forms.filter((form) =>
        form.sections[0].title.toLowerCase().includes(search.toLowerCase()),
      )
    : forms;

  const handleCreate = useCallback(() => {
    navigate(`/addForm/${workspaceId}`);
  }, [navigate, workspaceId]);

  return (
    <>
      <Container className="mb-3 mt-5">
        <div className="mt-3 mb-5">
          <p className="display-6 fs-4">Start a new form</p>

          <Card className={styles.create_form_card} onClick={handleCreate}>
            <AiOutlineFileAdd
              className={classNames(styles.create_form_icons, "mb-4")}
            />
            <Card.Title>Create new form</Card.Title>
          </Card>
        </div>

        <h1 className={classNames(styles.all_form_text, "display-4 mb-4")}>
          All Workspace Forms
        </h1>

        {loading ? (
          <Loader />
        ) : (
          <>
            {!filteredForms || filteredForms.length === 0 ? (
              <p>No data Found</p>
            ) : (
              <>
                <div className="d-flex flex-wrap gap-2">
                  {filteredForms.map((form, index) => (
                    <AllFormsCard
                      form={form}
                      index={index}
                      key={form}
                      search={search}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}

        {pageCount > 1 && (
          <div className="w-100 d-flex justify-content-center mt-5">
            <ReactPaginate
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={6}
              onPageChange={handlePageChange}
              forcePage={pageNumber - 1}
              previousLabel={<span aria-hidden="true">&laquo;</span>}
              nextLabel={<span aria-hidden="true">&raquo;</span>}
              containerClassName="pagination"
              pageClassName="page-item"
              breakClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active"
              activeLinkClassName="active"
              activeStyle={{ color: "#7349bd" }}
              disabledClassName="disabled"
              breakLabel="..."
              disableInitialCallback={true}
              previousClassNameDisabled="disabled"
              nextClassNameDisabled="disabled"
              previousLinkClassNameDisabled="page-link disabled"
              nextLinkClassNameDisabled="page-link disabled"
              ariaLabelBuilder={(page) => `Page ${page}`}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleWorkspace;
