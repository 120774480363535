import { createSlice } from "@reduxjs/toolkit";
const shareWorkspace_UsersSlice = createSlice({
  name: "shareWorkspace_UserSlice",
  initialState: {
    users: [],
  },
  reducers: {
    setAllUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});
export const { setAllUsers } = shareWorkspace_UsersSlice.actions;
export default shareWorkspace_UsersSlice.reducer;
