import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, cookieToken } from "../../service/url";

export const fetchProfileData = createAsyncThunk(
  "profile/fetchProfileData",
  async () => {
    try {
      const response = await axios.get(`${API_URL}/drive/user/getProfile`, {
        headers: {
          "x-access-token": cookieToken,
        },
      });

      return response.data.data[0];
    } catch (error) {
      console.error("Error fetching profile data:", error);
      throw error; // Rethrow the error to be caught in the component
    }
  },
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    userId: "",
    name: "",
    email: "",
    img: "",
    organization: "",
    role: "",
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        const profileData = action.payload;
        state.userId = profileData.userId._id;
        state.name = profileData.userId.username;
        state.email = profileData.userId.email;
        state.img = profileData.userId.image;
        state.organization = profileData.organizationId.name;
        state.role = profileData.roleId.name;
        state.loading = false;
      })
      .addCase(fetchProfileData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default profileSlice.reducer;
