export const pathName = [
    '/',
    '/member',
    '/guests',
    '/pending',
    '/userboard',
    '/workspace_setting',
    '/handbook',
    '/mem',
    '/guest',
    '/member',
    '/workspace',
    '/template',
    '/template-board',
    '/recent',
    '/trash',
    '/resources',
    '/createBoard',
    '/finish-setup',
    '/userprofile'



];
